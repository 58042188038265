import Layout from "../layouts/Dashboard";
import { Link } from "react-router-dom";
import { useState, useContext } from "react";
import PermissionForm from "./PermissionForm";
import { useNavigate, useLocation } from 'react-router-dom';
import { axios } from "../utils";
import { UserProvider } from "./UserContext";
import Modal from "./Modal";
import IconSuccess from "../assets/icon-success.png";
import EmployeeForm from "./EmployeeForm";
import { AuthContext } from "../AuthContext";
import IconClock from "../assets/icon-clock.png";

export default function AddEmployee(){

    const {auth} = useContext(AuthContext);
    const [user, setUser] = useState({});
    const [page, setPage] = useState('user-form');
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    const onAddUser = async ()=>{
        setError('');
        try{
            const name = user.name.split(' ');
            const params = {
                ...user, 
                firstName: name[0],
                lastName: name[1],
                permissions: JSON.stringify(user.permissions),
            };
            if(user.id){
                axios.patch(`/users/${user.id}`, {...params, status: 'Pending for Approval'})
                .then(resp=>setSuccess(true))
                .catch(e=>{
                    setError(e.response?.data?.errors ? Object.values(e.response.data.errors)[0] : e.message);
                })
            }
            else{
                await axios.post("/users", {
                    ...params,
                    password: process.env.REACT_APP_DEFAULT_USER_PASSWORD,
                    role: 'employee',
                    status: 'Pending for Approval',
                    member_id: auth.member_id,
                })
                .then(_=>setSuccess(true))
                .catch(e=>{
                    setError(e.response?.data?.errors ? Object.values(e.response.data.errors)[0] : e.message);
                })
            }
        }catch(e){
            setError(e.response?.data?.message || e.message);
        }
    }
    const onSuccessOK = ()=>{
        setSuccess(false);
        setPage('employee-form')
        navigate('/access-management')
    }

    return <Layout>
        <div className="flex justify-between items-center">
            <p className="text-2xl font-bold">
                {page==='user-form' ? (id ? 'Edit' : 'Add')+' Employee' : 'Roles & Permissions'}
            </p>
            <div className="flex items-center space-x-4">
                {page==='user-form' && <Link to="/access-management" className="px-4 py-2 secondary text-white rounded-md">Back</Link>}
                {page==='permission-form' && <>
                    <button onClick={()=>setPage('user-form')} className="px-4 py-2 secondary text-white rounded-md">Back</button>
                    <Link to={`/access-history?id=${user.id}&back=employee-form`} className="primary text-white h-[37px] px-4 py-2 rounded-md flex justify-center gap-2"><img src={IconClock} width={22} alt="View History Icon"/> <span className="pt-[1px]">View History</span></Link>
                    <button onClick={onAddUser} className="primary text-white px-4 py-2 rounded-md block disabled:opacity-50">{user.id ? 'Save' : 'Add Employee'}</button>
                </>
                }
            </div>
        </div>
        {
            success && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4">{user.id ? 'Employee Updated' : 'New Employee Added'}</p>
                        <span className="mb-6">You successfully {user.id ? 'updated an':'added a new'} employee.</span>
                        <button onClick={onSuccessOK} className="primary text-white px-4 py-2 rounded-md w-1/2 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        <UserProvider>
            <div className="w-full bg-white rounded p-3 mt-5 pb-6">
                {error && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{error}</span>}
                <div className="flex flex-col items-center ">
                        {page==='user-form' && <EmployeeForm {...{setPage}} />}
                </div>
                {page==='permission-form' && <PermissionForm isEmployee {...{setPage, setUser}} />}
            </div>
        </UserProvider>
    </Layout>
}