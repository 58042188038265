import Layout from "../layouts/Dashboard";
import { useState, useEffect, useContext } from "react";
import IconActive from "../assets/icon-active.png";
import IconActiveOff from "../assets/icon-active-off.png";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {getTimeFromDate, axios, appendTimeToDate, objectDiff} from "../utils";
import IconClockBlack from "../assets/icon-clock-black.png";
import IconSuccess from "../assets/icon-success.png";
import Modal from "./Modal";
import { AuthContext } from "../AuthContext";

export default function DDPortalSettings(){

    const {auth} = useContext(AuthContext);
    const [selected, setSelected] = useState('mandate');
    const [form, setForm] = useState({
        collection: {enabled: []},
        acceptance: {enabled: []},
        mandate: {},
        fees: {}
    });
    const [success, setSuccess] = useState(false);
    const settingKey = 'dd-settings';
    const [initialForm, setInitialForm] = useState({mandate: {}});

    useEffect(()=>{
        if(auth.id){
            axios.get(`settings/${settingKey}`).then((resp)=>{
                if(resp.data){
                    let {collection: {collection: {enabled, ...collectionTime}, acceptance: {enabled: acceptanceEnabled, ...acceptanceTime}}} = {...form, ...resp.data};
                    Object.keys(collectionTime).forEach(key=>{
                        collectionTime[key] = appendTimeToDate(collectionTime[key]);
                    });
                    Object.keys(acceptanceTime).forEach(key=>{
                        acceptanceTime[key] = appendTimeToDate(acceptanceTime[key]);
                    });
                    const data = {
                        ...resp.data, 
                        name: settingKey,
                        collection: {enabled, ...collectionTime},
                        acceptance: {enabled: acceptanceEnabled, ...acceptanceTime}
                    };
                    setInitialForm(data)
                    setForm(data)
                }
            })
        }
    }, [auth])

    const onSave = ()=>{
        let {collection: {enabled, ...collectionTime}, acceptance: {enabled: acceptanceEnabled, ...acceptanceTime}} = form;
        Object.keys(collectionTime).forEach(key=>{
            collectionTime[key] = getTimeFromDate(collectionTime[key]);
        });
        Object.keys(acceptanceTime).forEach(key=>{
            acceptanceTime[key] = getTimeFromDate(acceptanceTime[key]);
        });
        const params = {
            ...form, 
            name: settingKey,
            collection: {
                collection: {enabled, ...collectionTime},
                acceptance: {enabled: acceptanceEnabled, ...acceptanceTime}
            },
            
        };
        if(form.name){
            axios.patch(`settings/${settingKey}`, params)
        }
        else {
            axios.post(`settings`, params)
        }
        setInitialForm(form)
        setSuccess(true);
    }

    const onChange = (e)=>{
        const {name, value, type} = e.target;
        setForm({...form, [type]: {...form[type], [name]: value}});
    }

    return <Layout>
        {
            success && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4">Success</p>
                        <span className="mb-6">Settings has been saved successfully.</span>
                        <button onClick={()=>setSuccess(false)} className="primary text-white px-4 py-2 rounded-md w-1/2 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        <div className="flex justify-between items-center">
            <p className="text-2xl font-bold">DD Portal Settings</p>
            <button disabled={Object.keys(objectDiff(initialForm.mandate, form.mandate)).length===0 || (form.mandate.enabled && form.mandate.days<1)} onClick={onSave} className="px-4 py-2 primary text-white rounded-md">Save</button>
        </div>
        <div className="flex flex-col">
            <div className="flex gap-3 pt-3 pb-1">
                <div onClick={()=>setSelected('collection')} className={`hidden pb-3 cursor-pointer ${selected==='collection' && 'tab active !border-b-2'}`}>Collection</div>
                <div onClick={()=>setSelected('mandate')} className={`pb-3 cursor-pointer ${selected==='mandate' && 'tab active !border-b-2'}`}>Mandate</div>
                <div onClick={()=>setSelected('fees')} className={`hidden pb-3 cursor-pointer ${selected==='fees' && 'tab active !border-b-2'}`}>Fees</div>
            </div>
        </div>
        {selected==='collection' && <div className="w-full bg-white rounded p-3">
            <div className="overflow-x-auto mb-3">
                <h3 className="text-xl font-semibold leading-[23.44px]">Collection Report Cutoff Time Settings</h3>
                <div className="w-2/3 mt-5">
                    <div className="flex justify-between">
                        <div className="gap-3 flex flex-col">
                            <h4 className="text-lg leading-[21.09px] font-semibold">Upload Collection File</h4>
                            {['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].map(day=>(
                                <div key={day} className="flex justify-between items-center">
                                    <div className="flex gap-2 w-2/3">
                                        {!form.collection.enabled.includes(day) && <img onClick={()=>setForm({...form, collection: {...form.collection, enabled: [...form.collection.enabled, day], [day]: form.collection.day || appendTimeToDate('8:00AM')}})} className="w-[45px] h-[26px] cursor-pointer" src={IconActiveOff} />}
                                        {form.collection.enabled.includes(day) && <img onClick={()=>setForm({...form, collection: {...form.collection, enabled: form.collection.enabled.filter(d=>d!==day)}})} className="w-[45px] h-[26px] cursor-pointer" src={IconActive} />}
                                        <span className="pt-1 capitalize">{day}</span>
                                    </div>
                                    <div className={`flex border border-gray-300 rounded-md w-[155px] ${!form.collection.enabled.includes(day) && 'opacity-50'}`}>
                                        <div className="w-9/12">
                                            <DatePicker
                                                selected={form.collection[day]}
                                                onChange={(v)=>onChange({target: {type: 'collection', name: day, value: v}})}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={60}
                                                timeCaption="Time"
                                                dateFormat="h:mm aa"
                                                timeFormat="hh:mm aa"
                                                disabled={!form.collection.enabled.includes(day)}
                                            />
                                        </div>
                                        <img className="mt-[10px] w-[20px] h-[20px]" src={IconClockBlack}/>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="gap-3 flex flex-col">
                            <h4 className="text-lg leading-[21.09px] font-semibold">Upload Acceptance File</h4>
                            {['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].map(day=>(
                                <div key={day} className="flex justify-between items-center">
                                    <div className="flex gap-2 w-2/3">
                                        {!form.acceptance.enabled.includes(day) && <img onClick={()=>setForm({...form, acceptance: {...form.acceptance, enabled: [...form.acceptance.enabled, day], [day]: form.collection.day || appendTimeToDate('8:00AM')}})} className="w-[45px] h-[26px] cursor-pointer" src={IconActiveOff} />}
                                        {form.acceptance.enabled.includes(day) && <img onClick={()=>setForm({...form, acceptance: {...form.acceptance, enabled: form.acceptance.enabled.filter(d=>d!==day)}})} className="w-[45px] h-[26px] cursor-pointer" src={IconActive} />}
                                        <span className="pt-1 capitalize">{day}</span>
                                    </div>
                                    <div className={`flex border border-gray-300 rounded-md w-[155px] ${!form.acceptance.enabled.includes(day) && 'opacity-50'}`}>
                                        <div className="w-9/12">
                                            <DatePicker
                                                selected={form.acceptance[day]}
                                                onChange={(v)=>onChange({target: {type: 'acceptance', name: day, value: v}})}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={60}
                                                timeCaption="Time"
                                                dateFormat="h:mm aa"
                                                timeFormat="hh:mm aa"
                                                disabled={!form.acceptance.enabled.includes(day)}
                                            />
                                        </div>
                                        <img className="mt-[10px] w-[20px] h-[20px]" src={IconClockBlack}/>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>}
        {
            selected==='mandate' && <div className="w-full bg-white rounded p-3">
                <div className="overflow-x-auto mb-3 w-2/5 gap-3 flex flex-col">
                    <h3 className="text-xl font-semibold leading-[23.44px]">Mandate Settings</h3>
                    <div className="flex gap-3 font-semibold leading-[21.09px] text-base">
                        {!form.mandate.enabled && <img onClick={()=>setForm({...form, mandate: {...form.mandate, enabled: !form.mandate.enabled}})} src={IconActiveOff} className="w-[45px] h-[26px] cursor-pointer" />}
                        {form.mandate.enabled && <img onClick={()=>setForm({...form, mandate: {...form.mandate, enabled: !form.mandate.enabled}})} src={IconActive} className="w-[45px] h-[26px] cursor-pointer" />}
                        <span className="pt-1">Approval of Mandates (Issuing bank) Cycle</span>
                    </div>
                    <input readOnly={!form.mandate.enabled} value={form.mandate.days} onChange={(e)=>setForm({...form, mandate: {...form.mandate, days: e.target.value?.replace(/\D/g, '')}})} placeholder="Input Number of Days" type="text" className="border rounded" />
                    <div className="hidden">
                        <span className="font-semibold leading-[21.09px] text-base">Mandate Reprocess</span>
                        <input value={form.mandate.reprocess} onChange={(e)=>setForm({...form, mandate: {...form.mandate, reprocess: e.target.value}})} placeholder="5" type="text" className="border rounded" />
                    </div>
                </div>
            </div>
        }
        {
            selected==='fees' && <div className="w-full bg-white rounded p-3">
                <div className="overflow-x-auto mb-3 w-2/5 gap-3 flex flex-col">
                    <h3 className="text-xl font-semibold leading-[23.44px]">Fees Settings</h3>
                    <span className="font-semibold leading-[21.09px] text-base">Mandate Creation Fees</span>
                    <input value={form.fees.creation} onChange={(e)=>setForm({...form, fees: {...form.fees, creation: e.target.value}})} placeholder="₱0.00" type="number" className="border rounded" />
                    <span className="font-semibold leading-[21.09px] text-base">Mandate Amendments Fees</span>
                    <input value={form.fees.amendments} onChange={(e)=>setForm({...form, fees: {...form.fees, amendments: e.target.value}})} placeholder="₱0.00" type="number" className="border rounded" />
                    <span className="font-semibold leading-[21.09px] text-base">System Fees</span>
                    <input value={form.fees.system} onChange={(e)=>setForm({...form, fees: {...form.fees, system: e.target.value}})} placeholder="₱0.00" type="number" className="border rounded" />
                    <span className="font-semibold leading-[21.09px] text-base">Transaction Fees</span>
                    <input value={form.fees.transaction} onChange={(e)=>setForm({...form, fees: {...form.fees, transaction: e.target.value}})} placeholder="₱0.00" type="number" className="border rounded" />
                </div>
            </div>
        }
    </Layout>
}