import {useState, useEffect, useContext} from "react";
import Layout from "../layouts/Dashboard";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Modal from "./Modal";
import IconSuccess from "../assets/icon-success.png";
import { axios, emailRegex, hasAccess } from "../utils";
import { AuthContext } from "../AuthContext";
import IconClock from "../assets/icon-clock.png";
import IconSearch from "../assets/icon-search.png";
import IconChecked from "../assets/icon-checked.png";
import IconUnchecked from "../assets/icon-unchecked.png";
import IconCancel from "../assets/icon-cancel.png";
import IconCancelGreen from "../assets/icon-cancel-green.png";
import IconDelete from "../assets/icon-delete.png";
import IconDeleteConfirm from "../assets/icon-delete-confirm.png";

export default function AddAdmin(){

    const {auth} = useContext(AuthContext);
    const [success, setSuccess] = useState(false);
    const [form, setForm] = useState({bank_id: "", admins: [{name: '', email: ''}, {name: '', email: ''}]})
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const [members, setMembers] = useState([]);
    const [initialForm, setInitialForm] = useState({admins: []});
    const [isEdit, setIsEdit] = useState(false);
    const [modalCancel, setModalCancel] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [search, setSearch] = useState('');
    const [selected, setSelected] = useState([]);
    const [list, setList] = useState([]);
    const [cancelConfirmModal, setCancelConfirmModal] = useState(false);
    const [cancelSuccess, setCancelSuccess] = useState(false);
    const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);

    useEffect(()=>{
        const _list = form.admins.filter(a=>!['Pending Approval for Cancellation', 'cancelled','DELETED', 'Pending Approval for Deletion'].includes(a.status));
        setList(_list);
        if(search){
            if(form.admins.length){
                setList(_list.filter(a=>a.name?.toLowerCase().indexOf(search) !== -1));
            }
        }
    }, [search, form.admins])

    useEffect(()=>{
        axios.get('/member?status=active|approved|Pending for Approval&limit=1000').then(resp=>{
            setMembers(resp.data.data);
            const member = resp.data.data.find(m=>m.id === id);
            setForm(prev=>({...prev, created_by: member?.created_by}));
        });
        if(id){
            getAdmins(id)
        }
    },[]);

    useEffect(()=>{
        setForm(prev=>({...prev, bank_id: id || form.bank_id, admins: [{name: '', email: ''}, {name: '', email: ''}]}))
        if(form.bank_id) getAdmins(form.bank_id)
    }, [form.bank_id])

    const getAdmins = (_id)=>{
        axios.get(`/member/${_id}/admins`).then(resp=>{
            const data = resp.data;
            let admins = [];
            for(let i = 0; i < data.length; i++){
                admins.push({id: data[i].id, name: data[i].firstName + ' ' + data[i].lastName, email: data[i].email, disabled: true, status: data[i].status});
            }
            if(admins.length === 0) {
                admins = [{name: '', email: ''}, {name: '', email: ''}];
            }
            
            setInitialForm({admins: JSON.parse(JSON.stringify(admins))});
            setForm(prev=>({...prev, admins}));
        })
    }

    const onSuccessOK = ()=>{
        setSuccess(false);
        navigate('/access-management?type=bank')
    }

    const handleChange = (e) => {
        const {name, value} = e.target;
        setForm(prev=>({...prev, [name]: value}));
    }
    const onAdminChange = (e, index)=>{
        let {name, value} = e.target;

        if(name === 'name'){
            value = value.replace(/[^a-zA-Z\s]/g, '');
        }else if(name === 'email'){
            value = value.trim();
        }

        setForm(prev=>{
            const admins = [...prev.admins];
            admins[index][name] = value;
            admins[index]['password'] = process.env.REACT_APP_DEFAULT_USER_PASSWORD;
            return {...prev, admins};
        });
    }

    const deleteAdmin = ()=>{
        setForm(prev=>{
            const admins = [...prev.admins];
            admins.pop();
            return {...prev, admins};
        });
    }

    const onSubmit = async()=>{
        setErrors({});
        const hasEmpty = Object.keys(form).filter(f=>!['admins', 'created_by'].includes(f)).filter(key=>!form[key]);
        
        if(hasEmpty.length){
            setErrors(hasEmpty.reduce((acc, key)=>({...acc, [key]: `${key.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase())} is required.`}), {}));
        }
        form.admins.map((admin, index)=>{
            if(!emailRegex.test(admin.email)){
                setErrors(prev=>({...prev, admins: 'Invalid email address'}))
                hasEmpty.push('admins')
            }
            if(!admin.name){
                setErrors(prev=>({...prev, admins: 'Admin Name is required'}))
                hasEmpty.push('admins')
            }
        })
        
        if(hasEmpty.length>0){
            return;
        }
        
        try{
            for(const admin of form.admins){
                try{
                    const name = admin.name.split(' ');
                    if(admin.id){
                        const _name = initialForm.admins.find(a=>a.id === admin.id).name;
                        console.log(_name, admin.name)
                        if(_name !== admin.name){
                            await axios.patch(`/users/${admin.id}`, {
                                firstName: name[0],
                                lastName: name[1],
                                status: 'Pending for Approval'
                            });
                        }
                    }else{
                        await axios.post("/users", {
                            ...admin,
                            firstName: name[0],
                            lastName: name[1],
                            member_id: form.bank_id,
                            role: 'admin',
                            status: auth.attribute === 'super' ? 'approved' : 'Pending for Approval',
                            permissions: '{"member-management-roles":{"maker":false,"approver":false},"member-all":[],"access-for-approval":["edit","view","create","approve","extract","cancel"],"biller-for-approval":["edit","view","create","approve","extract","cancel"],"mandate-for-approval":["edit","view","create","approve","extract","cancel"],"access-all":["edit","view","create","approve","extract","cancel"],"access-bank-admins":["edit","view","create","approve","extract","cancel"],"access-management-roles":{"maker":false,"approver":false},"biller-management-roles":{"maker":false,"approver":false},"mandate-management-roles":{"maker":false,"approver":false},"calendar-management-roles":{"maker":false,"approver":false},"mandate-all":["view","extract","cancel"],"biller-all":["view","extract","cancel"],"collections-roles":{"maker":false,"approver":false},"collections-net-settlement":["view","extract"],"collections-settlement-report":[],"audit-logs-roles":{"maker":false,"approver":false},"audit-logs-all":["view","extract"],"collections-management-roles":{"maker":false,"approver":false},"collections-management-acquirer":["view","extract"],"collections-management-issuer":["view","extract"],"settings-roles":{"maker":false,"approver":false},"settings-all":["create","edit"]}'
                        })
                    }
                }catch(e){
                    setError(e.response?.data?.errors ? Object.values(e.response.data.errors)[0] : e.message);
                    return;
                }
            }
            let resp = await axios.get(`users?type=bank`);
            resp = resp.data.data.filter(r=>r.member_id===form.bank_id && r.status==='approved');
            const status = resp.length>=2 ? 'active' : 'approved';
            await axios.patch(`/member/${form.bank_id}`, {status});
            setSuccess(true);
        }catch(e){
            setError(e.response?.data?.errors ? Object.values(e.response.data.errors)[0] : e.message);etError(err.response?.data?.message || err.message)
        }
    }

    const onDelete = async()=>{
        setDeleteConfirmModal(false);
        setError('')
        selected.forEach(async(a)=>{
            await axios.patch(`/users/${a.id}`, {status: 'Pending Approval for Deletion', previous_status: a.status}).then(()=>setDeleteSuccess(true)).catch(e=>{
                setError(e.response?.data?.message || e.message);
            });
        })
    }

    const onCancel = async()=>{
        setCancelConfirmModal(false);
        setError('')
        selected.forEach(async(a)=>{
            await axios.patch(`/users/${a.id}`, {status: 'Pending Approval for Cancellation', previous_status: a.status}).then(()=>setCancelSuccess(true)).catch(e=>{
                setError(e.response?.data?.message || e.message);
            });
        })
    }

    return <Layout>
        <div className="flex justify-between items-center">
            <p className="text-2xl font-bold">{id ? 'Edit' :'Add'} Designated Bank Admin</p>
            <div className="flex items-center space-x-4">
                {id && <Link to={`/access-history?id=${id}&type=bank`} className="primary text-white px-4 py-2 rounded-md flex justify-center gap-2"><img src={IconClock} width={22} alt="View History Icon"/> <span className="pt-[1px]">View History</span></Link>}
                <Link to="/access-management?type=bank" className="px-4 py-2 secondary text-white rounded-md">Back</Link>
            </div>
        </div>
        {
            deleteSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconDeleteConfirm} alt="Success" className="mb-4"/>
                        <p className="font-semibold text-2xl mb-4">Delete Success</p>
                        <span className="mb-6">You successfully deleted selected admins.</span>
                        <button onClick={()=>navigate('/access-management?type=internal')} className="primary text-white px-4 py-2 rounded-md w-1/2 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            deleteConfirmModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconDeleteConfirm} alt="Success" className="mb-4"/>
                        <p className="font-semibold text-2xl mb-4">Delete</p>
                        <span className="mb-6">Are you sure you want to delete selected admins?</span>
                        <div className="flex gap-3">
                            <button onClick={()=>{setDeleteConfirmModal(false);setModalDelete(true);}} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Cancel</button>
                            <button onClick={onDelete} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Delete</button>
                        </div>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            cancelSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconDeleteConfirm} alt="Success" className="mb-4"/>
                        <p className="font-semibold text-2xl mb-4">Cancel Success</p>
                        <span className="mb-6">You successfully cancelled selected admins.</span>
                        <button onClick={()=>navigate('/access-management?type=bank')} className="primary text-white px-4 py-2 rounded-md w-1/2 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            cancelConfirmModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconCancelGreen} alt="Success" className="mb-4"/>
                        <p className="font-semibold text-2xl mb-4">Cancel</p>
                        <span className="mb-6">Are you sure you want to cancel the selected admins?</span>
                        <div className="flex gap-3">
                            <button onClick={()=>{setCancelConfirmModal(false);setModalCancel(true);}} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Cancel</button>
                            <button onClick={onCancel} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            modalDelete && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50 flex-col">
                    <div className="bg-white p-4 rounded-lg shadow-md max-w-md w-full">
                    <div onClick={()=>setModalDelete(false)} className="relative top-[-15px] cursor-pointer text-end">x</div>
                        <div className="mb-4 items-center flex flex-col gap-2">
                            <p className="font-bold text-xl2 mb-2">Delete</p>
                            <div className="w-full relative">
                                <input value={search} onChange={e=>setSearch(e.target.value)} type="text" class="text-base font-normal leading-[18.75px] w-full border border-gray-300 rounded-md py-2 pl-10 pr-4 focus:outline-none focus:ring focus:border-blue-300" placeholder="Search"/>
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <img src={IconSearch} alt="Search" className="cursor-pointer w-[80%]" />
                                </div>
                            </div>
                            <div className={`${list.filter(i=>selected.filter(s=>s.id===i.id).length===0).length>2 ? '' : 'pointer-events-none'} bg-[#FCFAFA] border border-[#D8D8D8] rounded-lg p-3 w-full`}>
                                {
                                    list.map((a)=><div onClick={(prev)=>selected.filter(item=>item.id===a.id).length===0 ? setSelected(prev=>[...prev, a]) : setSelected(prev=>prev.filter(item=>item.id!==a.id))} className="flex gap-2 cursor-pointer w-full">
                                        <img src={selected.filter(item=>item.id===a.id).length>0 ? IconChecked : IconUnchecked} alt="Unchecked" className=""/>
                                        <span>{a.name}</span>
                                    </div>)
                                }
                            </div>
                            <button onClick={()=>{setModalDelete(false); setDeleteConfirmModal(true);}} disabled={selected.length===0} className="w-1/3 primary text-white px-4 py-2 rounded-md block disabled:opacity-50">Delete</button>
                        </div>
                    </div>
                </div>
            </Modal>
        }
        {
            modalCancel && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50 flex-col">
                    <div className="bg-white p-4 rounded-lg shadow-md max-w-md w-full">
                        <div onClick={()=>setModalCancel(false)} className="relative top-[-15px] cursor-pointer text-end">x</div>
                        <div className="mb-4 items-center flex flex-col gap-2">
                            <p className="font-bold text-xl2 mb-2">Cancel</p>
                            <div className="w-full relative">
                                <input value={search} onChange={e=>setSearch(e.target.value)} type="text" class="text-base font-normal leading-[18.75px] w-full border border-gray-300 rounded-md py-2 pl-10 pr-4 focus:outline-none focus:ring focus:border-blue-300" placeholder="Search"/>
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <img src={IconSearch} alt="Search" className="cursor-pointer w-[80%]" />
                                </div>
                            </div>
                            <div className={`${list.filter(i=>selected.filter(s=>s.id===i.id).length===0).length>2 ? '' : 'pointer-events-none'} bg-[#FCFAFA] border border-[#D8D8D8] rounded-lg p-3 w-full`}>
                                {
                                    list.map((a)=><div onClick={(prev)=>selected.filter(item=>item.id===a.id).length===0 ? setSelected(prev=>[...prev, a]) : setSelected(prev=>prev.filter(item=>item.id!==a.id))} className="flex gap-2 cursor-pointer w-full">
                                        <img src={selected.filter(item=>item.id===a.id).length>0 ? IconChecked : IconUnchecked} alt="Unchecked" className=""/>
                                        <span>{a.name}</span>
                                    </div>)
                                }
                            </div>
                            <button onClick={()=>{setModalCancel(false); setCancelConfirmModal(true);}} disabled={selected.length===0} className="w-1/3 primary text-white px-4 py-2 rounded-md block disabled:opacity-50">Cancel</button>
                        </div>
                    </div>
                </div>
            </Modal>
        }
        {
            success && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4">{form.admins.some(a=>!a.disabled) ? 'Admin Added' : 'Admin Updated'}!</p>
                        <span className="mb-6 text-center">You have {form.admins.some(a=>!a.disabled)? 'added':`updated`} {(form.admins.filter(a=>form.admins.some(a=>!a.disabled) ? !a.disabled : a.disabled).map(a=>a.name)).join(', ')} as bank admin of {members.find(m=>m.id===form.bank_id)?.bank_name} and is waiting for approval.</span>
                        <button onClick={onSuccessOK} className="primary text-white px-4 py-2 rounded-md w-1/2 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        <div className={`w-full bg-white border rounded p-3 mt-5 pb-6 ${(form.id && !isEdit) || (form.id && form.created_by!==auth.id) ? 'pointer-events-none':''}`}>
            {error && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{error}</span>}
            <div className="flex flex-col items-center ">
                {errors.admins && <div className="flex items-center mt-3 pt-3"><span className="mt-[-10px] mb-4 block text-red-500 font-semibold text-xs italic">{errors.admins}</span></div>}
                <div className="flex justify-between w-3/6 mb-3 mt-2">
                    <p className="font-bold">Designated Admin</p>
                    <span className="text-gray-500 text-xs">*2 admins required per bank</span>
                </div>
                <div className="mb-4 w-3/6">
                    <select
                    id="dropdown"
                    name="bank_id"
                    value={form.bank_id}
                    disabled={id}
                    onChange={handleChange}
                    className={`w-full px-3 py-3 border rounded-lg text-gray-500 ${(form.bank_id&&isEdit)?'border-black': ''}`}
                    >
                        <option value="">Bank Name</option>
                        {members.map(member=><option value={member.id}>{member.bank_name}</option>)}
                    </select>
                    {errors.bank_id && <span className="block text-red-500 font-semibold text-xs italic">{errors.bank_id}</span>}
                </div>
                {
                    form.admins.map((admin, index)=><>
                        <div className={`mb-4 w-3/6 ${!form.bank_id ? 'pointer-events-none' : ''}`}>
                            <div class="relative">
                                <input 
                                    onChange={e=>onAdminChange(e, index)}
                                    type="text" 
                                    id="name" 
                                    name="name" 
                                    value={admin.name}
                                    className={`${!isEdit&&'disabled'} pt-4 w-full px-2 py-1 border border-gray-300 rounded-md ${admin.name?'border-black': ''}`}
                                />
                                <label 
                                    for="name" 
                                    className={admin.name?'active':''}
                                >
                                    Name of Admin {index+1}
                                </label>
                            </div>
                        </div>
                        <div className={`${index === form.admins.length-1 ? 'w-3/6' : 'mb-4 w-3/6'} ${(admin.disabled || !form.bank_id) ? 'disabled pointer-events-none' : ''}`}>
                            <div class="relative">
                                <input 
                                    onChange={e=>onAdminChange(e, index)}
                                    type="text" 
                                    id="email" 
                                    name="email" 
                                    value={admin.email}
                                    className={`pt-4 w-full px-2 py-1 border border-gray-300 rounded-md ${admin.email?'border-black': ''}`}
                                />
                                <label 
                                    for="email" 
                                    className={admin.email?'active':''}
                                >
                                    Email Address
                                </label>
                            </div>
                        </div>
                    </>)
                }
                <div className={`flex justify-end w-3/6 mb-3 ${!isEdit&&'disabled'} ${form.admins.filter(a=>!a.disabled).some(a=>!a.name||!a.email) && 'pointer-events-none opacity-50'}`}>
                    <p onClick={()=>setForm(prev=>({...prev, admins: [...prev.admins, {name: '', email: '', disabled:false}]}))} className="text-primary cursor-pointer">+ Add another admin</p>
                </div>
                <div className="mb-4 w-3/6 text-center">
                    <button disabled={!isEdit || !hasAccess('access-bank-admins', id ? 'edit':'create', auth.permissions) || !form.bank_id || form.admins.filter(a=>a.disabled).some(a=>!a.name) || form.admins.filter(a=>!a.disabled).some(a=>!a.name||!emailRegex.test(a.email))} onClick={onSubmit} className="primary text-white px-4 py-2 rounded-md w-full block disabled:opacity-50">{form.admins.some(a=>!a.disabled) ? 'Add Bank Admin': 'Save'}</button>
                </div>
                <div className={`${!id ? 'hidden':''} mb-4 w-3/6 text-center flex gap-5 pointer-events-auto`}>
                    <button onClick={()=>setIsEdit(true)} className="primary text-white px-4 py-2 rounded-md w-full block disabled:opacity-50">Edit</button>
                    <button disabled={form.admins.filter(a=>a.status==='approved').length<=2} onClick={()=>{setSelected([]);setSearch('');setModalCancel(true);}} className="primary text-white px-4 py-2 rounded-md w-full block disabled:opacity-50">Cancel</button>
                    <button disabled={form.admins.filter(a=>a.status==='approved').length<=2} onClick={()=>{setSelected([]);setSearch('');setModalDelete(true);}} className="primary text-white px-4 py-2 rounded-md w-full block disabled:opacity-50">Delete</button>
                </div>
            </div>
        </div>
    </Layout>
}