import {useState, useContext, useEffect} from "react";
import Layout from "../layouts/Dashboard";
import { Link, useNavigate, useLocation } from "react-router-dom";
import IconSuccess from "../assets/icon-success.png";
import Modal from "./Modal";
import { AuthContext } from "../AuthContext";
import { axios } from "../utils";

export default function AddBiller(){
    
    const {auth, updateAuth} = useContext(AuthContext);
    const [success, setSuccess] = useState(false);
    const [form, setForm] = useState({});
    const [error, setError] = useState(null);


    const onChange = (e)=>{
        const {name, value} = e.target;
        setForm({...form, [name]: value});
    }
    useEffect(()=>{
        if(auth.id){
            axios.get(`/users/${auth.id}`).then(resp=>{
                const {firstName, lastName, email, role} = resp.data
                setForm({name: `${firstName} ${lastName}`, email, role});
            })
        }
    }, [])

    const onSubmit = async ()=>{
        setError('');
        const name = form.name.split(' ');
        axios.patch(`/users/${auth.id}`, {
            firstName: name[0],
            lastName: name[1],
        })
        .then(()=>{
            updateAuth({firstName: name[0], lastName: name[1], email: form.email})
            setSuccess(true)
        })
        .catch(e=>{
            setError(e.response?.data?.errors ? Object.values(e.response.data.errors)[0] : e.message);
        })
    }

    return <Layout>
        <div className="flex justify-between items-center">
            <p className="text-2xl font-bold">Account Settings</p>
        </div>
        {
            success && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4">Account Updated</p>
                        <span className="mb-6">You successfully your account.</span>
                        <button onClick={()=>setSuccess(false)} className="primary text-white px-4 py-2 rounded-md w-1/2 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        <div className="shadow-lg w-full bg-white border rounded p-3 mt-5 pb-6">
            {error && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{error}</span>}
            <div className="flex flex-col items-center p-3">
                <div className="w-3/6 font-semibold text-xl mb-[10px]">
                    Account Info
                </div>
                <div className="w-3/6">
                    <div class="relative input-container">
                        <input 
                            type="text" 
                            id="name" 
                            name="name" 
                            value={form.name}
                            onChange={onChange}
                            className={`pt-4 w-full pl-[10px] py-2 border border-gray-300 rounded-md ${form.name?'border-black': ''}`}
                        />
                        <label 
                            for="name" 
                            className={`pl-1 ${form.name?'active':''}`}
                        >
                            Full Name
                        </label>
                    </div>
                </div>
                <div className="w-3/6">
                    <div class="relative input-container">
                        <input 
                            type="text" 
                            id="email" 
                            name="email" 
                            readOnly
                            value={form.email}
                            onChange={onChange}
                            className={`disabled pt-4 w-full pl-[10px] py-2 border border-gray-300 rounded-md ${form.email?'border-black': ''}`}
                        />
                        <label 
                            for="biller_tin" 
                            className={`pl-2 ${form.email?'active':''}`}
                        >
                            Email
                        </label>
                    </div>
                </div>
                <div className="w-3/6">
                    <div class="relative input-container">
                        <input 
                            type="text" 
                            id="role" 
                            name="role" 
                            value={form.role}
                            readOnly
                            className={`disabled pt-4 w-full pl-[10px] py-2 border border-gray-300 rounded-md ${form.role?'border-black': ''}`}
                        />
                        <label 
                            for="role" 
                            className={`pl-2 ${form.role?'active':''}`}
                        >
                            Role
                        </label>
                    </div>
                </div>
                <div className="mb-4 w-3/6 text-center">
                    <button disabled={!form.name || !form.email} onClick={onSubmit} className="primary text-white px-4 py-2 rounded-md w-full block">Save</button>
                </div>
            </div>
        </div>
    </Layout>
}