import {useState, useContext, useEffect} from "react";
import Layout from "../layouts/Dashboard";
import IconSearch from "../assets/icon-search.png";
import { Link } from "react-router-dom";
import IconSuccess from "../assets/icon-success.png";
import Modal from "./Modal";
import { AuthContext } from "../AuthContext";
import { axios, formatStatus, hasAccess, escapeRegExp } from "../utils";
import 'react-datepicker/dist/react-datepicker.css';

export default function MandateManagement(){

    const {auth} = useContext(AuthContext);
    const [search, setSearch] = useState("");
    const [exportSuccess, setExportSuccess] = useState(false);
    const [rows, setRows] = useState([]);
    const [error, setError] = useState(null);

    const onClickExport = ()=>{
        setError('')
        const sort = [{orderBy: "createdAt", order: 'desc'}]
        axios.post('/mandates/util/export', {filters: search, sort, limit: 1000})
        .then(()=>{
            setExportSuccess(true);
        }).catch((e)=>{
            setError(e.response?.data?.message || e.message);
        });
    }

    const onChange = async ()=>{
        setError('')
        try{
            const sort = JSON.stringify([{orderBy: "createdAt", order: 'desc'}])
            const resp = await axios.get(`/mandates?filters=${escapeRegExp(search.trim())}&limit=1000&sort=${encodeURIComponent(sort)}`)
            setRows(resp.data.data)
        }catch(e){
            setError(e.response?.data?.message || e.message)
        }
    }

    useEffect(()=>{
        setRows([])
        if(search.trim().length>=3){
            onChange();
        }
    }, [search])

    return <Layout>
        <div className="flex justify-between items-center">
            <div className="flex gap-3">
                <p className="text-2xl font-semibold leading-[28.13px]">Mandate Management</p>
                {auth.member_id && hasAccess('mandate-management-roles', 'approver', auth.permissions) && <Link to="/pending-mandate" className="text-base leading-[18.75px] font-bold p-1 secondary text-white rounded-md">View Mandate for Approval</Link>}
            </div>
            <div className="flex items-center space-x-4">
                {hasAccess('mandate-all', 'extract', auth.permissions) && <button disabled={!search || rows.length===0} onClick={onClickExport} className="px-4 py-2 primary text-white rounded-md text-base font-bold w-[97px] h-[43px]">Export</button>}
                {auth.member_id && hasAccess('mandate-all', 'create', auth.permissions) && <Link to="/mandate-form" className="px-4 py-2 primary text-white text-base font-bold rounded-md h-[43px]">Create Mandate</Link>}
            </div>
        </div>
        <div className="w-full mt-5">
            <div class="relative">
                <input onChange={e=>setSearch(e.target.value)} type="text" class="text-base font-normal leading-[18.75px] w-full border border-gray-300 rounded-md py-2 pl-10 pr-4 focus:outline-none focus:ring focus:border-blue-300" placeholder="Search via Mandate ID, Biller Name, Status"/>
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <img src={IconSearch} alt="Search" className="cursor-pointer w-[80%]" />
                </div>
            </div>
        </div>
        {
            exportSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4 text-center">Your requested file has been<br/>sent to your email</p>
                        <button onClick={()=>setExportSuccess(false)} className="primary text-white px-4 py-2 rounded-md w-3/12 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {error && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{error}</span>}
        {search.trim() && rows.length > 0 && <div className="bg-white w-full mt-5 border rounded p-3">
            <div className="mb-10">
                <table className="table-auto border-collapse w-full">
                    <thead>
                    <tr className="text-lg border-b-2">
                        <th className="py-2 text-left font-medium">Mandate ID</th>
                        <th className="py-2 text-left font-medium">Biller Name</th>
                        {
                            auth.role==='employee' && <th className="py-2 text-left pr-[40px] font-medium">Created By</th>
                        }
                        <th className="py-2 text-left font-medium">Status</th>
                        {
                            (hasAccess('mandate-all', 'view', auth.permissions) || auth.role==='employee') && <th className="py-2 text-left pr-[40px] font-medium" width="15%">Action</th>
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {rows.map((r)=><tr className="border-b border-gray-200 text-base">
                        <td className="py-2 font-normal">{r.mandate_id}</td>
                        <td className="py-2 font-normal">{r.billerByName}</td>
                        {
                            auth.role==='employee' && <td className="py-2 font-normal text-left">{r.createdByName}</td>
                        }
                        <td className="py-2 font-normal flex gap-1 justify-left items-center w-full">{formatStatus(r.status)}</td>
                        {
                            (hasAccess('mandate-all', 'view', auth.permissions) || auth.role==='employee') && <td className="py-2 font-normal text-left">
                                {( 
                                    (r.status!=='Pending for Approval' && r.status!=='Pending for Acceptance' )  //issuing
                                    || !hasAccess('mandate-management-roles', 'approver', auth.permissions)
                                    || (r.created_by === auth.id || (auth.id===r.created_by && r.updated_by && auth.id===r.updated_by))
                                    //|| r.updated_by === auth.id
                                    //|| (!auth.member_id && hasAccess('mandate-all', 'view', auth.permissions))
                                ) ? 
                                    <Link to={`/mandate-form?id=${r.id}`} className="px-4 py-2 primary text-white rounded-md w-[160px] block text-center">
                                        View{(r.member_id===auth.member_id && r.status !== 'expired' && r.status!=='Cancelled' && hasAccess('mandate-all', 'edit', auth.permissions))&& `/Edit`} Details
                                    </Link>
                                    : 
                                    <Link to={ ((r.status==='Pending for Approval' && r.member_id === auth.member_id) || (r.status==='Pending for Acceptance' && r.bank_id === auth.member_id)) ? `/approve-mandate?id=${r.id}&page=mandate-management`:`/mandate-form?id=${r.id}`} className="px-4 py-2 primary text-white text-center rounded-md w-[160px] block">
                                        View { (((r.status==='Pending for Approval' && r.member_id === auth.member_id) || (r.status==='Pending for Acceptance' && r.bank_id === auth.member_id)) && r.updated_by !== auth.id) && 'and Approve'}
                                    </Link>
                                }
                            </td>
                        }
                    </tr>)}
                    </tbody>
                </table>
            </div>
        </div>}
    </Layout>
}