import Layout from "../layouts/Dashboard";
import { UserProvider } from './UserContext';
import PermissionForm from "./PermissionForm";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import {axios} from "../utils";

export default function ApproveUser() {

    const setPage = ()=>null;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const [user, setUser] = useState({});
    const type = queryParams.get('type');
    const back = queryParams.get('back');

    useEffect(()=>{
        axios.get(`/users/${id}`).then(resp=>{
            let permissions = {};
            try{
                permissions = JSON.parse(resp.data.permissions);
                permissions = Object.keys(permissions).reduce((acc, key)=>(isNaN(key))?{...acc, [key]: permissions[key]}: acc, {});
            }catch(e){
            }
            setUser({...resp.data, permissions});
        });
    }, []);

  return <Layout>
    <div className="flex justify-between items-center">
        <p className="text-2xl font-semibold">View and Approve</p>
        <div className="flex items-center space-x-4">
            <Link to={`${back ? `/${back}?type=${type}` : `/access-management?type=${type}`}`} className="px-4 py-2 secondary text-white rounded-md">Back</Link>
        </div>
    </div>
    <div className="w-full bg-white rounded p-3 mt-5 pb-6">
        {user.id && <UserProvider data={user}>
            <PermissionForm isApprove {...{setUser, setPage}} />
        </UserProvider>}
    </div>
  </Layout>;
}