import Layout from "../layouts/Dashboard";
import {Link, useNavigate, useLocation} from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../AuthContext";
import { axios } from "../utils";
import Modal from "./Modal";
import IconSuccess from "../assets/icon-success.png";

export default function ApproveMember() {

    const {auth} = useContext(AuthContext);
    const navigate = useNavigate();
    const [form, setForm] = useState({notifications: [], types: [], admins: []});
    const [approvedModal, setApprovedModal] = useState();
    const [rejectModal, setRejectModal] = useState();
    const [rejectReason, setRejectReason] = useState('');
    const [rejectSuccess, setRejectSuccess] = useState();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const page = queryParams.get('page') || 'pending-members';
    const [admins, setAdmins] = useState([{firstName: '-', email: '-'}]);

    const onApprove = async(approved)=>{
        if(approved){
            await axios.patch(`/member/${id}`, {status: 'approved'})
            setApprovedModal(true)
        }else{
            setRejectModal(true);
        }
    }

    const onReject = async()=>{
        await axios.patch(`/member/${id}`, {status: 'Rejected', reject_reason: rejectReason})
        setRejectModal(false);
        setRejectSuccess(true);
    }
    
    useEffect(()=>{
        if(auth.role!=='admin'){
            navigate('/member-management');
        }
        axios.get(`/member/${id}`).then(resp=>{
            let data = {
                ...resp.data,
                notifications: JSON.parse(resp.data.notifications).join(', '),
                type: resp.data.type ? JSON.parse(resp.data.type).join(', ') : '',
            }
            setForm(data);
        });
    }, []);

  return <Layout>
    <div className="flex justify-between items-center">
        <p className="text-2xl font-semibold">View and Approve</p>
        <div className="flex items-center space-x-4">
            <Link to={`${page ? `/${page}` : '/pending-members'}`} className="px-4 py-2 secondary text-white rounded-md">Back</Link>
        </div>
    </div>
        {
            rejectModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    <div className="bg-white px-8 py-4 rounded-lg shadow-md">
                        <div onClick={()=>setRejectModal(false)} className='text-end w-full right-0 cursor-pointer text-black'>x</div>
                        <div className="mb-4 items-center flex flex-col">
                            <div className="font-semibold text-2xl">Reject</div>
                            <div className="mt-[15px] mb-[20px] font-normal"><span className="capitalize">{form.bank_name}</span> has been rejected due to</div>
                            <input className="rounded mb-[17px] w-[451px] h-[54px] border" type="text" value={rejectReason} onChange={(e)=>setRejectReason(e.target.value)} placeholder="Please add reason for rejection" />
                            <button onClick={onReject} disabled={!rejectReason} className="disabled:opacity-[0.5] primary text-white px-4 py-2 rounded-md w-[136px] h-[54px] block">Reject</button>
                        </div>
                    </div>
                </div>
            </Modal>
        }
        {
            rejectSuccess && <Modal onClose={()=>setRejectSuccess(false)}>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <div className="font-semibold text-2xl leading-[28.13px] mb-[20px]">Rejection Successful!</div>
                        <div className="text-xl2 mb-6 text-center">
                            You have successfully rejected a member.
                        </div>
                        <button onClick={()=>navigate('/pending-members')} className="primary text-white px-4 py-2 rounded-md w-[97px] h-[54px] block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            approvedModal && <Modal onClose={()=>setApprovedModal(false)}>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <div className="font-semibold text-2xl leading-[28.13px] mb-[20px]">Approval Successful!</div>
                        <div className="text-xl2 mb-6 text-center">
                            You have successfully added a member.
                        </div>
                        <button onClick={()=>navigate(`/${page}`)} className="primary text-white px-4 py-2 rounded-md w-[97px] h-[54px] block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
    <div className="bg-white w-full mt-5 rounded p-10 flex flex-col items-center">
        <div className="w-[60%]">
            <table className="w-full border-collapse">
                <tr>
                    <td className="pb-5" width="33%">
                        <div className="label text-xs">Bank Name</div>
                        <span className="font-medium text-base capitalize">
                            {form.bank_name}
                            <div>{form.type}</div>
                        </span>
                    </td>
                    <td className="pb-5 align-top" width="33%">&nbsp;</td>
                    <td className="pb-5 align-top whitespace-nowrap w-[1%]">
                        <div className="label text-xs">BIC / Swift / Pesonet Identifiers</div>
                        <span className="font-medium text-base">{form.bic}</span>
                    </td>
                </tr>
                <tr>
                    <td className="pb-5 align-top whitespace-nowrap w-[1%]" colSpan={3}>
                        <div className="label text-xs">Bank Address</div>
                        <span className="font-medium text-base">{form.bank_address}</span>
                    </td>
                </tr>
                <tr>
                    <td className="pb-5" width="33%">
                        <div className="label text-xs">Liaison Name</div>
                        <span className="font-medium text-base">{form.liason_name}</span>
                    </td>
                    <td className="pb-5" width="33%">
                        <div className="label text-xs">Mobile or Landline Number</div>
                        <span className="font-medium text-base">{form.liason_contact}</span>
                    </td>
                    <td className="pb-5 whitespace-nowrap w-[1%]">
                        <div className="label text-xs">Email Address</div>
                        <span className="font-medium text-base">{form.liason_email}</span>
                    </td>
                </tr>
                <tr>
                    <td className="pb-5" width="33%">
                        <div className="label text-xs">Liaison Name</div>
                        <span className="font-medium text-base">{form.liason2_name || '-'}</span>
                    </td>
                    <td className="pb-5" width="33%">
                        <div className="label text-xs">Mobile or Landline Number</div>
                        <span className="font-medium text-base">{form.liason2_contact || '-'}</span>
                    </td>
                    <td className="pb-5 whitespace-nowrap w-[1%]">
                        <div className="label text-xs">Email Address</div>
                        <span className="font-medium text-base">{form.liason2_email || '-'}</span>
                    </td>
                </tr>
                <tr>
                    <td className="pb-5 font-medium text-base" width="33%">
                        <div className="label text-xs">Notifications</div>
                        <span className="font-medium text-base capitalize">{form.notifications}</span>
                    </td>
                </tr>
                <tr>
                    <td className="pb-5 font-medium text-base" width="33%">Designated Admin</td>
                </tr>
                {admins.map(a=><tr>
                    <td className="pb-5" width="33%">
                        <div className="label text-xs">Name of Admin</div>
                        <span className="font-medium text-base">{a.firstName} {a.lastName}</span>
                    </td>
                    <td className="pb-5" width="33%" colSpan={2}>
                        <div className="label text-xs">Email Address</div>
                        <span className="font-medium text-base flex-wrap flex">{a.email}</span>
                    </td>
                </tr>)}
                <tr>
                    <td className="pb-5" colSpan={3}>
                        <button onClick={()=>onApprove(true)} className="px-4 py-2 w-full h-[54px] primary text-white rounded-md">Approve</button>
                        <button onClick={()=>onApprove(false)} className="block mt-[15px] w-full h-[54px] text-center px-4 py-2 secondary text-white rounded-md">Reject</button>
                    </td>
                </tr>
            </table>
        </div>
    </div>
  </Layout>;
}