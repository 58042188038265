import { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../layouts/Dashboard";
import Modal from "./Modal";
import IconSuccess from "../assets/icon-success.png";
import { axios, formatDate, formatStatus, hasAccess } from "../utils";
import { AuthContext } from "../AuthContext";

export default function PendingMembers() {

    const {auth} = useContext(AuthContext);
    const [exportSuccess, setExportSuccess] = useState(false);
    const [rows, setRows] = useState([]);
    const [error, setError] = useState(null);
    const [uploadModal, setUploadModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadedFileName, setUploadedFileName] = useState('');
    const [errorUpload, setErrorUpload] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const navigate = useNavigate();

    const onClickExport = ()=>{
        setError('')
        axios.post(`/member/util/export`, {filters: `pending`})
            .then(()=>{
                setExportSuccess(true)
            }).catch((e)=>{
                setError(e.response?.data?.errors ? Object.values(e.response.data.errors)[0] : e.message);
            });
    }

    const onClickUpload = () => {
        const formData = new FormData();
        formData.append('file', selectedFile);
        axios.post('/member/util/import', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
        }).then(async()=>{
            setUploadSuccess(true);
            setUploadModal(false);
        }).catch((e)=>{
            setErrorUpload(e.response?.data?.message || e.message)
        })
    }
  
    const handleFileChange = (event) => {
      setSelectedFile(event.target.files[0]);
      setUploadedFileName(event.target.files[0].name);
    };

    const onLoad = async ()=>{
        setError('')
        try{
            const sort = JSON.stringify([{orderBy: "createdAt", order: 'desc'}])
            const resp = await axios.get(`/member?limit=1000&status=Pending Approval for Cancellation|Pending for Approval|Pending Approval for Deletion&sort=${encodeURIComponent(sort)}`)
            setRows(resp.data.data.filter(r=>r.created_by!==auth.id && (r.updated_by!==auth.id)))
        }catch(e){
            setError(e.response?.data?.message || e.message)
        }
    }

    useEffect(()=>{
        if(auth.id){
            onLoad()
        }
    }, [auth]);

  return <Layout>
        <div className="flex justify-between items-center">
            <p className="text-2xl font-semibold leading-[28.13px]">List of Member for Approval</p>
            <div className="flex items-center space-x-4">
                <Link to="/member-management" className="px-4 py-2 secondary font-bold text-[16px] text-white rounded-[10px] leading-[18.75px]">Back</Link>
                <button disabled={rows.length === 0} onClick={onClickExport} className="px-4 py-2 primary font-bold text-[16px] text-white rounded-[10px] leading-[18.75px]">Export</button>
                <button onClick={()=>setUploadModal(true)} className="px-4 py-2 primary font-bold text-[16px] text-white rounded-[10px] leading-[18.75px]">Upload</button>
            </div>
        </div>
        {error && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{error}</span>}
        {
            exportSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4 text-center">Your requested file has been<br/>sent to your email</p>
                        <button onClick={()=>setExportSuccess(false)} className="primary text-white px-4 py-2 rounded-md w-3/12 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            uploadSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4 text-center">File successfully uploaded.</p>
                        <button onClick={async()=>{setUploadSuccess(false);await onLoad();}} className="primary text-white px-4 py-2 rounded-md w-3/12 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            uploadModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white rounded-lg shadow-md w-[508px] py-[33px] pb-2 px-[35px]">
                    {errorUpload && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{errorUpload}</span>}
                    <div className="mb-4 flex items-center flex-col">
                        <div className="flex mb-[31px]">
                            <div className="font-semibold text-2xl">Upload File</div>
                            <div onClick={()=>setUploadModal(false)} className="relative right-[-170px] top-[-25px] cursor-pointer">x</div>
                        </div>
                        <div className=" border border-dotted flex flex-col w-full items-center gap-2 p-5 mb-[17px]">
                            {!uploadedFileName && <>
                                <span className="text-gray-500 text-xs">Drop file here or</span>
                                <label htmlFor="fileInput" className="w-1/2 secondary border border-gray-300 text-center py-2 rounded-md cursor-pointer">
                                Excel File
                                </label>
                                <input
                                id="fileInput"
                                type="file"
                                className="hidden"
                                onChange={handleFileChange}
                                />
                            </>}
                            {selectedFile && (
                            <span className="text-gray-500">{uploadedFileName}</span>
                            )}

                        </div>
                        <button onClick={onClickUpload} className="primary text-white px-4 py-2 rounded-md w-[134px] h-[54px] block">Upload</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            rows.length === 0 && <div className="text-center bg-white p-5 mt-5 block text-sm font-semibold">No Pending Members for Approval</div>
        }
        {rows.length > 0 && <div className="w-full mt-5 border rounded p-3">
            <div className="overflow-x-auto">
                <table className="table-auto border-collapse w-full mb-3">
                    <thead>
                    <tr className="border-b">
                        <th className="py-2 text-left text-lg font-medium leading-[21.09px]" width="30%">Bank Name</th>
                        <th className="py-2 text-left text-lg font-medium leading-[21.09px" width="20%">Date Created</th>
                        <th className="py-2 text-left text-lg font-medium leading-[21.09px" width="20%">Created By</th>
                        <th className="py-2 text-left text-lg font-medium leading-[21.09px" width="20%">Status</th>
                        <th className="py-2 text-left text-lg font-medium leading-[21.09px" width="10%">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                        {
                            rows.map((row, i)=>(
                                <tr key={i} className="border-b">
                                    <td className="py-4 text-left text-base font-normal leading-[18.75px]">{row.bank_name}</td>
                                    <td className="py-4 text-left text-base font-normal leading-[18.75px]">{formatDate(row.createdAt)}</td>
                                    <td className="py-4 text-left text-base font-normal leading-[18.75px]">{row.createdByName}</td>
                                    <td className="py-2 flex gap-1 text-base justify-start items-center font-normal leading-[18.75px]">
                                        {formatStatus(row.status)}
                                    </td>
                                    <td className="text-right text-base">
                                        {
                                            ((row.created_by!==auth.id && row.updated_by!==auth.id) && hasAccess('member-all', 'approve', auth.permissions))
                                                ? <Link to={`/approve-member?id=${row.id}`}  className="px-4 py-2 primary text-white text-center rounded-md w-[143.84px] inline-block">View and Approve</Link>
                                                : <Link to={`/member-form?id=${row.id}`}  className="px-4 py-2 primary text-white text-center rounded-md w-[143.84px] inline-block">View</Link>
                                        }
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>}
  </Layout>;
}