import { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../layouts/Dashboard";
import Modal from "./Modal";
import IconSuccess from "../assets/icon-success.png";
import { axios, formatStatus, hasAccess } from "../utils";
import { AuthContext } from "../AuthContext";
import IconCancel from "../assets/icon-cancel.png";
import IconDelete from "../assets/icon-delete.png";
import IconDeleteConfirm from "../assets/icon-delete-confirm.png";

export default function PendingMembers() {

    const {auth} = useContext(AuthContext);
    const [exportSuccess, setExportSuccess] = useState(false);
    const [rows, setRows] = useState([]);
    const [error, setError] = useState(null);
    const [uploadModal, setUploadModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadedFileName, setUploadedFileName] = useState('');
    const [errorUpload, setErrorUpload] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const navigate = useNavigate();
    const [selected, setSelected] = useState('deletion');
    const [cancelConfirmModal, setCancelConfirmModal] = useState(false);
    const [cancelSuccess, setCancelSuccess] = useState(false);
    const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);

    const onUpload = ()=>{
        setErrorUpload('');
        setSelectedFile('')
        setUploadedFileName('')
        setUploadModal(true);
        
    }

    const onClickUpload = () => {
        const formData = new FormData();
        formData.append('file', selectedFile);
        axios.post('/billers/util/import', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
        }).then(()=>{
            setUploadSuccess(true);
            setUploadModal(false);
        }).catch((e)=>{
            setErrorUpload(e.response?.data?.message || e.message)
        })
    }
  
    const handleFileChange = (event) => {
      setSelectedFile(event.target.files[0]);
      setUploadedFileName(event.target.files[0].name);
    };

    const onExport = ()=>{
        setError('')
        axios.post("/billers/util/export", {filters: 'Pending for', member_id: auth.member_id})
            .then(()=>{
                setExportSuccess(true);
            }).catch((e)=>{
                setError(e.response?.data?.errors ? Object.values(e.response.data.errors)[0] : e.message);
            });
    }
    
    const onLoad = ()=>{
        const filters = auth.member_id ? 'Pending for Approval' : (selected === 'deletion' ? 'Pending for Deletion' : 'Pending for Cancellation');
        axios.get(`/billers?limit=1000&filters=${filters}${auth.member_id ? `&member_id=${auth.member_id}` : ''}`).then(resp=>{
            setRows(resp.data.data.filter(b=>b.created_by!==auth.id && b.updated_by!==auth.id))
        })
    }

    useEffect(()=>{
        onLoad()
    },[selected])

    useEffect(()=>{
        if(auth.id){
            if(!hasAccess('biller-for-approval', 'approve', auth.permissions)){
                navigate('/biller-management');
            }
            onLoad();
        }
    }, [auth]);

    const onDelete = async(id)=>{
        setDeleteConfirmModal(false);
        setError('')
        await axios.patch(`/billers/${id}`, {status: 'Pending Approval for Deletion'}).then(()=>setDeleteSuccess(true)).catch(e=>{
            setError(e.response?.data?.message || e.message);
        });
    }

    const onCancel = async(id)=>{
        setCancelConfirmModal(false);
        setError('')
        await axios.patch(`/billers/${id}`, {status: 'Pending Approval for Cancellation'}).then(()=>setCancelSuccess(true)).catch(e=>{
            setError(e.response?.data?.message || e.message);
        });
    }

  return <Layout>
        <div className="flex justify-between items-center mb-5">
            {
                !auth.member_id && <p className="text-2xl font-semibold leading-[28.13px]">Biller for Deletion and Cancellation</p>
            }
            {auth.member_id && <>
                <p className="text-2xl font-semibold leading-[28.13px]">List of Biller for Approval</p>
                <div className="flex items-center space-x-4">
                    <Link to="/biller-management" className="px-4 py-2 secondary text-white rounded-md text-base font-bold leading-[18.75px]">Back</Link>
                    <button disabled={rows.length === 0} onClick={onExport} className="px-4 py-2 primary text-white rounded-md text-base font-bold leading-[18.75px]">Export</button>
                    <button disabled={rows.length === 0} onClick={onUpload} className="px-4 py-2 primary text-white rounded-md text-base font-bold leading-[18.75px]">Upload</button>
                </div>
            </>}
        </div>
        {
            cancelSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconDeleteConfirm} alt="Success" className="mb-4"/>
                        <p className="font-semibold text-2xl mb-4">Cancel Success</p>
                        <span className="mb-6">You successfully cancelled a biller.</span>
                        <button onClick={()=>navigate('/biller-management')} className="primary text-white px-4 py-2 rounded-md w-1/2 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            cancelConfirmModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconCancelGreen} alt="Success" className="mb-4"/>
                        <p className="font-semibold text-2xl mb-4">Cancel</p>
                        <span className="mb-6">Are you sure you want to cancel?</span>
                        <div className="flex gap-3">
                            <button onClick={()=>setCancelConfirmModal(false)} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Cancel</button>
                            <button onClick={onCancel} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            deleteSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconDeleteConfirm} alt="Success" className="mb-4"/>
                        <p className="font-semibold text-2xl mb-4">Delete Success</p>
                        <span className="mb-6">You successfully deleted a biller.</span>
                        <button onClick={()=>navigate('/biller-management')} className="primary text-white px-4 py-2 rounded-md w-1/2 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            deleteConfirmModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconDeleteConfirm} alt="Success" className="mb-4"/>
                        <p className="font-semibold text-2xl mb-4">Delete</p>
                        <span className="mb-6">Are you sure you want to delete?</span>
                        <div className="flex gap-3">
                            <button onClick={()=>setDeleteConfirmModal(false)} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Cancel</button>
                            <button onClick={onDelete} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Delete</button>
                        </div>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            uploadSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4 text-center">File successfully uploaded.</p>
                        <button onClick={()=>{setUploadSuccess(false);onLoad();}} className="primary text-white px-4 py-2 rounded-md w-3/12 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            uploadModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white rounded-lg shadow-md max-w-md w-3/12 p-5">
                    
                    <div className="mb-4 flex items-center gap-4 flex-col">
                        <div className="flex">
                            <p className="font-bold text-xl2">Upload File</p>
                            <div onClick={()=>setUploadModal(false)} className="relative right-[-120px] top-[-15px] cursor-pointer">x</div>
                        </div>
                        <div className=" border border-dotted flex flex-col w-full items-center gap-2 p-5">
                            {!uploadedFileName && <>
                                <span className="text-gray-500 text-xs">Drop file here or</span>
                                <label htmlFor="fileInput" className="w-1/2 secondary border border-gray-300 text-center py-2 rounded-md cursor-pointer">
                                Excel File
                                </label>
                                <input
                                id="fileInput"
                                type="file"
                                className="hidden"
                                onChange={handleFileChange}
                                />
                            </>}
                            {errorUpload && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{errorUpload}</span>}
                            {!errorUpload && selectedFile && (
                            <span className="text-gray-500">{uploadedFileName}</span>
                            )}

                        </div>
                        <button onClick={onClickUpload} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Upload</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {error && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{error}</span>}
        {
            exportSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4 text-center">Your requested file has been<br/>sent to your email</p>
                        <button onClick={()=>setExportSuccess(false)} className="primary text-white px-4 py-2 rounded-md w-3/12 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {error && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{error}</span>}
        {
            !auth.member_id && <div className="flex flex-col">
                <div className="flex px-3 pl-0">
                    <div onClick={()=>setSelected('deletion')} className={`pb-3 cursor-pointer font-bold p-3 rounded-t ${selected==='deletion' ? 'bg-white text-primary' : 'bg-[#f0f0f1]'}`}>Biller for Deletion</div>
                    <div onClick={()=>setSelected('cancellation')} className={`pb-3 cursor-pointer font-bold p-3 rounded-t ${selected==='cancellation' ? 'bg-white text-primary' : 'bg-[#f0f0f1]'}`}>Biller for Cancellation</div>
                </div>
            </div>
        }
        {
            rows.length === 0 && <div className="text-center bg-white p-5 block text-sm font-semibold">No Pending Biller for Approval</div>
        }
        {rows.length > 0 && <div className="bg-white w-full border rounded p-3">
            <div className="overflow-x-auto mb-10">
                <table className="table-auto border-collapse w-full">
                    <thead>
                    <tr className="border-b">
                        <th className="py-2 text-left text-lg font-medium leading-[21.09px]" width="10%">Biller ID</th>
                        <th className="py-2 text-left text-lg font-medium leading-[21.09px]" width="30%">Biller Name</th>
                        <th className="py-2 text-left text-lg font-medium leading-[21.09px]" width="20%">Created By</th>
                        <th className="py-2 text-left text-lg font-medium leading-[21.09px]" width="15%">Status</th>
                        <th className="py-2 text-left text-lg font-medium leading-[21.09px]" width="15%">Action</th>
                    </tr>
                    </thead>
                    <tbody> 
                        {
                            rows.map((r, i)=><tr key={i} className="border-b">
                                <td className="py-2 text-left text-base font-normal leading-[18.75px]">{r.biller_id || '-'}</td>
                                <td className="py-2 text-left text-base font-normal leading-[18.75px]">{r.biller_name}</td>
                                <td className="py-2 text-left text-base font-normal leading-[18.75px]">{r.createdByName}</td>
                                <td className="py-2 gap-1 flex justify-start items-center text-base font-normal leading-[18.75px]">{formatStatus(r.status)}</td>
                                <td className="py-3 text-left">
                                    {auth.member_id && <Link to={`/approve-biller?id=${r.id}&back=pending-billers`}  className="px-4 py-2 primary text-white rounded-md">View and Approve</Link>}
                                    {
                                        !auth.member_id && <>
                                            <button onClick={()=>selected==='deletion' ? onDelete(r.id) : onCancel(r.id)} className="px-4 py-2 primary text-white rounded-md">Approve</button>
                                        </>
                                    }
                                </td>
                            </tr>)
                        }
                    </tbody>
                </table>
            </div>
        </div>
        }
  </Layout>;
}