import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { useEffect, useState } from "react";
import './App.css';
import Landing from './components/Landing';
import MemberManagement from "./components/MemberManagement";
import Login from "./components/Login";
import AccessManagement from "./components/AccessManagement";
import BillerManagement from "./components/BillerManagement";
import MandateManagement from "./components/MandateManagement";
import CalendarManagement from "./components/CalendarManagement";
import AddMember from "./components/AddMember";
import AddUser from "./components/AddUser";
import AddEmployee from "./components/AddEmployee";
import CreateMandate from "./components/CreateMandate";
import Logs from "./components/Logs";
import AddBiller from "./components/AddBiller";
import { AuthProvider } from "./AuthContext";
import PendingMembers from "./components/PendingMembers";
import PendingUsers from "./components/PendingUsers";
import PendingEmployee from "./components/PendingEmployee";
import PendingBillers from "./components/PendingBillers";
import PendingMandate from "./components/PendingMandate";
import ApproveMember from "./components/ApproveMember";
import ApproveUser from "./components/ApproveUser";
import ApproveBiller from "./components/ApproveBiller";
import ApproveMandate from "./components/ApproveMandate";
import ApproveEmloyee from "./components/ApproveEmployee";
import AddAdmin from "./components/AddAdmin";
import Settings from "./components/Settings";
import Collections from "./components/Collections";
import CollectionsBank from "./components/CollectionsBank";
import ApproveAdmin from "./components/ApproveAdmin";
import BankReports from "./components/BankReports";
import CollectionBankReports from "./components/CollectionBankReport";
import AccessHistory from "./components/AccessHistory";
import BillerHistory from "./components/BillerHistory";
import MandateHistory from "./components/MandateHistory";
import DDPortalSettings from "./components/DDPortalSettings";
import MemberHistory from "./components/MemberHistory";
import PendingCalendar from "./components/PendingCalendar";
import useIdleTimer from "./components/useIdleTimer";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login/>,
  },{
    path: "/pending-calendar",
    element: <PendingCalendar/>,
  },{
    path: "/dd-portal-settings",
    element: <DDPortalSettings/>,
  },{
    path: "/member-history",
    element: <MemberHistory/>,
  },{
    path: "/mandate-history",
    element: <MandateHistory/>,
  },{
    path: "/biller-history",
    element: <BillerHistory/>,
  },{
    path: "/access-history",
    element: <AccessHistory/>,
  },{
    path: "/reports",
    element: <BankReports/>,
  },{
    path: "/landing",
    element: <Landing/>,
  },{
    path: "/approve-admin",
    element: <ApproveAdmin/>,
  },{
    path: "/collection-management",
    element: <CollectionsBank/>,
  },{
    path: "/collections",
    element: <Collections/>,
  },{
    path: "/collections/net-settlement",
    element: <CollectionBankReports/>,
  },{
    path: "/settings",
    element: <Settings/>,
  },{
    path: "/member-management",
    element: <MemberManagement/>,
  },{
    path: "/biller-form",
    element: <AddBiller/>,
  },{
    path: "/admin-form",
    element: <AddAdmin/>,
  },{
    path: "/approve-employee",
    element: <ApproveEmloyee/>,
  },{
    path: "/approve-member",
    element: <ApproveMember/>,
  },{
    path: "/approve-biller",
    element: <ApproveBiller/>,
  },{
    path: "/approve-mandate",
    element: <ApproveMandate/>,
  },{
    path: "/approve-user",
    element: <ApproveUser/>,
  },{
    path: "/pending-billers",
    element: <PendingBillers/>,
  },{
    path: "/pending-mandate",
    element: <PendingMandate/>,
  },{
    path: "/pending-members",
    element: <PendingMembers/>,
  },{
    path: "/pending-employee",
    element: <PendingEmployee/>,
  },{
    path: "/pending-users",
    element: <PendingUsers/>,
  },{
    path: "/user-form",
    element: <AddUser/>,
  },{
    path: "/logs",
    element: <Logs/>,
  },{
    path: "/mandate-form",
    element: <CreateMandate/>,
  },{
    path: "/employee-form",
    element: <AddEmployee/>,
  },{
    path: "/member-form",
    element: <AddMember/>,
  },{
    path: "/login",
    element: <Login/>,
  },{
    path: "/access-management",
    element: <AccessManagement/>,
  },{
    path: "/biller-management",
    element: <BillerManagement/>,
  },{
    path: "/mandate-management",
    element: <MandateManagement/>,
  },{
    path: "/calendar-management",
    element: <CalendarManagement/>,
  }
]);

function App() {

  const onLogout = ()=>{
      window.document.location = process.env.REACT_APP_AKAMAI_PORTAL;
  }
  //useIdleTimer(onLogout);

  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}

export default App;
