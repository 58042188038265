import React, { createContext, useState } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setUser] = useState({});
  const updateAuth = (data) => {
    setUser(prev=>({...prev, ...data}));
  };

  return <AuthContext.Provider value={{auth, updateAuth}}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };