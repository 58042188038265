import {useState, useEffect, useContext} from "react";
import Layout from "../layouts/Dashboard";
import IconSearch from "../assets/icon-search.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { axios, formatDate, hasAccess, escapeRegExp, formatStatus } from "../utils";
import { AuthContext } from "../AuthContext";
import Modal from "./Modal";
import IconSuccess from "../assets/icon-success.png";

export default function AccessManagement(){

    const [search, setSearch] = useState("");
    const [exportSuccess, setExportSuccess] = useState(false);
    const [result, setResult] = useState([]);
    const [error, setError] = useState(null);
    const {auth} = useContext(AuthContext);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('type');
    const navigate = useNavigate();

    const onClickExport = ()=>{
        setError('')
        const is_member = type==='bank';
        const role = auth.member_id ? 'employee' : '';
        const sort = [{orderBy: "createdAt", order: 'desc'}]
        const _type = role === 'employee' ? '' : type;
        const _search = encodeURIComponent(escapeRegExp(search.trim()))
        axios.post('/users/util/export', {filters: _search, is_member, type: _type, role, sort, limit: 1000})
            .then(()=>{
                setExportSuccess(true);
            }).catch((e)=>{
                setError(e.response?.data?.message || e.message);
            });
    }

    useEffect(()=>{
        if(auth.id){
            if(auth.role!=='admin'){
                navigate('/biller-management')
            }
        }
    }, [auth])

    useEffect(() => {
        setResult([])
        if(search.trim().length>=3){
            onChange();
        }
    }, [search])

    useEffect(()=>{
        setSearch('')
    }, [type])

    const onChange = async ()=>{
        setError('')
        try{
            const sort = JSON.stringify([{orderBy: "createdAt", order: 'desc'}])
            let param = auth.member_id ? `&member_id=${auth.member_id}` : '';
            const role = auth.member_id ? 'employee' : '';
            const _type = role === 'employee' ? '' : type;
            let _search = search.trim().toLowerCase();
            _search = 'active'.indexOf(_search) > -1 && _search.length >= 2 ? 'approved' : _search;
            const resp = await axios.get(`users?type=${_type}&role=${role}&limit=1000${param}&filters=${encodeURIComponent(escapeRegExp(_search))}&sort=${encodeURIComponent(sort)}`)
            setResult(resp.data.data.filter(row=>row.id !== auth.id))
        }catch(e){
            setError(e.response?.data?.message || e.message)
        }
    }

    const canApprove = (r)=>{
        return (
            auth.id!==r.created_by 
            || (auth.id===r.created_by && r.updated_by && auth.id!==r.updated_by)) 
            && hasAccess(type==='bank'?'access-bank-admins-for-approval':'access-for-approval', 'approve', auth.permissions
        )
    }

    return <Layout>
        <div className="flex justify-between items-center">
            <div className="flex gap-3">
                <p className="text-2xl font-semibold leading-[28.13px]">Access Management</p>
                {
                  !auth.member_id && hasAccess('access-for-approval', 'approve', auth.permissions) && !auth.member_id && type==='internal' && <Link to="/pending-users?type=internal" className="p-1 secondary text-white rounded-md text-base font-bold leading-[18.75px]">View User for Approval</Link>
                }
                {
                  !auth.member_id && hasAccess('access-bank-admins-for-approval', 'approve', auth.permissions) && !auth.member_id && type==='bank' && <Link to="/pending-users?type=bank" className="p-1 secondary text-white rounded-md text-base font-bold leading-[18.75px]">View Bank Admin for Approval</Link>
                }
                {
                  auth.member_id && hasAccess('access-for-approval', 'approve', auth.permissions) && !!auth.member_id && <Link to="/pending-employee" className="p-1 text-base secondary text-white rounded-md font-bold leading-[18.75px]">View Employee for Approval</Link>
                }
            </div>
            <div className="flex items-center space-x-4">
                {hasAccess('access-all', 'extract', auth.permissions) && <button disabled={result.length===0} onClick={onClickExport} className="px-4 py-2 primary font-bold text-base leading-[18.75px] text-white rounded-md">Export</button>}
                {
                    hasAccess('access-all', 'create', auth.permissions) && <>
                        {!auth.member_id && hasAccess('access-all', 'create', auth.permissions) && type==='internal' && <Link to="/user-form" className="px-4 py-2 primary text-white rounded-md text-base font-bold leading-[18.75px]">Add User</Link>}
                        {!auth.member_id && hasAccess('access-bank-admins', 'create', auth.permissions) && type==='bank' && <Link to="/admin-form" className="px-4 py-2 primary text-white rounded-md text-base font-bold leading-[18.75px]">Add Bank Admin</Link>}
                        {auth.role==='admin' && auth.member_id && hasAccess('access-all', 'create', auth.permissions) && <Link to="/employee-form" className="px-4 py-2 primary text-white rounded-md text-base font-bold leading-[18.75px]">Add Employee</Link>}
                    </>
                }
            </div>
        </div>
        <div className="w-full mt-6">
            <div className="flex w-full gap-3">
                <div className="w-full relative">
                    <input value={search} onChange={e=>setSearch(e.target.value)} type="text" class="text-base font-normal leading-[18.75px] w-full border border-gray-300 rounded-md py-2 pl-10 pr-4 focus:outline-none focus:ring focus:border-blue-300" placeholder="Search via Email Address, Name, Created By, Status"/>
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <img src={IconSearch} alt="Search" className="cursor-pointer w-[80%]" />
                    </div>
                </div>
            </div>
        </div>
        {
            exportSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4 text-center">Your requested file has been<br/>sent to your email</p>
                        <button onClick={()=>setExportSuccess(false)} className="primary text-white px-4 py-2 rounded-md w-3/12 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {error && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{error}</span>}
       {(result.length>0) && <div className="w-full mt-5 bg-white rounded p-3">
            <div className="container overflow-auto mb-10">
                <table className={`w-full`}>
                    <thead>
                    <tr className="border-b ">
                        <th className="py-2 text-left text-lg font-medium leading-[21.09px]" width="12.5%">Email</th>
                        <th className="py-2 text-left text-lg font-medium leading-[21.09px]" width="12.5%">Name</th>
                        {type==='bank' && <th className="py-2 text-left text-lg font-medium leading-[21.09px]" width="12.5%">Bank Name</th>}
                        <th className="py-2 text-left text-lg font-medium leading-[21.09px]" width="12.5%">Date Created</th>
                        <th className="py-2 text-left text-lg font-medium leading-[21.09px]" width="12.5%">Created By</th>
                        <th className="py-2 text-left text-lg font-medium leading-[21.09px]" width="12.5%">Date of Activation</th>
                        <th className="py-2 text-left text-lg font-medium leading-[21.09px]" width="20%">Status</th>
                        {hasAccess('access-all', 'view', auth.permissions) && <th className="py-2 text-left text-lg font-medium leading-[21.09px]" width="12.5%">Action</th>}
                    </tr>
                    </thead>
                    <tbody>
                    {result.map((r)=>
                    <tr className="border-b border-gray-200">
                        <td className="py-3 text-base font-normal leading-[18.75px]">{r.email}</td>
                        <td className="py-3 text-base font-normal leading-[18.75px]">{r.firstName} {r.lastName}</td>
                        {type==='bank' && <td className="py-3 text-base font-normal leading-[18.75px]">{r.bankName}</td>}
                        <td className="py-3 text-base font-normal leading-[18.75px]">{formatDate(r.createdAt)}</td>
                        <td className="py-3 text-base font-normal leading-[18.75px]">{r.createdByName}</td>
                        <td className="py-3 text-base font-normal leading-[18.75px]">{r.date_activated ? formatDate(r.date_activated): '-'}</td>
                        <td className="py-3 text-base font-normal leading-[18.75px] flex gap-1 justify-left items-center w-full">{formatStatus(r.status)}</td>
                        {hasAccess('access-all', 'view', auth.permissions) && <td className="py-3 text-base font-normal leading-[18.75px] text-left">
                            {
                                (r.updated_by===auth.id || r.status.indexOf('Pending')===-1 || (!hasAccess('access-for-approval', 'approve', auth.permissions) && !hasAccess('access-bank-admins-for-approval', 'approve', auth.permissions))) 
                                    ? <Link to={`/${!auth.member_id ?(type==='bank'?'admin-form':'user-form'): 'employee-form'}?id=${type==='bank' ? r.member_id : r.id}`} className="px-4 py-2 primary text-white rounded-md min-w-[100.23px] block text-center">
                                        View{ (r.status!=='Pending Approval for Cancellation' && r.status!=='Cancelled' && r.status!=='DELETED' && hasAccess(type==='bank'?'access-bank-admins':'access-all', 'edit', auth.permissions) && r.status!=='Pending for Approval') ? '/Edit' : ' Details'}
                                    </Link> 
                                    :<Link to={canApprove(r) ? `/${!auth.member_id ?(type==='bank'?'approve-admin':'approve-user'): 'approve-employee'}?id=${r.id}&type=${type}&back=access-management` : `/${!auth.member_id ?(type==='bank'?'admin-form':'user-form'): 'employee-form'}?id=${type==='bank' ? r.member_id : r.id}`} className="px-4 py-2 primary text-white rounded-md block text-center min-w-[100.23px]">
                                        View {canApprove(r) ? 'and Approve': 'Details'}
                                    </Link>
                            }
                        </td>}
                    </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>}
    </Layout>
}