import Layout from "../layouts/Landing"
import PesoNet from "../assets/logo-pesonet.png"
import PDDTS from "../assets/logo-pddts.png";
import PVP from "../assets/logo-pvp.png";
import PayGate from "../assets/logo-paygate.png";
import Pas6 from "../assets/logo-pas6.png";
import CICS from "../assets/logo-cics.png";
import SmartDebit from "../assets/logo-smart-debit.png";
import { Link } from 'react-router-dom';

export default function Landing() {
  return <Layout>
    <div className="flex flex-col justify-center items-center h-screen">
        <div className="flex justify-center items-center mb-8">
            <div className="mr-4">
            <img alt="PesoNet" src={PesoNet} className=" bg-gray-200 rounded" width="320" height="193" />
            </div>
            <div className="mr-4">
            <img alt="PDDTS" src={PDDTS} className=" bg-gray-200 rounded" width="320" height="193" />
            </div>
            <div className="mr-4">
            <img alt="PVP" src={PVP} className=" bg-gray-200 rounded" width="320" height="193" />
            </div>
            <div className="mr-4">
            <img alt="PayGate" src={PayGate} className=" bg-gray-200 rounded" width="320" height="193" />
            </div>
        </div>
        <div className="flex justify-center items-center">
            <div className="mr-4">
            <img alt="Pas6" src={Pas6} className=" bg-gray-200 rounded" width="320" height="193" />
            </div>
            <div className="mr-4">
            <img alt="CICS" src={CICS} className=" bg-gray-200 rounded" width="320" height="193" />
            </div>
            <div className="mr-4">
            <Link to="/member-management"><img alt="SmartDebit" src={SmartDebit} className=" bg-gray-200 rounded" width="320" height="193" /></Link>
            </div>
        </div>
    </div>
  </Layout>
}