import {useState, useEffect, useContext} from "react";
import Layout from "../layouts/Dashboard";
import IconSearch from "../assets/icon-search.png";
import {Link, useNavigate} from "react-router-dom";
import Modal from "./Modal";
import IconSuccess from "../assets/icon-success.png";
import { axios, formatDate, formatStatus, hasAccess, escapeRegExp } from "../utils";
import { AuthContext } from "../AuthContext";
import 'react-datepicker/dist/react-datepicker.css';

export default function MemberManagement(){

    const [search, setSearch] = useState("");
    const [exportSuccess, setExportSuccess] = useState(false);
    const [rows, setRows] = useState([]);
    const [error, setError] = useState(null);
    const {auth} = useContext(AuthContext);
    const navigate = useNavigate();

    const onClickExport = ()=>{
        setError('')
        const filter = search?.indexOf('active') > -1 ? `approved` : search;
        axios.post('/member/util/export', {filters: filter})
        .then(()=>{
            setExportSuccess(true);
        }).catch((e)=>{
            setError(e.response?.data?.message || e.message);
        });
    }

    const onChange = async ()=>{
        setError('')
        try{
            const sort = JSON.stringify([{orderBy: "createdAt", order: 'desc'}])
            const resp = await axios.get(`/member?limit=1000&filters=${escapeRegExp(search)}&sort=${encodeURIComponent(sort)}`)
            setRows(resp.data.data)
        }catch(e){
            setError(e.response?.data?.message || e.message)
        }
    }

    useEffect(()=>{
        if(auth.member_id){
            navigate('/access-management');
        }
    }, []);

    useEffect(()=>{
        setRows([])
        if(search.trim().length>=3){
            onChange();
        }
    }, [search])

    return <Layout>
        <div className="flex justify-between items-center">
            <div className="flex gap-3">
                <p className="text-2xl font-semibold leading-[28.13px]">Member Management</p>
                {hasAccess('member-for-approval', 'approve', auth.permissions) && <Link to="/pending-members" className="p-1 px-2 text-base secondary font-bold text-white rounded-[10px] leading-[18.75px]">View Member for Approval</Link>}
            </div>
            
            <div className="flex items-center space-x-4">
                {hasAccess('member-all', 'extract', auth.permissions) && <button disabled={rows.length===0} onClick={onClickExport} className=" text-base font-bold px-4 py-2 primary text-white rounded-[10px] leading-[18.75px]">Export</button>}
                {hasAccess('member-all', 'create', auth.permissions) && <Link to="/member-form" className="px-4 py-2 text-base font-bold primary text-white rounded-[10px] leading-[18.75px]">Add Member</Link>}
            </div>
        </div>
        <div className="w-full mt-5">
            <div class="relative">
                <input onChange={e=>setSearch(e.target.value)} type="text" class="text-base font-normal leading-[18.75px] w-full border border-gray-300 rounded-md py-2 pl-10 pr-4 focus:outline-none focus:ring focus:border-blue-300" placeholder="Search via Bank Name, Status"/>
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <img src={IconSearch} alt="Search" className="cursor-pointer w-[80%]" />
                </div>
            </div>
        </div>
        {
            exportSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4 text-center">Your requested file has been<br/>sent to your email</p>
                        <button onClick={()=>setExportSuccess(false)} className="primary text-white px-4 py-2 rounded-md w-3/12 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {error && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{error}</span>}
        {search && <div className="w-full mt-5 bg-white border-[0.5px] rounded p-3">
            <div className="overflow-x-auto mb-10">
                <table className="table-auto border-collapse w-full">
                    <thead>
                        <tr className="border-b">
                            <th className="py-2 text-left font-medium text-lg leading-[21.09px]" width="25%">Bank Name</th>
                            <th className="py-2 text-left font-medium text-lg leading-[21.09px]" width="25%">Date Activated</th>
                            <th className="py-2 text-left font-medium text-lg leading-[21.09px]" width="25%">Status</th>
                            {hasAccess('member-all', 'view', auth.permissions) && <th className="py-2 text-left font-medium text-lg leading-[21.09px]" width="10%">Action</th>}
                        </tr>
                    </thead>
                    <tbody>
                    {rows.map((r)=><tr className="border-b border-gray-200">
                        <td className="py-2 text-base leading-[18.75px]">{r.bank_name}</td>
                        <td className="py-2 text-base leading-[18.75px]">{formatDate(r.createdAt)}</td>
                        <td className="py-2 text-base leading-[18.75px] flex gap-1 justify-start items-center pt-3">
                            {formatStatus(r.status, {isMember: 1})}
                        </td>
                        {hasAccess('member-all', 'view', auth.permissions) && <td className="py-2 text-left">
                            {
                                (
                                    auth.attribute==='super' 
                                    || r.status!=='Pending for Approval' 
                                    || !hasAccess('member-for-approval', 'approve', auth.permissions) 
                                    || (r.created_by === auth.id || (auth.id===r.created_by && r.updated_by && auth.id===r.updated_by))
                                )
                                    ? <Link to={`/member-form?id=${r.id}`} className="px-4 py-2 primary text-white text-center rounded-md w-[143.84px] inline-block">
                                        View{(r.status!=='Pending Approval for Cancellation' && r.status!=='DELETED' && r.status!=='Pending for Approval' && hasAccess('member-all', 'edit', auth.permissions)) && `/Edit`}
                                    </Link>
                                    : <Link to={auth.id!==r.updated_by ? `/approve-member?id=${r.id}&page=member-management` : `/member-form?id=${r.id}`} className="px-4 py-2 primary text-white text-center rounded-md w-[143.84px] inline-block">View {r.updated_by!==auth.id && 'and Approve'}</Link>
                            }
                        </td>}
                    </tr>)}
                    </tbody>
                </table>
            </div>
        </div>}
    </Layout>
}