import {useState, useEffect, useContext} from "react";
import Layout from "../layouts/Dashboard";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Modal from "./Modal";
import IconSuccess from "../assets/icon-success.png";
import IconCheck from "../assets/icon-checked.png";
import { axios, emailRegex, isValidContactNumber, hasAccess } from "../utils";
import IconDelete from "../assets/icon-delete.png";
import IconDeleteConfirm from "../assets/icon-delete-confirm.png";
import { AuthContext } from "../AuthContext";
import IconClock from "../assets/icon-clock.png";
import IconCancel from "../assets/icon-cancel.png";
import IconCancelGreen from "../assets/icon-cancel-green.png";
import IconSuspend from "../assets/icon-suspend.png";

export default function AddMember(){

    const {auth} = useContext(AuthContext);
    const [form, setForm] = useState({
        notifications: [], 
        types: [], 
        active: false,
        bank_name: "",
        bic: "",
        bank_address: "",
        liason_name: "",
        liason_contact: "",
        liason_email: "",
    });
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const [canEdit, setCanEdit] = useState(false);
    const [cancelConfirmModal, setCancelConfirmModal] = useState(false);
    const [cancelSuccess, setCancelSuccess] = useState(false);
    const [suspendConfirmModal, setSuspendConfirmModal] = useState(false);
    const [suspendSuccess, setSuspendSuccess] = useState(false);

    useEffect(()=>{
        if(auth.id && id){
            axios.get(`/member/${id}`).then(resp=>{
                setForm({...resp.data, notifications: JSON.parse(resp.data.notifications), types: JSON.parse(resp.data.type)});
                setCanEdit(hasAccess('member-all', 'edit', auth.permissions) && resp.data.status!=='Pending for Approval' && resp.data.status!=='Pending Approval for Cancellation')
            }).catch(e=>{
                setError(e.response?.data?.message || e.message);
            })
        }
    }, [auth])

    const handleChange = (e) => {
        let {name, value} = e.target;
        if(name==='bic'){
            value = value.trim().toUpperCase();
        }else if(name==='liason_contact' || name==='liason2_contact'){
            value = value.trim().replace(/\D/g, '');
        }else if(name==='liason_name' || name==='liason2_name'){
            value = value.replace(/[^a-zA-Z\s]/g, '');
        }
        setForm({...form, [name]: value});
    }

    const onCheck = (e)=>{
        const {name, checked} = e.target;
        setForm(prev=>({...prev, notifications: (checked ? [...prev.notifications, name] : prev.notifications.filter(item=>item !== name))}));
    }

    const onTypesCheck = (e)=>{
        const {name, checked} = e.target;
        setForm(prev=>({...prev, types: (checked ? [...prev.types, name] : prev.types.filter(item=>item !== name))}));
    }

    const onDelete = async()=>{
        setDeleteConfirmModal(false);
        setError('')
        await axios.patch(`/member/${id}`, {status: 'Pending Approval for Deletion'}).then(()=>setDeleteSuccess(true)).catch(e=>{
            setError(e.response?.data?.message || e.message);
        });
    }

    const onCancel = async()=>{
        setCancelConfirmModal(false);
        setError('')
        await axios.patch(`/member/${id}`, {status: 'Pending Approval for Cancellation'}).then(()=>setCancelSuccess(true)).catch(e=>{
            setError(e.response?.data?.message || e.message);
        });
    }

    const onSuspend = async()=>{
        setSuspendConfirmModal(false);
        setError('')
        await axios.patch(`/member/${user.id}`, {status: 'suspended'}).then(()=>setSuspendSuccess(true)).catch(e=>{
            setError(e.response?.data?.message || e.message);
        });
    }

    const onSubmit = async ()=>{

        setErrors({});
        const hasEmpty = Object.keys(form).filter(f=>!['admins', 'notifications', 'types', 'active', 'liason2_contact', 'liason2_email', 'liason2_name', 'reject_reason'].includes(f)).filter(key=>!form[key]);
        
        if(form.types.length === 0){
            hasEmpty.push('type')
        }
        if(hasEmpty.length){
            setErrors(hasEmpty.reduce((acc, key)=>({...acc, [key]: `${key.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase())} is required.`}), {}));
        }
        if(!emailRegex.test(form.liason_email)){
            setErrors(prev=>({...prev, liason_email: 'Invalid email address'}))
            hasEmpty.push('liason_email')
        }

        if(!isValidContactNumber(form.liason_contact)){
            setErrors(prev=>({...prev, liason_contact: 'Invalid Contact Number.'}))
            hasEmpty.push('liason_contact')
        }
        if(form.liason2_name || form.liason2_contact || form.liason2_email){
            if(!form.liason2_name){
                setErrors(prev=>({...prev, liason2_name: 'Liaison Name is required.'}))
                hasEmpty.push('liason2_name')
            }
            if(!emailRegex.test(form.liason2_email)){
                setErrors(prev=>({...prev, liason2_email: 'Invalid email address'}))
                hasEmpty.push('liason2_email')
            }
            if(!isValidContactNumber(form.liason2_contact)){
                setErrors(prev=>({...prev, liason2_contact: 'Invalid Contact Number.'}))
                hasEmpty.push('liason2_contact')
            }
        }

        if(hasEmpty.length>0){
            return;
        }

        try{
            const params = {
                ...form, 
                liason2_name: form.liason2_name || "",
                liason2_contact: form.liason2_contact || "",
                liason2_email: form.liason2_email || "",
                notifications: JSON.stringify(form.notifications),
                active: true,
                type: JSON.stringify(form.types),
                status: auth.attribute==='super' ? 'approved': 'Pending for Approval'
            };
            if(id) await axios.patch(`/member/${id}`, {...params});
            else await axios.post("/member", params);
            setSuccess(true);
        }catch(e){
            setError(e.response?.data?.errors ? Object.values(e.response.data.errors)[0] : e.message);
        }
    }

    return <Layout>
        <div className="flex justify-between items-center">
            <p className="text-2xl font-bold">{id ? (canEdit ? 'Edit' : 'View') :'Add'} Member</p>
            <div className="flex items-center space-x-4">
                {id && <Link to={`/member-history?id=${id}`} className="primary text-white px-4 py-2 rounded-md flex justify-center gap-2"><img src={IconClock} width={22} alt="View History Icon"/> <span className="pt-[1px]">View History</span></Link>}
                <Link to="/member-management" className="px-4 py-2 secondary text-white rounded-md">Back</Link>
            </div>
        </div>
        {
            suspendSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconDeleteConfirm} alt="Success" className="mb-4"/>
                        <p className="font-semibold text-2xl mb-4">Suspend Success</p>
                        <span className="mb-6">You successfully suspended a user.</span>
                        <button onClick={()=>navigate('/member-management')} className="primary text-white px-4 py-2 rounded-md w-1/2 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            suspendConfirmModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconDeleteConfirm} alt="Success" className="mb-4"/>
                        <p className="font-semibold text-2xl mb-4">Suspend</p>
                        <span className="mb-6">Are you sure you want to suspend?</span>
                        <div className="flex gap-3">
                            <button onClick={()=>setSuspendConfirmModal(false)} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Cancel</button>
                            <button onClick={onSuspend} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Suspend</button>
                        </div>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            cancelSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconDeleteConfirm} alt="Success" className="mb-4"/>
                        <p className="font-semibold text-2xl mb-4">Cancel Success</p>
                        <span className="mb-6">You successfully cancelled a member.</span>
                        <button onClick={()=>navigate('/member-management')} className="primary text-white px-4 py-2 rounded-md w-1/2 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            cancelConfirmModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconCancelGreen} alt="Success" className="mb-4"/>
                        <p className="font-semibold text-2xl mb-4">Cancel</p>
                        <span className="mb-6">Are you sure you want to cancel?</span>
                        <div className="flex gap-3">
                            <button onClick={()=>setCancelConfirmModal(false)} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Cancel</button>
                            <button onClick={onCancel} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            deleteSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconDeleteConfirm} alt="Success" className="mb-4"/>
                        <p className="font-semibold text-2xl mb-4">Delete Success</p>
                        <span className="mb-6">You successfully deleted a member.</span>
                        <button onClick={()=>navigate('/member-management')} className="primary text-white px-4 py-2 rounded-md w-1/2 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            deleteConfirmModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconDeleteConfirm} alt="Success" className="mb-4"/>
                        <p className="font-semibold text-2xl mb-4">Delete</p>
                        <span className="mb-6">Are you sure you want to delete?</span>
                        <div className="flex gap-3">
                            <button onClick={()=>setDeleteConfirmModal(false)} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Cancel</button>
                            <button onClick={onDelete} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Delete</button>
                        </div>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            success && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-semibold text-2xl mb-4">Member {id ? 'Updated': 'Added'}!</p>
                        <span className="mb-6 text-[16px] font-normal">You successfully { id? 'updated' : 'added'} a new member.</span>
                        <button onClick={()=>navigate('/member-management')} className="primary text-white text-[18px] font-medium px-4 py-2 rounded-md w-[97px] block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        <div className={`w-full bg-white border rounded p-3 pt-10 mt-5 pb-6 ${(id && !canEdit) && 'pointer-events-none'}`}>
            {error && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{error}</span>}
            <div className="flex flex-col items-center">
                {
                    form.status==='Rejected' && <>
                        <div className="flex items-start flex-col mb-4 w-3/6">
                            <div className="font-bold">Status: <span className="text-red-500">Rejected</span></div>
                            <div className="font-bold">Specify: <span className="text-red-500">{form.reject_reason}</span></div>
                        </div>
                    </>
                }
                <div className="w-3/6">
                    <div class="relative input-container">
                        <input 
                            type="text" 
                            id="bank_name" 
                            name="bank_name" 
                            onChange={handleChange}
                            value={form.bank_name}
                            className={`float pt-4 w-full py-2 border rounded-md ${form.bank_name ? 'border-black': 'border-gray-300 '}`}
                        />
                        <label 
                            for="bank_name" 
                            className={form.bank_name?'active':''}
                        >
                            Bank Name
                        </label>
                    </div>
                    {errors.bank_name && <span className="block text-red-500 font-semibold text-xs italic">{errors.bank_name}</span>}
                </div>
                <div className=" w-3/6">
                    <div class="relative input-container">
                        <input 
                            type="text" 
                            id="bic" 
                            name="bic" 
                            onChange={handleChange}
                            value={form.bic}
                            className={`float pt-4 w-full py-2 border rounded-md ${form.bic ? 'border-black': 'border-gray-300 '}`}
                        />
                        <label 
                            for="bic" 
                            className={`bic ${form.bic?'active':''}`}
                        >
                            BIC / Swift / Pesonet Identifiers
                        </label>
                    </div>
                    {errors.bic && <span className="block text-red-500 font-semibold text-xs italic">{errors.bic}</span>}
                </div>
                <div className="flex justify-between mb-4 w-3/6">
                    <div className={`mr-4 border p-3 rounded flex-1 ${form.types.includes('acquirer')? 'border-black': ''}`}>
                        <div className="flex">
                            {!form.types.includes('acquirer') &&<input onClick={()=>onTypesCheck({target:{name: 'acquirer', checked: true}})} type="checkbox"
                                className="outline-none h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded cursor-pointer"/>}
                            {form.types.includes('acquirer') && <img onClick={()=>onTypesCheck({target:{name: 'acquirer', checked: false}})} src={IconCheck} alt="Check" className=" cursor-pointer"/>}
                            <span className="px-2 text-gray-500">Acquirer</span>
                        </div>
                    </div>
                    <div className={`border p-3 rounded flex-1 ${form.types.includes('issuer')? 'border-black': ''}`}>
                        <div className="flex">
                            {!form.types.includes('issuer') &&<input onClick={()=>onTypesCheck({target:{name: 'issuer', checked: true}})} type="checkbox"
                                className="outline-none h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded cursor-pointer"/>}
                            {form.types.includes('issuer') && <img onClick={()=>onTypesCheck({target:{name: 'issuer', checked: false}})} src={IconCheck} alt="Check" className=" cursor-pointer"/>}
                            <span className="px-2 text-gray-500">Issuer</span>
                        </div>
                    </div>
                </div>
                {errors.type && <div className="flex mt-[-10px] mb-4 w-3/6">
                    <span className="block text-red-500 font-semibold text-xs italic">{errors.type}</span>
                </div>}
                <div className="mb-4 w-3/6">
                    <div class="relative">
                        <input 
                            type="text" 
                            id="bank_address" 
                            name="bank_address" 
                            onChange={handleChange}
                            value={form.bank_address}
                            className={`float pt-4 w-full py-2 border rounded-md ${form.bank_address ? 'border-black': 'border-gray-300 '}`}
                        />
                        <label 
                            for="bank_address" 
                            className={form.bank_address?'active':''}
                        >
                            Bank Address
                        </label>
                    </div>
                    {errors.bank_address && <span className="block text-red-500 font-semibold text-xs italic">{errors.bank_address}</span>}
                </div>
                <div className={`mb-4 w-3/6 border rounded flex ${form.liason_name || form.liason_contact ? 'border-black': ''}`}>
                    <div class="relative w-full">
                        <input 
                            type="text" 
                            id="liason_name" 
                            name="liason_name" 
                            onChange={handleChange}
                            value={form.liason_name}
                            className="pt-4 w-full py-2 outline-none focus:border-blue-500"
                        />
                        <label 
                            for="liason_name" 
                            className={form.liason_name?'active':''}
                        >
                            Liaison Name
                        </label>
                    </div>
                    <div className="h-full border-l border-gray-300 p-1 pl-0 mt-2">&nbsp;</div>
                    <div class="relative w-full">
                        <input 
                            type="text" 
                            id="liason_contact" 
                            name="liason_contact" 
                            onChange={handleChange}
                            value={form.liason_contact}
                            className="pt-4 w-full py-2 rounded-md outline-none focus:border-blue-500"
                        />
                        <label 
                            for="liason_contact" 
                            className={`mobile ${form.liason_contact?'active':''}`}
                        >
                            Mobile or Landline Number
                        </label>
                    </div>
                </div>
                <div className="w-3/6 mt-[-10px] mb-4 flex">
                    {errors.liason_name && <span className="w-5/6 text-start block text-red-500 font-semibold text-xs italic">{errors.liason_name}</span>}
                    {errors.liason_contact && <span className=" w-5/6 text-start block text-red-500 font-semibold text-xs italic">{errors.liason_contact}</span>}
                </div>
                <div className="mb-4 w-3/6">
                    <div class="relative">
                        <input 
                            type="text" 
                            id="liason_email" 
                            name="liason_email" 
                            onChange={handleChange}
                            value={form.liason_email}
                            className={`float pt-4 w-full py-2 border rounded-md ${form.liason_email ? 'border-black': 'border-gray-300 '}`}
                        />
                        <label 
                            for="liason_email" 
                            className={form.liason_email?'active':''}
                        >
                            Email Address
                        </label>
                    </div>
                    {errors.liason_email && <span className="block text-red-500 font-semibold text-xs italic">{errors.liason_email}</span>}
                </div>
                <div className={`mb-4 w-3/6 border rounded flex ${form.liason2_name || form.liason2_contact ? 'border-black': ''}`}>
                    <div class="relative w-full">
                        <input 
                            type="text" 
                            id="liason2_name" 
                            name="liason2_name" 
                            onChange={handleChange}
                            value={form.liason2_name}
                            className="pt-4 w-full py-2 outline-none focus:border-blue-500"
                        />
                        <label 
                            for="liason2_name" 
                            className={`liason ${form.liason2_name?'active':''}`}
                        >
                            Liaison Name (Optional)
                        </label>
                    </div>
                    <div className="h-full border-l border-gray-300 p-1 pl-0 mt-2">&nbsp;</div>
                    <div class="relative w-full">
                        <input 
                            type="text" 
                            id="liason2_contact" 
                            name="liason2_contact" 
                            onChange={handleChange}
                            value={form.liason2_contact}
                            className="pt-4 w-full py-2 rounded-md outline-none focus:border-blue-500"
                        />
                        <label 
                            for="liason2_contact" 
                            className={`mobile ${form.liason2_contact?'active':''}`}
                        >
                            Mobile or Landline Number
                        </label>
                    </div>
                </div>
                <div className="w-3/6 mt-[-10px] mb-4 flex">
                    {errors.liason2_name && <span className="w-5/6 text-start block text-red-500 font-semibold text-xs italic">{errors.liason2_name}</span>}
                    {errors.liason2_contact && <span className=" w-5/6 text-start block text-red-500 font-semibold text-xs italic">{errors.liason2_contact}</span>}
                </div>
                <div className="mb-2 w-3/6">
                    <div class="relative">
                        <input 
                            type="text" 
                            id="liason2_email" 
                            name="liason2_email" 
                            onChange={handleChange}
                            value={form.liason2_email}
                            className={`float pt-4 w-full py-2 border rounded-md ${form.liason2_email ? 'border-black': 'border-gray-300 '}`}
                        />
                        <label 
                            for="liason2_email" 
                            className={form.liason2_email?'active':''}
                        >
                            Email Address
                        </label>
                    </div>
                    {errors.liason2_email && <span className="block text-red-500 font-semibold text-xs italic">{errors.liason2_email}</span>}
                </div>
                <div className="flex justify-between w-3/6 pb-2">
                    <p className="font-bold">Notifications</p>
                </div>
                <div className="flex justify-between mb-4 w-3/6">
                    <div className={`mr-4 border p-3 rounded flex-1 ${form.notifications.includes('email')? 'border-black': ''}`}>
                        <div className="flex">
                            {!form.notifications.includes('email') &&<input onClick={()=>onCheck({target:{name: 'email', checked: true}})} type="checkbox"
                                className="outline-none h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded cursor-pointer"/>}
                            {form.notifications.includes('email') && <img onClick={()=>onCheck({target:{name: 'email', checked: false}})} src={IconCheck} alt="Check" className=" cursor-pointer"/>}
                            <span className="px-2 text-gray-500">Email</span>
                        </div>
                    </div>
                    <div className={`border p-3 rounded flex-1 ${form.notifications.includes('portal')? 'border-black': ''}`}>
                        <div className="flex">
                            {!form.notifications.includes('portal') &&<input onClick={()=>onCheck({target:{name: 'portal', checked: true}})} type="checkbox"
                                className="outline-none h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded cursor-pointer"/>}
                            {form.notifications.includes('portal') && <img onClick={()=>onCheck({target:{name: 'portal', checked: false}})} src={IconCheck} alt="Check" className=" cursor-pointer"/>}
                            <span className="px-2 text-gray-500">Portal</span>
                        </div>
                    </div>
                </div>
                <div className="mb-[30px] w-3/6 text-center">
                    <button disabled={ (id && !canEdit) || form.notifications.length===0 || form.types.length===0 || Object.keys(form).filter(f=>!['reject_reason','admins', 'notifications', 'types', 'active', 'liason2_contact', 'liason2_email', 'liason2_name'].includes(f)).some(key=>!form[key])} onClick={onSubmit} className="primary text-white px-4 py-2 rounded-md w-full block">{id ? 'Save & Submit': 'Add Member'} </button>
                </div>
                <div className={`${form.status==='Pending Approval for Cancellation' ? 'hidden':''} w-5/12 flex justify-between`}>
                {
                    (hasAccess('member-all', 'delete', auth.permissions) && id) && <div onClick={()=>setCancelConfirmModal(true)} className="justify-center flex text-red-500 gap-2 cursor-pointer">
                        <img src={IconCancel} alt="Cancel" className="cursor-pointer w-[19px] h-[21px]" />
                        Cancel
                    </div>
                }
                {
                    (false && hasAccess('access-all', 'delete', auth.permissions) && id) && <div onClick={()=>setSuspendConfirmModal(true)} className={`justify-center flex text-red-500 gap-2 cursor-pointer`}>
                        <img src={IconSuspend} alt="Suspend" className="cursor-pointer" />
                        Suspend
                    </div>
                }
                {
                    (form.status!=='DELETED' && hasAccess('member-all', 'delete', auth.permissions) && id) && <div onClick={()=>setDeleteConfirmModal(true)} className="justify-center flex text-red-500 gap-2 cursor-pointer">
                        <img src={IconDelete} alt="Delete" className="cursor-pointer w-[19px] h-[21px]" />
                        Delete
                    </div>
                }
                </div>
            </div>
        </div>
    </Layout>
}