import Layout from "../layouts/Dashboard";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import IconSearch from "../assets/icon-search.png";
import {getFormattedDate, axios, formatDate, formatNumber} from "../utils";
import Modal from "./Modal";
import IconSuccess from "../assets/icon-success.png";

export default function Collections(){

    const [selected, setSelected] = useState("settlement");
    const [search, setSearch] = useState("");
    const [exportSuccess, setExportSuccess] = useState(false);
    const [report, setReport] = useState({banks: {}, total_loss: 0, total_win: 0});
    const [banks, setBanks] = useState({});

    useEffect(()=>{
        if(selected==='net-settlement'){
            axios.get(`/reports/banks/net-settlement`).then(resp=>{
                setReport(resp.data)
            });
        }else if(selected==='settlement' && search.trim()){
            setBanks({})
            axios.get(`/reports/banks/settlement`).then(resp=>{
                setBanks(resp.data)
            });
        }
    }, [selected, search])

    const onExport = ()=>{
        setExportSuccess(true)
    }

    return <Layout>
        {
            exportSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4 text-center">Your requested file has been<br/>sent to your email</p>
                        <button onClick={()=>setExportSuccess(false)} className="primary text-white px-4 py-2 rounded-md w-3/12 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        <div className="flex justify-between items-center">
            <p className="text-2xl font-bold">Presentment and Collection Management</p>
            <button disabled={selected==='settlement' && Object.keys(banks).length===0 || selected==='net-settlement' && Object.keys(report.banks).length===0} onClick={onExport} className="px-4 py-2 primary font-bold text-base leading-[18.75px] text-white rounded-md">Export</button>
        </div>
        <div className="flex justify-between">
            <div className="flex gap-3 p-3">
                <div onClick={()=>setSelected('settlement')} className={`pb-3 cursor-pointer ${selected==='settlement' && 'tab active !border-b-2'}`}>Settlement Report</div>
                <div onClick={()=>setSelected('net-settlement')} className={`pb-3 cursor-pointer ${selected==='net-settlement' && 'tab active !border-b-2'}`}>Net Settlement Report</div>
            </div>
        </div>
        {
            selected==='net-settlement' && <>
                <div className="flex mt-2">
                    <div>Following are the results of the Philippine EFT Systems and Operations Network (PESONet) on {getFormattedDate(new Date())} for cycle 1 for your information.</div>
                </div>
                <div className="w-full mt-3 bg-white rounded p-3">
                    <div className="overflow-x-auto mb-3">
                        <table className="table-auto border-collapse w-full">
                            <thead>
                                <tr>
                                    <th className="py-2 text-left" width="15%">Account Code</th>
                                    <th className="py-2 text-left" width="25%">Bank Name</th>
                                    <th className="py-2 text-right" width="10%">&nbsp;</th>
                                    <th className="py-2 text-right" width="10%">Loss (Debit)</th>
                                    <th className="py-2 text-right" width="10%">&nbsp;</th>
                                    <th className="py-2 text-right" width="10%">Win (Credit)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    selected==='net-settlement' && Object.keys(report.banks).map((bank)=>{
                                        return <tr>
                                            <td>&nbsp;</td>
                                            <td className="py-2 text-left">{bank}</td>
                                            <td className="py-2 text-right">{report.banks[bank].loss_amount ? 'PHP': ''}</td>
                                            <td className="py-2 text-right">{report.banks[bank].loss_amount ? formatNumber(report.banks[bank].loss_amount) : ''} {report.banks[bank].loss_amount ? '-':''}</td>
                                            <td className="py-2 text-right">{report.banks[bank].win_amount ? 'PHP': ''}</td>
                                            <td className="py-2 text-right">{report.banks[bank].win_amount ? formatNumber(report.banks[bank].win_amount): ''} {report.banks[bank].win_amount ? '+': ''}</td>
                                        </tr>
                                    })
                                }
                                <tr>
                                    <td colSpan="2" className="bg-black text-white pl-6">TOTAL</td>
                                    <td className="py-2 bg-black text-white text-right">PHP</td>
                                    <td className="py-2 bg-black text-white text-right">{formatNumber(report.total_loss)}-</td>
                                    <td className="py-2 bg-black text-white text-right">PHP</td>
                                    <td className="py-2 bg-black text-white text-right">{formatNumber(report.total_win)}+</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="flex justify-end font-normal text-sm leading-[16.41px] pt-3">PHILIPPINE CLEARING HOUSE CORPORATION</div>
            </>
        }
        {selected==='settlement' && <>
            <div className="w-full mt-0">
                <div className="flex w-full gap-3">
                    <div className="w-full relative">
                        <input value={search} onChange={e=>setSearch(e.target.value)} type="text" class="text-base font-normal leading-[18.75px] w-full border border-gray-300 rounded-md py-2 pl-10 pr-4 focus:outline-none focus:ring focus:border-blue-300" placeholder="Search via Bank Name, Date"/>
                        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <img src={IconSearch} alt="Search" className="cursor-pointer w-[80%]" />
                        </div>
                    </div>
                </div>
            </div>
            {Object.keys(banks).length>0 && <div className="w-full mt-5 bg-white rounded p-3">
                <div className="overflow-x-auto mb-10">
                    <table className="table-auto border-collapse w-full">
                        <thead>
                        <tr className="border-b">
                            <th className="py-2 text-left" width="65%">Bank Name</th>
                            <th className="py-2 text-left" width="20%">Date</th>
                            <th className="py-2 text-center" width="15%">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Object.keys(banks).map((r)=><tr className="border-b border-gray-200">
                            <td className="py-2">{r}</td>
                            <td className="py-2">{formatDate(banks[r].date)}</td>
                            <td className="py-4 text-center">
                                <Link to={`/collections/net-settlement?id=${banks[r].member_id}`} className="px-4 py-2 primary text-white text-center rounded-md inline-block">View Report</Link>
                            </td>
                        </tr>)}
                        </tbody>
                    </table>
                </div>
            </div>}
        </>}
    </Layout>
}