import {useContext, useState, useEffect} from "react";
import Layout from "../layouts/Dashboard";
import IconSearch from "../assets/icon-search.png";
import IconSuccess from "../assets/icon-success.png";
import Modal from "./Modal";
import { Link } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import { axios, formatStatus, hasAccess, escapeRegExp } from "../utils";

export default function BillerManagement(){

    const {auth} = useContext(AuthContext);
    const [search, setSearch] = useState('');
    const [exportSuccess, setExportSuccess] = useState(false);
    const [rows, setRows] = useState([]);
    const [error, setError] = useState(null);

    const onClickExport = ()=>{
        setError('')
        const member_id = !!auth.member_id ? auth.member_id : '';
        let payload = {
            member_id
        }
        if ('active'.indexOf(search.toLowerCase()) > -1 && search.length >= 2){
            payload['filters'] = ""
            payload['status'] = "approved"
        }else{
            payload['filters'] = search
        }
        axios.post('/billers/util/export', payload)
        .then(()=>{
            setExportSuccess(true);
        }).catch((e)=>{
            setError(e.response?.data?.message || e.message);
        });
    }

    const onChange = async ()=>{
        setRows([])
        if(search.trim().length>=3){
            setError('')
            try{
                const sort = JSON.stringify([{orderBy: "createdAt", order: 'desc'}])
                let status = 'active'.indexOf(search.toLowerCase()) > -1 && search.length >= 2 ? '&status=approved' : '';
                let _search = search;
                if(status) _search = '';
                const resp = await axios.get(`/billers?limit=1000${status}&filters=${escapeRegExp(_search.toLowerCase())}&sort=${encodeURIComponent(sort)}`);
                setRows(resp.data.data);
            }catch(e){
                setError(e.response?.data?.message || e.message);
            }
        }
    }

    const canApprove = (row)=>{
        return (
            row.member_id === auth.member_id 
            && (row.created_by !== auth.id || (auth.id===row.created_by && row.updated_by && auth.id!==row.updated_by))
            && hasAccess('biller-for-approval', 'edit', auth.permissions)
        )
    }

    useEffect(()=>{onChange()}, [search])

    return <Layout>
        <div className="flex justify-between items-center">
            <div className="flex gap-3">
                <p className="text-2xl font-bold leading-[28.13px]">Biller Management</p>
                {hasAccess('biller-for-approval', 'approve', auth.permissions) && <Link to="/pending-billers" className="p-1 secondary text-white rounded-md font-bold text-base leading-[18.75px]">View Biller for Approval</Link>}
            </div>
            <div className="flex items-center space-x-4">
                {hasAccess('biller-all', 'extract', auth.permissions) && <button disabled={rows.length===0} onClick={onClickExport} className="px-4 py-2 primary text-white rounded-md font-bold text-base leading-[18.75px]">Export</button>}
                {auth.member_id && hasAccess('biller-all', 'create', auth.permissions) && <Link to="/biller-form" className="px-4 py-2 primary text-white rounded-md font-bold text-base leading-[18.75px]">Add Biller</Link>}
            </div>
        </div>
        <div className="w-full mt-5">
            <div class="relative">
                <input onChange={e=>setSearch(e.target.value)} type="text" class="text-base font-normal leading-[18.75px] w-full border border-gray-300 rounded-md py-2 pl-10 pr-4 focus:outline-none focus:ring focus:border-blue-300" placeholder="Search via Biller ID, Biller Name, Created by, Approved By, Status"/>
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <img src={IconSearch} alt="Search" className="cursor-pointer w-[80%]" />
                </div>
            </div>
        </div>
        {
            exportSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4 text-center">Your requested file has been<br/>sent to your email</p>
                        <button onClick={()=>setExportSuccess(false)} className="primary text-white px-4 py-2 rounded-md w-3/12 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {error && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{error}</span>}
        {rows.length > 0 && <div className="w-full mt-5 bg-white rounded p-3">
                <div className="overflow-x-auto mb-3">
                    <table className="table-auto border-collapse w-full">
                        <thead>
                        <tr className="border-b">
                            <th className="py-2 text-left text-lg font-medium leading-[21.09px]" width="10%">Biller ID</th>
                            <th className="py-2 text-left text-lg font-medium leading-[21.09px]" width="15%">Biller Name</th>
                            <th className="py-2 text-left text-lg font-medium leading-[21.09px]" width="15%">Created By</th>
                            <th className="py-2 text-left text-lg font-medium leading-[21.09px]" width="15%">Status</th>
                            <th className="py-2 text-left text-lg font-medium leading-[21.09px]" width="15%">Approved By</th>
                            {(hasAccess('biller-all', 'view', auth.permissions) || hasAccess('biller-all', 'view', auth.permissions)) && <th className="py-2 text-left text-lg font-medium leading-[21.09px]" width="15%">Action</th>}
                        </tr>
                        </thead>
                        <tbody>
                        {
                            rows.map(row=><tr className="border-b border-gray-200">
                                <td className="py-2 text-base font-normal leading-[18.75px]">{row.biller_id}</td>
                                <td className="py-2 text-base font-normal leading-[18.75px]">{row.biller_name}</td>
                                <td className="py-2 text-base font-normal leading-[18.75px]">{row.createdByName}</td>
                                <td className="py-2 text-base font-normal leading-[18.75px] flex gap-1 justify-start items-center">{formatStatus(row.status)}</td>
                                <td className="py-2 text-base font-normal leading-[18.75px]">{row.approvedByName}</td>
                                {(hasAccess('biller-all', 'view', auth.permissions) || hasAccess('biller-all', 'view', auth.permissions)) && <td className="py-2 text-left">
                                    {
                                        (row.updated_by===auth.id || row.status!=='Pending for Approval' || (!auth.member_id && hasAccess('biller-all', 'view', auth.permissions)))
                                            ? <Link to={`/biller-form?id=${row.id}`} className="px-4 py-2 primary text-white rounded-md">
                                                View{((['approved', 'Rejected'].includes(row.status) && hasAccess('biller-all', 'edit', auth.permissions))) && '/Edit'} Details
                                            </Link>
                                            : <Link to={canApprove(row) ? `/approve-biller?id=${row.id}&back=biller-management`:`/biller-form?id=${row.id}`} className="px-4 py-2 primary text-white rounded-md">
                                                View {canApprove(row) ? 'and Approve' : 'Details'}
                                            </Link>
                                    }
                                    
                                </td>}
                            </tr>)
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        }
    </Layout>
}