import Layout from "../layouts/Dashboard";
import {Link, useNavigate, useLocation} from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../AuthContext";
import { axios, formatDate, hasAccess, numberWithCommas } from "../utils";
import Modal from "./Modal";
import IconSuccess from "../assets/icon-success.png";

const reasons = [
    {label: 'AC01', value: 'IncorrectAccountNumber'},
    {label: 'AC04', value: 'ClosedAccountNumber'},
    {label: 'AC06', value: 'BlockedAccount'},
    {label: 'AG01', value: 'TransactionForbidden'},
    {label: 'AM02', value: 'NotAllowedAmount'},
    {label: 'AM05', value: 'Duplication'},
    {label: 'MS03', value: 'NotSpecifiedReasonAgentGenerated'},
    {label: 'RR04', value: 'Regulatory Reason '},
    {label: 'BE01', value: 'InconsistenWithEndCustomer'},
    {label: 'BE04', value: 'MissingCreditorAddress'},
    {label: 'BE05', value: 'UnrecognisedInitiatingParty'},
    {label: 'BE07', value: 'MissingDebtorAddress'},
]

export default function ApproveMandate() {

    const {auth} = useContext(AuthContext);
    const [form, setForm] = useState({});
    const [approvedModal, setApprovedModal] = useState();
    const [rejectModal, setRejectModal] = useState();
    const [rejectReason, setRejectReason] = useState('');
    const [biller, setBiller] = useState({});
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const tab = queryParams.get('tab');
    const page = queryParams.get('page') || 'pending-mandate';
    const [member, setMember] = useState({bank_name: ''});
    const [rejectSuccess, setRejectSuccess] = useState();
    const [issuingBank, setIssuingBank] = useState({});

    const onApprove = async(approved)=>{
        if(approved){
            let status = form.status === 'Pending for Acceptance' ? 'Active and Accepted' : (form.status==='Pending for Approval' ? 'Pending for Confirmation':'Pending for Acceptance');
            await axios.patch(`/mandates/${id}`, {status})
            setApprovedModal(true)
        }else{
            setRejectModal(true);
        }
    }

    const onReject = async()=>{
        await axios.patch(`/mandates/${id}`, {status: 'Rejected', reject_reason: rejectReason})
        setRejectSuccess(true);
    }
    
    useEffect(()=>{
        axios.get(`/mandates/${id}`).then(resp=>{
            setForm(resp.data);
            axios.get(`/billers/${resp.data.biller_id}`).then(resp=>setBiller(resp.data))
            axios.get(`/member/${resp.data.member_id}`).then(resp=>setMember(resp.data))
            axios.get(`/member/${resp.data.bank_id}`).then(resp=>setIssuingBank(resp.data))
        });
    }, []);

  return <Layout>
    <div className="flex justify-between items-center">
        <p className="text-2xl font-semibold">View and Approve</p>
        <div className="flex items-center space-x-4">
            <Link to={`${page ? `/${page}` : '/pending-mandate'}`} className="px-4 py-2 secondary text-white rounded-md">Back</Link>
        </div>
    </div>
        {
            rejectModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div onClick={()=>setRejectModal(false)} className='text-end w-full right-0 cursor-pointer text-black'>x</div>
                    <div className="mb-4 items-center flex flex-col">
                        <div className="font-semibold text-2xl">Reject</div>
                        <div className="mt-[15px] mb-[20px] font-normal"><span className="capitalize">{form.mandate_id}</span> has been rejected due to</div>
                        {(auth.member_id===form.member_id && form.status==='Pending for Approval') && <input maxLength={200} className="rounded mb-[17px] w-full h-[54px] border" type="text" value={rejectReason} onChange={(e)=>setRejectReason(e.target.value)} placeholder="Please add reason for rejection" />}
                        {
                            (auth.member_id===form.bank_id && form.status==='Pending for Acceptance') &&
                            <div className="border w-full rounded p-3 mb-4">
                                <select className="w-full capitalize" onChange={(e)=>setRejectReason(e.target.value)}>
                                    <option value="">Select Reason</option>
                                    {reasons.map(r=><option value={r.value}>{r.label}</option>)}
                                </select>
                            </div>
                        }
                        <button onClick={onReject} disabled={!rejectReason} className="disabled:opacity-[0.5] primary text-white px-4 py-2 rounded-md w-[136px] h-[54px] block">Reject</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            rejectSuccess && <Modal onClose={()=>setRejectSuccess(false)}>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4 text-center">Rejection Successful!</p>
                        <p className="text-xl2 mb-4 text-center">
                            You have successfully rejected a mandate.
                        </p>
                        <button onClick={()=>navigate(`/${page}?tab=${tab}`)} className="primary text-white px-4 py-2 rounded-md w-3/12 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            approvedModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4 text-center">Approval Successful!</p>
                        <p className="text-xl2 mb-4 text-center">You successfully approved a mandate.</p>
                        <button onClick={()=>navigate(`/${page}?tab=${tab}`)} className="primary text-white px-4 py-2 rounded-md w-3/12 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
    <div className="bg-white w-full mt-5 rounded py-10 flex flex-col items-center">
        <div className="w-[50%]">
            <table className="w-full border-collapse">
                <tr>
                    <td className="pb-5" colSpan={3}>
                        <div className="label text-xs">Mandate ID</div>
                        <span className="font-bold">{form.mandate_id}</span>
                    </td>
                </tr>
                <tr>
                    <td className="pb-5" width="33%">
                        <div className="label text-xs">Acquiring Bank Name</div>
                        <span className="font-bold">{member.bank_name}</span>
                    </td>
                    <td className="pb-5 whitespace-nowrap w-[33%]">
                        <div className="label text-xs">Acquiring Bank BIC</div>
                        <span className="font-bold">{member.bic}</span>
                    </td>
                    <td className="pb-5" width="33%">
                        <div className="label text-xs">Biller Name</div>
                        <span className="font-bold">{biller.biller_name}</span>
                    </td>
                </tr>
                <tr>
                    <td className="pb-5" width="33%">
                        <div className="label text-xs">Biller Code</div>
                        <span className="font-bold">{biller.biller_id}</span>
                    </td>
                    <td className="pb-5" width="33%">
                        <div className="label text-xs">TIN No.</div>
                        <span className="font-bold">{biller.biller_tin_number}</span>
                    </td>
                    <td className="pb-5 whitespace-nowrap w-[1%]">
                        <div className="label text-xs">Branch Code</div>
                        <span className="font-bold">{biller.branch_code}</span>
                    </td>
                </tr>
                <tr>
                    <td className="pb-5" width="33%">
                        <div className="label text-xs">Customer Name</div>
                        <span className="font-bold">{form.customer_name}</span>
                    </td>
                    <td className="pb-5 whitespace-nowrap w-[1%]">
                        <div className="label text-xs">Customer Reference ID</div>
                        <span className="font-bold">{form.customer_reference_id}</span>
                    </td>
                </tr>
                <tr>
                    <td className="pb-5">
                        <div className="label text-xs" width="33%">Customer Bank Account Number</div>
                        <span className="font-bold">{form.customer_bank_account_number}</span>
                    </td>
                    <td className="pb-5">
                        <div className="label text-xs" width="33%">Customer Bank</div>
                        <span className="font-bold">{issuingBank.bank_name}</span>
                    </td>
                </tr>
                <tr>
                    <td className="pb-5 whitespace-nowrap w-[1%]">
                        <div className="label text-xs">Approve Amount/Approve Amount Range</div>
                        <span className="font-bold">{numberWithCommas(form.amount)}</span>
                    </td>
                </tr>
                <tr>
                    <td className="pb-5">
                        <div className="label text-xs">Start Date</div>
                        <span className="font-bold">{formatDate(form.start_date)}</span>
                    </td>
                    <td className="pb-5" colSpan={2}>
                        <div className="label text-xs">End Date</div>
                        <span className="font-bold">{formatDate(form.end_date)}</span>
                    </td>
                </tr>
                {
                    hasAccess('mandate-management-roles', 'approver', auth.permissions) &&
                    <tr>
                        <td colSpan={3}>
                                    <button onClick={()=>onApprove(true)} className="px-4 py-2 w-full primary text-white rounded-md">Approve</button>
                                    <button onClick={()=>onApprove(false)} className="block mt-3 w-full text-center px-4 py-2 secondary text-white rounded-md">Reject</button>
                        </td>
                    </tr>
                }
            </table>
        </div>
    </div>
  </Layout>;
}