import IconPencil from "../assets/icon-pencil.png";
import IconExpand from "../assets/icon-expand.png";
import IconExpanded from "../assets/icon-expanded.png";
import IconChecked from "../assets/icon-checked.png";
import IconUnchecked from "../assets/icon-unchecked.png";
import { UserContext } from './UserContext';
import { useState, useContext, useEffect } from "react";
import { axios, hasAccess, permissionDifference } from "../utils";
import Modal from "./Modal";
import IconSuccess from "../assets/icon-success.png";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import ApprovalMatrix from "./ApprovalMatrix";
import IconDelete from "../assets/icon-delete.png";
import IconDeleteConfirm from "../assets/icon-delete-confirm.png";
import IconSuspend from "../assets/icon-suspend.png";
import IconCancel from "../assets/icon-cancel.png";
import IconCancelGreen from "../assets/icon-cancel-green.png";

let permissions = {
    'member-management': [
        {label: 'All Members', id:'member-all', permissions: []},
        {label: 'Member for Approval', id:'member-for-approval', permissions: []}
    ],
    'access-management': [
        {label: 'All Internal User', id:'access-all', permissions: []},
        {label: 'Internal User for Approval', id:'access-for-approval', permissions: []},
        {label: 'All Bank Admin', id:'access-bank-admins', permissions: []},
        {label: 'Bank Admin for Approval', id:'access-bank-admins-for-approval', permissions: []},
    ],
    'biller-management': [
        {label: 'All Billers', id:'biller-all', permissions: []},
        {label: 'Biller for Approval', id:'biller-for-approval', permissions: []}
    ],
    'mandate-management': [
        {label: 'All Mandates', id:'mandate-all', permissions: []},
        {label: 'View Mandate for Approval', id:'mandate-for-approval', permissions: []}
    ],
    'calendar-management': [
        {label: 'Calendar Management', id:'calendar-all', permissions: []},
        {label: 'Events for Approval', id:'calendar-for-approval', permissions: []},
    ],
    'collections': [
        {label: 'Net Settlement', id:'collections-net-settlement', permissions: []},
        {label: 'Settlement Reports', id:'collections-settlement-report', permissions: []}
    ],
    'collections-management': [
        {label: 'Acquirer', id:'collections-management-acquirer', permissions: []},
        {label: 'Issuer', id:'collections-management-issuer', permissions: []},
        //{label: 'Settings', id:'collections-management-settings', permissions: []}
    ],
    'reports': [
        {label: 'Reports', id:'reports-all', permissions: []},
    ],
    'audit-logs': [
        {label: 'Audit Logs', id:'audit-logs-all', permissions: []},
    ],
    'settings': [
        {label: 'Settings', id:'settings-all', permissions: []},
    ],
    'dd-portal-settings': [
        {label: 'Settings', id:'dd-portal-settings', permissions: []},
    ]
}

export default function PermissionForm ({setUser, setPage, isApprove, isEmployee, id}){

    const { user, updateUser } = useContext(UserContext);
    const {auth} = useContext(AuthContext);
    const [permission, setPermission] = useState(user.permissions || {});
    const [active, setActive] = useState('member-management');
    const [approvedModal, setApprovedModal] = useState();
    const [rejectModal, setRejectModal] = useState();
    const [rejectedModal, setRejectedModal] = useState();
    const [rejectReason, setRejectReason] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('type');
    const page = queryParams.get('page') || (isEmployee ? 'pending-employee' : 'pending-users');
    const [initial, setInitial] = useState({});
    const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [suspendConfirmModal, setSuspendConfirmModal] = useState(false);
    const [suspendSuccess, setSuspendSuccess] = useState(false);
    const [cancelConfirmModal, setCancelConfirmModal] = useState(false);
    const [cancelSuccess, setCancelSuccess] = useState(false);
    const [approveCancelConfirm, setApproveCancelConfirm] = useState(false);

    const onApproveCancel = async()=>{
        setApproveCancelConfirm(false);
        await axios.patch(`/users/${user.id}`, {status: 'Cancelled'}).then(()=>setCancelSuccess(true)).catch(e=>{
            setError(e.response?.data?.message || e.message);
        });
    }

    const onApprove = async(approved)=>{
        if(approved){
            if(user.status==='Pending Approval for Cancellation'){
                setApproveCancelConfirm(true);
                return;
            }
            if(isEmployee){
                await axios.patch(`/users/${id}`, {status: 'approved'})
            }else{
                await axios.patch(`/users/${user.id}`, {status: 'approved'})
            }
            setApprovedModal(true)
        }else{
            setRejectModal(true);
        }
    }

    const onReject = async()=>{
        const status = user.status==='Pending Approval for Cancellation' ? user.previous_status : 'Rejected';
        const reject_reason = rejectReason;
        if(isEmployee){
            await axios.patch(`/users/${id}`, {status, reject_reason})
        }else{
            await axios.patch(`/users/${user.id}`, {status, reject_reason})
        }
        setRejectedModal(true)
    }

    useEffect(()=>{
        if(auth.id){
            if(isEmployee && id){
                axios.get(`/users/${id}`).then(resp=>{
                    updateUser(resp.data)
                    const _perm = JSON.parse(resp.data.permissions)
                    setPermission(_perm);
                    setInitial(_perm);
                    setUser(resp.data)
                    setActive('biller-management');
                })
            }else if(isEmployee){
                setActive('biller-management');
            }
        }
    }, [auth])

    useEffect(()=>{
        if(user){
            setUser((prev)=>({...prev, ...user}))
        }
        if(isEmployee){
            permissions['access-management'] = [
                {label: 'All Employees', id:'access-all', permissions: []},
            ];
        }
    }, [user])

    useEffect(()=>{
        if(permission){
            updateUser((prev)=>({...prev, permissions: permission}));
        }
    }, [permission])
    
    const onChange = (e, id, action) => {
        if(isApprove) return;
        const {checked} = e.target;
        setPermission(prev=>({...prev, [id]:[...new Set(checked ? [...(prev[id] ?? []), action, ...['create', 'edit', 'approve', 'delete', 'extract', 'archive', 'cancel'].includes(action)?['view']:[]] : prev[id].filter(p=>![action, ...(prev[id].length <= 2 && ['create', 'edit', 'approve', 'delete', 'extract', 'archive', 'cancel'].includes(action)?['view']:[])].includes(p)))]}));
    }

    const onDelete = async()=>{
        setDeleteConfirmModal(false);
        setError('')
        await axios.patch(`/users/${id}`, {status: 'Pending Approval for Deletion', previous_status: user.status}).then(()=>setDeleteSuccess(true)).catch(e=>{
            setError(e.response?.data?.message || e.message);
        });
    }

    const onSuspend = async()=>{
        setSuspendConfirmModal(false);
        setError('')
        await axios.patch(`/users/${user.id}`, {status: 'suspended'}).then(()=>setSuspendSuccess(true)).catch(e=>{
            setError(e.response?.data?.message || e.message);
        });
    }

    const onCancel = async()=>{
        setCancelConfirmModal(false);
        setError('')
        await axios.patch(`/users/${user.id}`, {status: 'Pending Approval for Cancellation', previous_status: user.status}).then(()=>setCancelSuccess(true)).catch(e=>{
            setError(e.response?.data?.message || e.message);
        });
    }

    return <>
        {
            cancelSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-semibold text-2xl mb-4">Cancellation Request Submitted</p>
                        <span className="mb-6">Your request to cancel has been successfully submitted and is now pending approval.</span>
                        <button onClick={()=>navigate(`/access-management?type=${type}`)} className="primary text-white px-4 py-2 rounded-md w-1/2 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            approveCancelConfirm && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <p className="font-semibold text-2xl mb-4">Cancel</p>
                        <span className="mb-6">Are you sure you want to cancel?</span>
                        <p className="font-semibold text-2xl mb-4">{user.firstName} {user.lastName}</p>
                        <div className="flex gap-3">
                            <button onClick={onApproveCancel} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Yes, Cancel</button>
                            <button onClick={()=>setApproveCancelConfirm(false)} className="primary text-white px-4 py-2 rounded-md w-1/2 block">No</button>
                        </div>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            cancelConfirmModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <p className="font-semibold text-2xl mb-4">Cancel</p>
                        <span className="mb-6">Are you sure you want to cancel?</span>
                        <p className="font-semibold text-2xl mb-4">{user.firstName} {user.lastName}</p>
                        <div className="flex gap-3">
                            <button onClick={onCancel} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Yes, Cancel</button>
                            <button onClick={()=>setCancelConfirmModal(false)} className="primary text-white px-4 py-2 rounded-md w-1/2 block">No</button>
                        </div>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            suspendSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconDeleteConfirm} alt="Success" className="mb-4"/>
                        <p className="font-semibold text-2xl mb-4">Suspend Success</p>
                        <span className="mb-6">You successfully suspended a user.</span>
                        <button onClick={()=>navigate('/access-management?type=internal')} className="primary text-white px-4 py-2 rounded-md w-1/2 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            suspendConfirmModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconDeleteConfirm} alt="Success" className="mb-4"/>
                        <p className="font-semibold text-2xl mb-4">Suspend</p>
                        <span className="mb-6">Are you sure you want to suspend?</span>
                        <div className="flex gap-3">
                            <button onClick={()=>setSuspendConfirmModal(false)} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Cancel</button>
                            <button onClick={onSuspend} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Suspend</button>
                        </div>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            deleteSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconDeleteConfirm} alt="Success" className="mb-4"/>
                        <p className="font-semibold text-2xl mb-4">Delete Success</p>
                        <span className="mb-6">You successfully deleted a user.</span>
                        <button onClick={()=>navigate('/access-management?type=internal')} className="primary text-white px-4 py-2 rounded-md w-1/2 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            deleteConfirmModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconDeleteConfirm} alt="Success" className="mb-4"/>
                        <p className="font-semibold text-2xl mb-4">Delete</p>
                        <span className="mb-6">Are you sure you want to delete?</span>
                        <div className="flex gap-3">
                            <button onClick={()=>setDeleteConfirmModal(false)} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Cancel</button>
                            <button onClick={onDelete} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Delete</button>
                        </div>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            rejectModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div onClick={()=>setRejectModal(false)} className='text-end w-full right-0 cursor-pointer text-black'>x</div>
                    <div className="mb-4 items-center flex flex-col">
                        <div className="font-semibold text-2xl">Reject</div>
                        <div className="mt-[15px] mb-[20px] font-normal"><span className="capitalize">{user.firstName}</span> has been rejected due to</div>
                        <input maxLength={200} className="rounded mb-[17px] w-full h-[54px] border" type="text" value={rejectReason} onChange={(e)=>setRejectReason(e.target.value)} placeholder="Please add reason for rejection" />
                        <button onClick={onReject} disabled={!rejectReason} className="disabled:opacity-[0.5] primary text-white px-4 py-2 rounded-md w-[136px] h-[54px] block">Reject</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            rejectedModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4 text-center">Rejection Successful!</p>
                        <p className="text-xl2 mb-4 text-center">You have successfully rejected {isEmployee ? `an employee` : 'a user'}</p>
                        <button onClick={()=>navigate(`/access-management?type=${type}`)} className="primary text-white px-4 py-2 rounded-md w-3/12 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            approvedModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4 text-center">Approval Successful!</p>
                        <p className="text-xl2 mb-4 text-center">You successfully approved {isEmployee ? 'an employee' : 'a user'}.</p>
                        <button onClick={()=>navigate(`/${page}?type=${type}`)} className="primary text-white px-4 py-2 rounded-md w-3/12 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
    <div className={`permissions ${(user.status==='Pending Approval for Cancellation' || user.status==='Cancelled') ? 'pointer-events-none': ''}`}>
        {error && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{error}</span>}
        <div className="flex justify-between">
            <div>
                <div className="flex justify-start">
                    <span className="text-xl2 font-semibold capitalize">{user.firstName} {user.lastName}</span>
                    {(auth.id===user.created_by && !isApprove && hasAccess('access-all', 'edit', auth.permissions)) && <div onClick={()=>setPage('user-form')} className="flex cursor-pointer">
                        <img src={IconPencil} alt="Edit" className="cursor-pointer flex justify-between ml-2 mr-1 h-[10px] mt-[8px]" />
                        <span className="text-primary">edit</span>
                    </div>}
                </div>
                <span className="text-gray-500">{user.email}</span>
            </div>
            {
                user.reject_reason && <div className="flex flex-col">
                    <span>Previous Reason for Rejection:</span>
                    <span className="capitalize">{user.reject_reason}</span>
                </div>
            }
        </div>
        <hr className="my-3"/>
        {(!isEmployee && !user.member_id) && <div onClick={()=>setActive('member-management')} className={`${(user.id || isApprove) && 'pointer-events-auto'} ${active === 'member-management' ? 'expanded text-primary': 'text-white' } primary  rounded p-2 mb-1 cursor-pointer flex justify-between`}>
            Member Management
            <img src={active === 'member-management'?IconExpanded : IconExpand} alt="Expand" className="cursor-pointer flex justify-between ml-2" />
        </div>}
        {
            ( !user.member_id && active === 'member-management') && <>
                {user.member_id && <ApprovalMatrix {...{user, setPermission, active, permissions: permission, isEmployee, isApprove}} />}
                <div className="flex justify-between px-3 border-b pb-1 mb-2 font-semibold w-full">
                    <div className="w-[195px]">Permissions</div>
                    <div className="flex-grow">Create</div>
                    <div className="flex-grow">Edit</div>
                    <div className="flex-grow">View</div>
                    <div className="flex-grow">Approve</div>
                    <div className="flex-grow">Delete</div>
                    <div className="flex-grow">Cancel</div>
                    <div className="flex-grow">Extract</div>
                    <div className="flex-grow">Archive</div>
                </div>
                {
                    permissions[active].map(p => 
                        <div className={`py-1 px-3 flex justify-between w-full`} key={p.label}>
                            <span className="w-[195px]">{p.label}</span>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('create') && <img alt={`${p.id}-create`} onClick={()=>onChange({target:{checked:false}}, p.id, 'create')} src={IconChecked} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('create') && <img alt={`${p.id}-create`} onClick={()=>onChange({target:{checked:true}}, p.id, 'create')} src={IconUnchecked} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('edit') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'edit')} src={IconChecked} alt={`${p.id}-edit`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('edit') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'edit')} src={IconUnchecked} alt={`${p.id}-edit`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') || permission[p.id]?.some(p=>['create', 'edit', 'approve', 'delete', 'extract', 'archive', 'cancel'].includes(p)) && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('view') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'view')} src={IconChecked} alt={`${p.id}-view`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('view') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'view')} src={IconUnchecked} alt={`${p.id}-view`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('approve') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'approve')} src={IconChecked} alt={`${p.id}-approve`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('approve') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'approve')} src={IconUnchecked} alt={`${p.id}-approve`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('delete') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'delete')} src={IconChecked} alt={`${p.id}-delete`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('delete') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'delete')} src={IconUnchecked} alt={`${p.id}-delete`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('cancel') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'cancel')} src={IconChecked} alt={`${p.id}-cancel`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('cancel') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'cancel')} src={IconUnchecked} alt={`${p.id}-cancel`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('extract') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'extract')} src={IconChecked} alt={`${p.id}-extract`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('extract') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'extract')} src={IconUnchecked} alt={`${p.id}-extract`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('archive') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'archive')} src={IconChecked} alt={`${p.id}-archive`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('archive') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'archive')} src={IconUnchecked} alt={`${p.id}-archive`} className="cursor-pointer" />}
                            </div>
                        </div>
                    )
                }
            </>
        }
        {!auth.member_id && <div onClick={()=>setActive('access-management')} className={`${(user.id || isApprove) && 'pointer-events-auto'} ${active === 'access-management' ? 'expanded text-primary': 'text-white' } primary  rounded p-2 mb-1 mt-3 cursor-pointer flex justify-between`}>
            Access Management
            <img src={active === 'access-management'?IconExpanded : IconExpand} alt="Expand" className="cursor-pointer flex justify-between ml-2" />
        </div>}
        {
            ( active === 'access-management') && <>
                {user.member_id && <ApprovalMatrix {...{user, setPermission, active, permissions: permission, isEmployee, isApprove}} />}
                <div className="flex justify-between px-3 mb-2 border-b pb-2 font-semibold w-full">
                    <div className="w-[195px]">Permissions</div>
                    <div className="flex-grow">Create</div>
                    <div className="flex-grow">Edit</div>
                    <div className="flex-grow">View</div>
                    <div className="flex-grow">Approve</div>
                    <div className="flex-grow">Delete</div>
                    <div className="flex-grow">Cancel</div>
                    <div className="flex-grow">Extract</div>
                    <div className="flex-grow">Archive</div>
                </div>
                {
                    permissions[active].map(p => 
                        <div className={`py-1 px-3 flex justify-between border-b pb-1 odd:bg-gray-100 w-full`} key={p.label}>
                            <span className="w-[195px]">{p.label}</span>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('create') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'create')} src={IconChecked} alt={`${p.id}-create`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('create') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'create')} src={IconUnchecked} alt={`${p.id}-create`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('edit') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'edit')} src={IconChecked} alt={`${p.id}-edit`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('edit') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'edit')} src={IconUnchecked} alt={`${p.id}-edit`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') || permission[p.id]?.some(p=>['create', 'edit', 'approve', 'delete', 'extract', 'archive', 'cancel'].includes(p)) && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('view') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'view')} src={IconChecked} alt={`${p.id}-view`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('view') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'view')} src={IconUnchecked} alt={`${p.id}-view`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('approve') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'approve')} src={IconChecked} alt={`${p.id}-approve`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('approve') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'approve')} src={IconUnchecked} alt={`${p.id}-approve`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('delete') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'delete')} src={IconChecked} alt={`${p.id}-delete`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('delete') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'delete')} src={IconUnchecked} alt={`${p.id}-delete`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('cancel') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'cancel')} src={IconChecked} alt={`${p.id}-cancel`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('cancel') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'cancel')} src={IconUnchecked} alt={`${p.id}-cancel`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('extract') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'extract')} src={IconChecked} alt={`${p.id}-extract`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('extract') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'extract')} src={IconUnchecked} alt={`${p.id}-extract`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('archive') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'archive')} src={IconChecked} alt={`${p.id}-archive`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('archive') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'archive')} src={IconUnchecked} alt={`${p.id}-archive`} className="cursor-pointer" />}
                            </div>
                        </div>
                    )
                }
            </>
        }
        <div onClick={()=>setActive('biller-management')} className={`${(user.id || isApprove) && 'pointer-events-auto'} ${active === 'biller-management' ? 'expanded text-primary': 'text-white' } primary  rounded p-2 mb-1 mt-3 cursor-pointer flex justify-between`}>
            Biller Management
            <img src={active === 'biller-management'?IconExpanded : IconExpand} alt="Expand" className="cursor-pointer flex justify-between ml-2" />
        </div>
        {
            active === 'biller-management' && <>
                {auth.member_id && <ApprovalMatrix {...{user, setPermission, active, permissions: permission, isEmployee, isApprove}} />}
                <div className="flex justify-between px-3 border-b pb-2 font-semibold w-full">
                    <div className="w-[195px]">Permissions</div>
                    {auth.member_id && <div className="flex-grow">Create</div>}
                    {auth.member_id && <div className="flex-grow">Edit</div>}
                    <div className="flex-grow">View</div>
                    <div className="flex-grow">Approve</div>
                    <div className="flex-grow">Delete</div>
                    <div className="flex-grow">Cancel</div>
                    <div className="flex-grow">Extract</div>
                    <div className="flex-grow">Archive</div>
                </div>
                {
                    permissions[active].map(p => 
                        <div className={`py-1 px-3 flex justify-between border-b pb-1 even:bg-gray-100 w-full`} key={p.label}>
                            <span className="w-[195px]">{p.label}</span>
                            {auth.member_id && <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('create') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'create')} src={IconChecked} alt={`${p.id}-create`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('create') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'create')} src={IconUnchecked} alt={`${p.id}-create`} className="cursor-pointer" />}
                            </div>}
                            {auth.member_id && <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('edit') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'edit')} src={IconChecked} alt={`${p.id}-edit`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('edit') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'edit')} src={IconUnchecked} alt={`${p.id}-edit`} className="cursor-pointer" />}
                            </div>}
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') || permission[p.id]?.some(p=>['create', 'edit', 'approve', 'delete', 'extract', 'archive', 'cancel'].includes(p)) && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('view') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'view')} src={IconChecked} alt={`${p.id}-view`} className="cursor-pointer pl-[3px]" />}
                                {!permission[p.id]?.includes('view') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'view')} src={IconUnchecked} alt={`${p.id}-view`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('approve') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'approve')} src={IconChecked} alt={`${p.id}-approve`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('approve') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'approve')} src={IconUnchecked} alt={`${p.id}-approve`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('delete') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'delete')} src={IconChecked} alt={`${p.id}-delete`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('delete') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'delete')} src={IconUnchecked} alt={`${p.id}-delete`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('cancel') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'cancel')} src={IconChecked} alt={`${p.id}-cancel`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('cancel') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'cancel')} src={IconUnchecked} alt={`${p.id}-cancel`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('extract') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'extract')} src={IconChecked} alt={`${p.id}-extract`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('extract') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'extract')} src={IconUnchecked} alt={`${p.id}-extract`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('archive') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'archive')} src={IconChecked} alt={`${p.id}-archive`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('archive') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'archive')} src={IconUnchecked} alt={`${p.id}-archive`} className="cursor-pointer" />}
                            </div>
                        </div>
                    )
                }
            </>
        }
        <div onClick={()=>setActive('mandate-management')} className={`${(user.id || isApprove) && 'pointer-events-auto'} ${active === 'mandate-management' ? 'expanded text-primary': 'text-white' } primary  rounded p-2 mb-1 mt-3 cursor-pointer flex justify-between`}>
            Mandate Management
            <img src={active === 'mandate-management'?IconExpanded : IconExpand} alt="Expand" className="cursor-pointer flex justify-between ml-2" />
        </div>
        {
            active === 'mandate-management' && <>
                {auth.member_id && <ApprovalMatrix {...{user, setPermission, active, permissions: permission, isEmployee, isApprove}} />}
                <div className="flex justify-between px-3 border-b pb-2 font-semibold w-full">
                    <div className="w-[195px]">Permissions</div>
                    {auth.member_id && <div className="flex-grow">Create</div>}
                    {auth.member_id && <div className="flex-grow">Edit</div>}
                    <div className="flex-grow">View</div>
                    <div className="flex-grow">Delete</div>
                    <div className="flex-grow">Extract</div>
                    <div className="flex-grow">Archive</div>
                </div>
                {
                    permissions[active].map(p => 
                        <div className={`py-1 px-3 flex justify-between border-b pb-1 odd:bg-gray-100 w-full`} key={p.label}>
                            <span className="w-[195px]">{p.label}</span>
                            {auth.member_id && <div className={`flex-grow`}>
                                {permission[p.id]?.includes('create') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'create')} src={IconChecked} alt={`${p.id}-create`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('create') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'create')} src={IconUnchecked} alt={`${p.id}-create`} className="cursor-pointer" />}
                            </div>}
                            {auth.member_id && <div className={`flex-grow`}>
                                {permission[p.id]?.includes('edit') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'edit')} src={IconChecked} alt={`${p.id}-edit`} className="cursor-pointer pl-[3px]" />}
                                {!permission[p.id]?.includes('edit') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'edit')} src={IconUnchecked} alt={`${p.id}-edit`} className="cursor-pointer" />}
                            </div>}
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') || permission[p.id]?.some(p=>['create', 'edit', 'approve', 'delete', 'extract', 'archive', 'cancel'].includes(p)) && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('view') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'view')} src={IconChecked} alt={`${p.id}-view`} className="cursor-pointer pl-[3px]" />}
                                {!permission[p.id]?.includes('view') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'view')} src={IconUnchecked} alt={`${p.id}-view`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('delete') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'delete')} src={IconChecked} alt={`${p.id}-delete`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('delete') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'delete')} src={IconUnchecked} alt={`${p.id}-delete`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('extract') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'extract')} src={IconChecked} alt={`${p.id}-extract`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('extract') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'extract')} src={IconUnchecked} alt={`${p.id}-extract`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('archive') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'archive')} src={IconChecked} alt={`${p.id}-archive`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('archive') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'archive')} src={IconUnchecked} alt={`${p.id}-archive`} className="cursor-pointer" />}
                            </div>
                        </div>
                    )
                }
            </>
        }
        {!isEmployee && <div onClick={()=>setActive('collections')} className={`${(user.id || isApprove) && 'pointer-events-auto'} ${active === 'collections' ? 'expanded text-primary': 'text-white' } primary  rounded p-2 mb-3 mt-3 cursor-pointer flex justify-between`}>
            Presentment and Collection Management
            <img src={active === 'collections'?IconExpanded : IconExpand} alt="Expand" className="cursor-pointer flex justify-between ml-2" />
        </div>}
        {
            active === 'collections' && <>
                <div className="flex justify-between px-3 border-b pb-2 font-semibold w-full">
                    <div className="w-[195px]">Permissions</div>
                    <div className="flex-grow">View</div>
                    <div className="flex-grow">Extract</div>
                    <div className="flex-grow">Archive</div>
                </div>
                {
                    permissions[active].map(p => 
                        <div className={`py-1 px-3 flex justify-between border-b pb-1 even:bg-gray-100 w-full`} key={p.label}>
                            <span className="w-[195px]">{p.label}</span>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('view') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'view')} src={IconChecked} alt={`${p.id}-view`} className="cursor-pointer pl-[3px]" />}
                                {!permission[p.id]?.includes('view') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'view')} src={IconUnchecked} alt={`${p.id}-view`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('extract') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'extract')} src={IconChecked} alt={`${p.id}-extract`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('extract') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'extract')} src={IconUnchecked} alt={`${p.id}-extract`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('archive') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'archive')} src={IconChecked} alt={`${p.id}-archive`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('archive') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'archive')} src={IconUnchecked} alt={`${p.id}-archive`} className="cursor-pointer" />}
                            </div>
                        </div>
                    )
                }
            </>
        }
        {isEmployee && <div onClick={()=>setActive('collections-management')} className={`${(user.id || isApprove) && 'pointer-events-auto'} ${active === 'collections-management' ? 'expanded text-primary': 'text-white' } primary  rounded p-2 mb-3 mt-3 cursor-pointer flex justify-between`}>
            Collection Management
            <img src={active === 'collections-management'?IconExpanded : IconExpand} alt="Expand" className="cursor-pointer flex justify-between ml-2" />
        </div>}
        {
            active === 'collections-management' && <>
                <div className="flex justify-between px-3 border-b pb-2 font-semibold w-full">
                    <div className="w-[195px]">Permissions</div>
                    <div className="flex-grow">Create</div>
                    <div className="flex-grow">View</div>
                    <div className="flex-grow">Delete</div>
                    <div className="flex-grow">Extract</div>
                    <div className="flex-grow">Archive</div>
                </div>
                {
                    permissions[active].map(p => 
                        <div className={`py-1 px-3 flex justify-between w-full`} key={p.label}>
                            <span className="w-[195px]">{p.label}</span>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('create') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'create')} src={IconChecked} alt={`${p.id}-create`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('create') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'create')} src={IconUnchecked} alt={`${p.id}-create`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') || permission[p.id]?.some(p=>['create', 'edit', 'approve', 'delete', 'extract', 'archive', 'cancel'].includes(p)) && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('view') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'view')} src={IconChecked} alt={`${p.id}-view`} className="cursor-pointer pl-[3px]" />}
                                {!permission[p.id]?.includes('view') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'view')} src={IconUnchecked} alt={`${p.id}-view`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('delete') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'delete')} src={IconChecked} alt={`${p.id}-delete`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('delete') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'delete')} src={IconUnchecked} alt={`${p.id}-delete`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('extract') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'extract')} src={IconChecked} alt={`${p.id}-extract`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('extract') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'extract')} src={IconUnchecked} alt={`${p.id}-extract`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('archive') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'archive')} src={IconChecked} alt={`${p.id}-archive`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('archive') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'archive')} src={IconUnchecked} alt={`${p.id}-archive`} className="cursor-pointer" />}
                            </div>
                        </div>
                    )
                }
            </>
        }
        {auth.member_id && <div onClick={()=>setActive('reports')} className={`${(user.id || isApprove) && 'pointer-events-auto'} ${active === 'reports' ? 'expanded text-primary': 'text-white' } primary  rounded p-2 mb-3 cursor-pointer flex justify-between`}>
            Reports
            <img src={active === 'reports'?IconExpanded : IconExpand} alt="Expand" className="cursor-pointer flex justify-between ml-2" />
        </div>}
        {
            active === 'reports' && <>
                <div className="flex justify-between px-3 border-b pb-2 font-semibold w-full">
                    <div className="w-[195px]">Permissions</div>
                    <div className="flex-grow">Create</div>
                    <div className="flex-grow">Edit</div>
                    <div className="flex-grow">View</div>
                    <div className="flex-grow">Delete</div>
                    <div className="flex-grow">Extract</div>
                    <div className="flex-grow">Archive</div>
                </div>
                {
                    permissions[active].map(p => 
                        <div className={`py-1 px-3 flex justify-between w-full`} key={p.label}>
                            <span className="w-[195px]">{p.label}</span>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('create') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'create')} src={IconChecked} alt={`${p.id}-create`} className="cursor-pointer pl-[3px]" />}
                                {!permission[p.id]?.includes('create') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'create')} src={IconUnchecked} alt={`${p.id}-create`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('edit') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'edit')} src={IconChecked} alt={`${p.id}-edit`} className="cursor-pointer pl-[3px]" />}
                                {!permission[p.id]?.includes('edit') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'edit')} src={IconUnchecked} alt={`${p.id}-edit`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') || permission[p.id]?.some(p=>['create', 'edit', 'approve', 'delete', 'extract', 'archive', 'cancel'].includes(p)) && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('view') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'view')} src={IconChecked} alt={`${p.id}-view`} className="cursor-pointer pl-[3px]" />}
                                {!permission[p.id]?.includes('view') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'view')} src={IconUnchecked} alt={`${p.id}-view`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('delete') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'delete')} src={IconChecked} alt={`${p.id}-delete`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('delete') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'delete')} src={IconUnchecked} alt={`${p.id}-delete`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('extract') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'extract')} src={IconChecked} alt={`${p.id}-extract`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('extract') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'extract')} src={IconUnchecked} alt={`${p.id}-extract`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('archive') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'archive')} src={IconChecked} alt={`${p.id}-archive`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('archive') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'archive')} src={IconUnchecked} alt={`${p.id}-archive`} className="cursor-pointer" />}
                            </div>
                        </div>
                    )
                }
            </>
        }
        <div onClick={()=>setActive('audit-logs')} className={`${(user.id || isApprove) && 'pointer-events-auto'} ${active === 'audit-logs' ? 'expanded text-primary': 'text-white' } primary  rounded p-2 mb-3 cursor-pointer flex justify-between`}>
            Audit Logs Report
            <img src={active === 'audit-logs'?IconExpanded : IconExpand} alt="Expand" className="cursor-pointer flex justify-between ml-2" />
        </div>
        {
            active === 'audit-logs' && <>
                <div className="flex justify-between px-3 border-b pb-2 font-semibold w-full">
                    <div className="w-[195px]">Permissions</div>
                    <div className="flex-grow">Create</div>
                    <div className="flex-grow">Edit</div>
                    <div className="flex-grow">View</div>
                    {auth.member_id && <div className="flex-grow">Delete</div>}
                    <div className="flex-grow">Extract</div>
                    <div className="flex-grow">Archive</div>
                </div>
                {
                    permissions[active].map(p => 
                        <div className={`py-1 px-3 flex justify-between w-full`} key={p.label}>
                            <span className="w-[195px]">{p.label}</span>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('create') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'create')} src={IconChecked} alt={`${p.id}-create`} className="cursor-pointer pl-[3px]" />}
                                {!permission[p.id]?.includes('create') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'create')} src={IconUnchecked} alt={`${p.id}-create`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('edit') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'edit')} src={IconChecked} alt={`${p.id}-edit`} className="cursor-pointer pl-[3px]" />}
                                {!permission[p.id]?.includes('edit') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'edit')} src={IconUnchecked} alt={`${p.id}-edit`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') || permission[p.id]?.some(p=>['create', 'edit', 'approve', 'delete', 'extract', 'archive', 'cancel'].includes(p)) && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('view') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'view')} src={IconChecked} alt={`${p.id}-view`} className="cursor-pointer pl-[3px]" />}
                                {!permission[p.id]?.includes('view') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'view')} src={IconUnchecked} alt={`${p.id}-view`} className="cursor-pointer" />}
                            </div>
                            {auth.member_id && <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('delete') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'delete')} src={IconChecked} alt={`${p.id}-delete`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('delete') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'delete')} src={IconUnchecked} alt={`${p.id}-delete`} className="cursor-pointer" />}
                            </div>}
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('extract') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'extract')} src={IconChecked} alt={`${p.id}-extract`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('extract') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'extract')} src={IconUnchecked} alt={`${p.id}-extract`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('archive') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'archive')} src={IconChecked} alt={`${p.id}-archive`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('archive') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'archive')} src={IconUnchecked} alt={`${p.id}-archive`} className="cursor-pointer" />}
                            </div>
                        </div>
                    )
                }
            </>
        }
        {!isEmployee && <div onClick={()=>setActive('calendar-management')} className={`${(user.id || isApprove) && 'pointer-events-auto'} ${active === 'calendar-management' ? 'expanded text-primary': 'text-white' } primary  rounded p-2 mb-1 cursor-pointer flex justify-between`}>
            Calendar Management
            <img src={active === 'calendar-management'?IconExpanded : IconExpand} alt="Expand" className="cursor-pointer flex justify-between ml-2" />
        </div>}
        {
            active === 'calendar-management' && <>
                <div className="flex justify-between px-3 border-b pb-2 font-semibold w-full">
                    <div className="w-[195px]">Permissions</div>
                    <div className="flex-grow">Create</div>
                    <div className="flex-grow">Edit</div>
                    <div className="flex-grow">View</div>
                    <div className="flex-grow">Approve</div>
                    <div className="flex-grow">Delete</div>
                    <div className="flex-grow">Cancel</div>
                    <div className="flex-grow">Archive</div>
                </div>
                {
                    permissions[active].map(p => 
                        <div className={`py-1 px-3 flex justify-between w-full`} key={p.label}>
                            <span className="w-[195px]">{p.label}</span>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('create') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'create')} src={IconChecked} alt={`${p.id}-create`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('create') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'create')} src={IconUnchecked} alt={`${p.id}-create`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('edit') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'edit')} src={IconChecked} alt={`${p.id}-edit`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('edit') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'edit')} src={IconUnchecked} alt={`${p.id}-edit`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') || permission[p.id]?.some(p=>['create', 'edit', 'approve', 'delete', 'extract', 'archive', 'cancel'].includes(p)) && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('view') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'view')} src={IconChecked} alt={`${p.id}-view`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('view') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'view')} src={IconUnchecked} alt={`${p.id}-view`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('approve') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'approve')} src={IconChecked} alt={`${p.id}-approve`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('approve') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'approve')} src={IconUnchecked} alt={`${p.id}-approve`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('delete') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'delete')} src={IconChecked} alt={`${p.id}-delete`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('delete') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'delete')} src={IconUnchecked} alt={`${p.id}-delete`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('cancel') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'cancel')} src={IconChecked} alt={`${p.id}-cancel`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('cancel') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'cancel')} src={IconUnchecked} alt={`${p.id}-cancel`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('archive') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'archive')} src={IconChecked} alt={`${p.id}-archive`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('archive') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'archive')} src={IconUnchecked} alt={`${p.id}-archive`} className="cursor-pointer" />}
                            </div>
                        </div>
                    )
                }
            </>
        }
        {auth.role==='admin' && <div onClick={()=>setActive('settings')} className={`${(user.id || isApprove) && 'pointer-events-auto'} ${active === 'settings' ? 'expanded text-primary': 'text-white' } primary  rounded p-2 mb-1 mt-3 cursor-pointer flex justify-between`}>
            Settings
            <img src={active === 'settings'?IconExpanded : IconExpand} alt="Expand" className="cursor-pointer flex justify-between ml-2" />
        </div>}
        {
            active === 'settings' && <>
                <div className="flex justify-between px-3 border-b pb-2 font-semibold w-full">
                    <div className="w-[195px]">Permissions</div>
                    <div className="flex-grow">Create</div>
                    <div className="flex-grow">Edit</div>
                    <div className="flex-grow">View</div>
                    <div className="flex-grow">Delete</div>
                    <div className="flex-grow">Extract</div>
                    <div className="flex-grow">Archive</div>
                </div>
                {
                    permissions[active].map(p => 
                        <div className={`py-1 px-3 flex justify-between w-full`} key={p.label}>
                            <span className="w-[195px]">{p.label}</span>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('create') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'create')} src={IconChecked} alt={`${p.id}-create`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('create') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'create')} src={IconUnchecked} alt={`${p.id}-create`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('edit') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'edit')} src={IconChecked} alt={`${p.id}-edit`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('edit') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'edit')} src={IconUnchecked} alt={`${p.id}-edit`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') || permission[p.id]?.some(p=>['create', 'edit', 'approve', 'delete', 'extract', 'archive', 'cancel'].includes(p)) && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('view') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'view')} src={IconChecked} alt={`${p.id}-view`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('view') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'view')} src={IconUnchecked} alt={`${p.id}-view`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('delete') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'delete')} src={IconChecked} alt={`${p.id}-delete`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('delete') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'delete')} src={IconUnchecked} alt={`${p.id}-delete`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('extract') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'extract')} src={IconChecked} alt={`${p.id}-extract`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('extract') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'extract')} src={IconUnchecked} alt={`${p.id}-extract`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('archive') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'archive')} src={IconChecked} alt={`${p.id}-archive`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('archive') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'archive')} src={IconUnchecked} alt={`${p.id}-archive`} className="cursor-pointer" />}
                            </div>
                        </div>
                    )
                }
            </>
        }
        {!auth.member_id && <div onClick={()=>setActive('dd-portal-settings')} className={`${(user.id || isApprove) && 'pointer-events-auto'} ${active === 'dd-portal-settings' ? 'expanded text-primary': 'text-white' } primary  rounded p-2 mb-1 mt-3 cursor-pointer flex justify-between`}>
            DD Portal Settings
            <img src={active === 'dd-portal-settings'?IconExpanded : IconExpand} alt="Expand" className="cursor-pointer flex justify-between ml-2" />
        </div>}
        {
            active === 'dd-portal-settings' && <>
                <div className="flex justify-between px-3 border-b pb-2 font-semibold w-full">
                    <div className="w-[195px]">Permissions</div>
                    <div className="flex-grow">Create</div>
                    <div className="flex-grow">Edit</div>
                    <div className="flex-grow">View</div>
                    <div className="flex-grow">Approve</div>
                    <div className="flex-grow">Delete</div>
                    <div className="flex-grow">Extract</div>
                    <div className="flex-grow">Archive</div>
                </div>
                {
                    permissions[active].map(p => 
                        <div className={`py-1 px-3 flex justify-between w-full`} key={p.label}>
                            <span className="w-[195px]">{p.label}</span>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('create') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'create')} src={IconChecked} alt={`${p.id}-create`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('create') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'create')} src={IconUnchecked} alt={`${p.id}-create`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('edit') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'edit')} src={IconChecked} alt={`${p.id}-edit`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('edit') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'edit')} src={IconUnchecked} alt={`${p.id}-edit`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') || permission[p.id]?.some(p=>['create', 'edit', 'approve', 'delete', 'extract', 'archive', 'cancel'].includes(p)) && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('view') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'view')} src={IconChecked} alt={`${p.id}-view`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('view') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'view')} src={IconUnchecked} alt={`${p.id}-view`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('approve') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'approve')} src={IconChecked} alt={`${p.id}-approve`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('approve') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'approve')} src={IconUnchecked} alt={`${p.id}-approve`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('delete') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'delete')} src={IconChecked} alt={`${p.id}-delete`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('delete') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'delete')} src={IconUnchecked} alt={`${p.id}-delete`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('extract') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'extract')} src={IconChecked} alt={`${p.id}-extract`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('extract') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'extract')} src={IconUnchecked} alt={`${p.id}-extract`} className="cursor-pointer" />}
                            </div>
                            <div className={`flex-grow ${permission[p.id]?.includes('preselected') && 'pointer-events-none'}`}>
                                {permission[p.id]?.includes('archive') && <img onClick={()=>onChange({target:{checked:false}}, p.id, 'archive')} src={IconChecked} alt={`${p.id}-archive`} className="cursor-pointer" />}
                                {!permission[p.id]?.includes('archive') && <img onClick={()=>onChange({target:{checked:true}}, p.id, 'archive')} src={IconUnchecked} alt={`${p.id}-archive`} className="cursor-pointer" />}
                            </div>
                        </div>
                    )
                }
            </>
        }
        <div className={`flex mt-4 gap-[30px] ${isApprove ? 'justify-center pointer-events-auto': 'justify-end'}`}>
        {
            (isApprove && auth.id!==user.created_by ) && 
            <div className="w-1/2 flex mb-4 flex-col">
                <button onClick={()=>onApprove(true)} className="px-4 py-2 w-full primary text-white rounded-md">Approve</button>
                <button onClick={()=>onApprove(false)} className="block mt-3 w-full text-center px-4 py-2 secondary text-white rounded-md">Reject</button>
            </div>
        }
        {
            (!isApprove && user.status!=='Cancelled' && user.status!=='Pending Approval for Cancellation' && hasAccess('access-all', 'cancel', auth.permissions) && user.id) && <div onClick={()=>setCancelConfirmModal(true)} className={`mb-4 justify-center flex text-red-500 gap-2 cursor-pointer ${auth.id===user.id && 'pointer-events-none opacity-50'}`}>
                <img src={IconCancel} alt="Cancel" className="cursor-pointer" />
                Cancel
            </div>
        }
        {
            (false && !isApprove && hasAccess('access-all', 'delete', auth.permissions) && user.id) && <div onClick={()=>setSuspendConfirmModal(true)} className={`mb-4 justify-center flex text-red-500 gap-2 cursor-pointer ${auth.id===user.id && 'pointer-events-none opacity-50'}`}>
                <img src={IconSuspend} alt="Suspend" className="cursor-pointer" />
                Suspend
            </div>
        }
        {
            (user.status!=='DELETED' && user.status!=='Pending Approval for Cancellation' && !isApprove && hasAccess('access-all', 'delete', auth.permissions) && user.id && !auth.member_id) && <div onClick={()=>setDeleteConfirmModal(true)} className={`mb-4 justify-center flex text-red-500 gap-2 cursor-pointer ${auth.id===user.id && 'pointer-events-none opacity-50'}`}>
                <img src={IconDelete} alt="Delete" className="cursor-pointer" />
                Delete
            </div>
        }
        </div>
    </div>
    </>

}