import {useState, useEffect, useContext} from "react";
import Layout from "../layouts/Dashboard";
import IconCalendar from "../assets/icon-calendar.png";
import IconSuccess from "../assets/icon-success.png";
import Modal from "./Modal";
import { axios, formatDateWithTime } from "../utils";
import { AuthContext } from "../AuthContext";
import IconSearch from "../assets/icon-search.png";

export default function Logs(){

    const {auth} = useContext(AuthContext);
    const [exportSuccess, setExportSuccess] = useState(false);
    const [rows, setRows] = useState([]);
    const [error, setError] = useState();

    useEffect(()=>{
        const sort = JSON.stringify([{orderBy: "createdAt", order: 'desc'}])
        const param = auth.member_id ? '&member_id='+auth.member_id : '';
        axios.get(`/logs?limit=10000${param}&sort=${encodeURIComponent(sort)}`).then(resp=>{
            setRows(resp.data.data.filter(r=>r.url.match(/users|member|mandates|billers|reports|collections|calendar/)));
        });
    }, []);

    const onClickExport = ()=>{
        axios.post(`/logs/util/export`).then(_=>{
            setExportSuccess(true);
        })
        .catch(e=>setError(e.response?.data?.message || e.message))
    }

    const getPage = (url)=>{
        switch(true){
            case url?.includes('/api/v1/users'):
                return 'Access Management';
            case  url?.includes('/api/v1/mandates'):
                return 'Mandates Management';
            case  url?.includes('/api/v1/member'):
                return 'Members Management';
            case url?.includes('/api/v1/billers'):
                return 'Billers Management';
            case url?.includes('/api/v1/reports'):
                return 'Presentment and Collection';
            case url?.includes('/api/v1/collections'):
                return 'Collections';
            case url?.includes('/api/v1/calendar'):
                return 'Calendar';
            default:
                return '-';
        }
    }

    return <Layout>
        <div className="flex justify-between items-center">
            <p className="text-2xl font-bold">Logs</p>
            <div className="flex items-center space-x-6">
                <img className="cursor-pointer" src={IconSearch}/>
                <button onClick={onClickExport} className="px-4 py-2 primary font-bold text-base leading-[18.75px] text-white rounded-md">Export</button>
            </div>
        </div>
        {
            exportSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4 text-center">Your requested file has been<br/>sent to your email</p>
                        <button onClick={()=>setExportSuccess(false)} className="primary text-white px-4 py-2 rounded-md w-3/12 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        <div className="w-full mt-5 bg-white rounded p-3">
            <div className="overflow-x-auto mb-3">
                {error && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{error}</span>}
                <table className="table-auto border-collapse w-full">
                    <thead>
                    <tr>
                        <th className="py-2 text-left" width="16%">Accessed Time</th>
                        <th className="py-2 text-left" width="25%">Accessed By</th>
                        <th className="py-2 text-left" width="5%">Action</th>
                        <th className="py-2 text-left" width="16%">Performed On</th>
                        <th className="py-2 text-left" width="10%">IP Address</th>
                        <th className="py-2 text-left" width="25%">User Agent</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        rows.map(r=><tr className="border-b border-gray-200">
                            <td className="py-2">{formatDateWithTime(r.createdAt)}</td>
                            <td className="py-2">{r.data.request.email}</td>
                            <td className="py-2">{r.data.request.action}</td>
                            <td className="py-2">{getPage(r.url)}</td>
                            <td className="py-2">{r.data.request.ipAddress}</td>
                            <td className="py-2">{r.data.request.userAgent}</td>
                        </tr>)
                    }
                    </tbody>
                </table>
            </div>
        </div>
    </Layout>
}