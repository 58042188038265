import Layout from "../layouts/Dashboard";
import {Link, useNavigate, useLocation} from "react-router-dom";
import { useEffect, useState } from "react";
import { axios, sleep } from "../utils";
import Modal from "./Modal";
import IconSuccess from "../assets/icon-success.png";

export default function ApproveAdmin() {

    const navigate = useNavigate();
    const [form, setForm] = useState({notifications: [], types: [], admins: []});
    const [approvedModal, setApprovedModal] = useState();
    const [rejectModal, setRejectModal] = useState();
    const [rejectReason, setRejectReason] = useState('');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const back = queryParams.get('back') || 'pending-users';

    const onApprove = async(approved)=>{
        if(approved){
            const status = 'Pending Approval for Cancellation' ? 'Cancelled' : 'approved';
            await axios.patch(`/users/${id}`, {status})
            setApprovedModal(true)
        }else{
            setRejectModal(true);
        }
    }

    const onReject = async()=>{
        const status = form.status === 'Pending Approval for Cancellation' ? form.previous_status : 'Rejected';
        await axios.patch(`/users/${id}`, {status, reject_reason: rejectReason})
        navigate('/pending-users?type=bank')
    }
    
    useEffect(()=>{
        axios.get(`/users/${id}`).then((resp)=>{
            const {firstName, lastName, email, member_id, status} = resp.data;
            axios.get(`/member/${member_id}`).then(resp=>{
                let data = {
                    firstName,
                    lastName,
                    email,
                    member_id,
                    ...resp.data,
                    bank_name: resp.data.bank_name,
                    memberStatus: resp.data.status,
                    notifications: JSON.parse(resp.data.notifications).join(', '),
                    type: resp.data.type ? JSON.parse(resp.data.type).join(', ') : '',
                    status
                }
                setForm(data);
            });
        })
    }, []);

  return <Layout>
    <div className="flex justify-between items-center">
        <p className="text-2xl font-semibold">View and Approve</p>
        <div className="flex items-center space-x-4">
            <Link to={`/${back}?type=bank`} className="px-4 py-2 secondary text-white rounded-md">Back</Link>
        </div>
    </div>
        {
            rejectModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    <div className="bg-white px-4 py-2 rounded-lg shadow-md">
                        <div onClick={()=>setRejectModal(false)} className='text-end w-full right-0 cursor-pointer text-black'>x</div>
                        <div className="mb-4 items-center flex flex-col">
                            <div className="font-semibold text-2xl">Reject</div>
                            <div className="mt-[15px] mb-[20px]">{form.firstName} {form.lastName} has been rejected due to</div>
                            <input className="rounded mb-[15px] w-[451px] border" type="text" value={rejectReason} onChange={(e)=>setRejectReason(e.target.value)} placeholder="Please add reason for rejection" />
                            <button onClick={onReject} disabled={!rejectReason} className="disabled:opacity-[0.5] primary text-white px-4 py-2 rounded-md w-[136px] h-[54px] block">Reject</button>
                        </div>
                    </div>
                </div>
            </Modal>
        }
        {
            approvedModal && <Modal onClose={()=>setApprovedModal(false)}>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 pb-2 rounded-lg shadow-md">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <div className="font-semibold text-2xl mb-[20px]">Approval Successful!</div>
                        <div className="text-xl2 mb-6 text-center">
                            You have successfully approved {form.firstName} {form.lastName}<br/>
                            to access the smart debit portal.
                        </div>
                        <button onClick={()=>navigate(`/${back}?type=bank`)} className="primary text-white px-4 py-2 rounded-md w-[97px] h-[54px] block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
    <div className="bg-white w-full mt-5 rounded p-10 flex flex-col items-center">
        <div className="w-8/12 text-xl mb-4">Designated Admin</div>
        <div className="w-8/12 flex justify-between">
            <div className="w-1/3">
                <div className="label text-xs">Name of Admin</div>
                <span className="font-medium text-base capitalize">
                    {form.firstName} {form.lastName}
                </span>
                
            </div>
            <div className="w-1/3">
                <div className="label text-xs">Email Address</div>
                <span className="font-medium text-base">{form.email}</span>
            </div>
            <div className="w-1/3"></div>
        </div>
        <div className="w-8/12 border-b-2  mb-[31px]">&nbsp;</div>
        <div className="w-8/12 flex justify-between mb-[31px] capitalize">
            <div className="w-2/3">
                <div className="label text-xs">Bank Name</div>
                <span className="font-medium text-base">
                    {form.bank_name}
                    <div>{form.type}</div>
                </span>
                
            </div>
            <div className="w-1/3">
                <div className="label text-xs">BIC / Swift / Pesonet Identifiers</div>
                <span className="font-medium text-base">{form.bic}</span>
            </div>
        </div>
        <div className="w-8/12 flex justify-between mb-[31px]">
            <div className="w-full">
                <div className="label text-xs">Bank Address</div>
                <span className="font-medium text-base">{form.bank_address}</span>
            </div>
        </div>
        <div className="w-8/12 flex justify-between mb-[31px]">
            <div className="w-1/3">
                <div className="label text-xs">Liaison Name</div>
                <span className="font-medium text-base">{form.liason_name}</span>
            </div>
            <div className="w-1/3">
                <div className="label text-xs">Mobile or Landline Number</div>
                <span className="font-medium text-base">{form.liason_contact}</span>
            </div>
            <div className="w-1/3">
                <div className="label text-xs">Email Address</div>
                <span className="font-medium text-base">{form.liason_email}</span>
            </div>
        </div>
        <div className="w-8/12 flex justify-between mb-[31px]">
            <div className="w-1/3">
                <div className="label text-xs">Liaison Name</div>
                <span className="font-medium text-base">{form.liason2_name || '-'}</span>
            </div>
            <div className="w-1/3">
                <div className="label text-xs">Mobile or Landline Number</div>
                <span className="font-medium text-base">{form.liason2_contact || '-'}</span>
            </div>
            <div className="w-1/3">
                <div className="label text-xs">Email Address</div>
                <span className="font-medium text-base">{form.liason2_email || '-'}</span>
            </div>
        </div>
        <div className="w-8/12 flex justify-between mb-[31px]">
            <div className="w-1/3">
                <div className="label text-xs">Notifications</div>
                <span className="font-medium text-base">{form.notifications}</span>
            </div>
        </div>
        <div className="w-8/12 flex justify-between mb-4 flex-col">
            <button onClick={()=>onApprove(true)} className="px-4 py-2 w-full h-[54px] primary text-white rounded-md">Approve</button>
            <button onClick={()=>onApprove(false)} className="block mt-[15px] w-full h-[54px] text-center px-4 py-2 secondary text-white rounded-md">Reject</button>
        </div>
    </div>
  </Layout>;
}