import Layout from "../layouts/Login";
import Logo from "../assets/logo-header-lg.png";
import IconUser from "../assets/icon-user.png";
import IconPassword from "../assets/icon-lock.png";
import {Link, useNavigate, useLocation} from "react-router-dom";
import {useState, useContext, useEffect} from "react";
import {axios} from "../utils";
import Cookies from 'js-cookie';
import { AuthContext } from "../AuthContext";

export default function Login(){

    const [form, setForm] = useState({});
    const [error, setError] = useState();
    const navigate = useNavigate();
    const {auth, updateAuth} = useContext(AuthContext);


    const onChange = (e) => {
        const {name, value} = e.target;
        setForm({...form, [name]: value});
    }

    useEffect(()=>{
        axios.get('/auth/me').then(resp=>{
            navigate('/member-management')
        }).catch(err=>console.error(err));
    }, [])

    const checkAkamai = async () => {
        try{

            setError('');
            var req = new XMLHttpRequest();
            req.open("GET", document.location, false);
            req.send(null);
            var headers = req.getAllResponseHeaders().toLowerCase();
            headers = headers.split(/\n|\r|\r\n/g).reduce(function(a, b) {
            if (b.length) {
                var [key, value] = b.split(": ");
                a[key] = value;
            }
            return a;
            }, {});
            const resp = await axios.get('auth/akamai/login', { 'headers':{'eaauser': headers['x-custom-header']} });
            updateAuth({...resp.data.user});
            const expirationDate = new Date();
            expirationDate.setTime(expirationDate.getTime() + 3600 * 1000); // 1 hour in milliseconds
            Cookies.set('auth_token', resp.data.token, { expires: expirationDate });
            if(resp.data.user.member_id){
                navigate('/access-management');
            }else{
                navigate('/member-management');
            }
        }catch(err){
            console.error(err.response?.data.errors ? Object.values(err.response?.data.errors)[0] : err.message);
        }
    }

    useEffect(()=>{
        //checkAkamai();
    }, [])

    const onClick = async (e) => {
        e.preventDefault();
        try{
            setError('');
            const resp = await axios.post('auth/email/login', form);
            updateAuth({...resp.data.user});
            const expirationDate = new Date();
            expirationDate.setTime(expirationDate.getTime() + 3600 * 1000); // 1 hour in milliseconds
            Cookies.set('auth_token', resp.data.token, { expires: expirationDate });
            navigate('/landing');
        }catch(err){
            setError(err.response?.data.errors ? Object.values(err.response?.data.errors)[0] : err.message);
        }
    }

    return <Layout>
        <div className="flex justify-center flex-col items-center pt-10">
            <div className="text-center mt-10 mb-4">
                <img src={Logo} alt="Logo" className="mx-auto h-16 mb-10" />
                <h1 className="text-2xl font-bold text-gray-800 mb-4">Direct Debit Portal</h1>
            </div>
            <div className="max-w-md w-full p-6 bg-white rounded-lg shadow-lg border-top-2 border-top">
                <h2 className="text-center text-2xl font-bold drop-shadow-lg mb-5">LOGIN</h2>
                <form>
                    {error && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{error}</span>}
                    <div className="mb-4">
                        <div className="flex items-center border border-gray-300 rounded-md px-3 py-2">
                            <img src={IconUser} alt="Username"/>
                            <div className="h-full border-l border-gray-300 mx-2">&nbsp;</div>
                            <input onChange={onChange} name="email" type="text" placeholder="Username" className="outline-none focus:outline-none flex-grow"/>
                        </div>
                    </div>
                    <div className="mb-6">
                        <div className="flex items-center border border-gray-300 rounded-md px-3 py-2">
                            <img src={IconPassword} alt="Password"/>
                            <div className="h-full border-l border-gray-300 mx-2">&nbsp;</div>
                            <input onChange={onChange} name="password" type="password" placeholder="Password" className="outline-none focus:outline-none flex-grow"/>
                        </div>
                    </div>
                    <div className="text-center text-primary mb-6">
                        <Link to="#" className="text-primary ">Forgot Password</Link>
                    </div>
                    <div className="text-center">
                        <button onClick={onClick} className="primary text-white px-4 py-2 rounded-md w-full block">Login</button>
                    </div>
                </form>
            </div>
        </div>
    </Layout>
}