import IconChecked from "../assets/icon-checked.png";
import IconUnchecked from "../assets/icon-unchecked.png";
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../AuthContext";

let initial = {
    'member-management-roles': {
        'maker': {permissions:{
            'member-all':['create', 'edit', 'view'],
        }},
        'approver': {permissions:{
            'member-all':['preselected']
        }},
        'both': {permissions:{
            'member-all':['create', 'edit', 'view'], 
        }},
        'empty': {permissions:{
            'member-all':[], 
        }}
    },
    'access-management-roles': {
        'maker': {
            permissions:{
                'access-all':['create', 'edit', 'view'],
                'access-bank-admins':['create', 'edit', 'view'],
            }
        },
        'approver': {
            permissions: {
                'access-all':['preselected'],
                'access-bank-admins':['preselected'],
            }
        },
        'both': {
            permissions: {
                'access-all':['create', 'edit', 'view'],
                'access-bank-admins':['create', 'edit', 'view'],
            }
        },
        'empty': {permissions:{
            'access-all':[],
            'access-bank-admins':[],
        }}
    },
    'biller-management-roles': {
        'maker': {
            permissions:{
                'biller-all':['view', 'extract'],
            }
        },
        'approver': {
            permissions:{
                'biller-all':['preselected'],
            }
        },
        'both': {
            permissions:{
                'biller-all':['view', 'extract'],
            }
        },
        'empty': {permissions:{
            'biller-all':[],
        }}
    },
    'mandate-management-roles': {
        'maker': {
            permissions:{
                'mandate-all':['view', 'extract'],
                'mandate-pending-for-approval': []
            }
        },
        'approver': {
            permissions:{
                'mandate-all':[],
                'mandate-pending-for-approval': ['view', 'extract']
            }
        },
        'both': {
            permissions:{
                'mandate-all':['view', 'extract'],
                'mandate-pending-for-approval': ['view', 'extract']
            }
        },
        'empty': {permissions:{
            'mandate-all':[],
            'mandate-pending-for-approval': []
        }}
    },
    'calendar-management-roles': {
        'maker': {
            permissions:{
                'calendar-all':['create', 'edit', 'view'],
            }
        },
        'approver': {
            permissions:{
                'calendar-all':['preselected'],
            }
        },
        'both': {
            permissions:{
                'calendar-all':['create', 'edit', 'view'],
            }
        },
        'empty': {permissions:{
            'calendar-all':[],
        }}
    },
    'collections-roles': {
        'maker': {
            permissions:{
                'collections-net-settlement':['create', 'edit', 'view'],
                'collections-settlement-report':['create', 'edit', 'view'],
            }
        },
        'approver': {
            permissions:{
                'collections-net-settlement':[],
                'collections-settlement-report':[],
            }
        },
        'empty': {permissions:{
            'collections-net-settlement':[],
            'collections-settlement-report':[],
        }}
    },
    'collections-management-roles': {
        'maker': {
            permissions:{
                'collections-management-acquirer':['create', 'edit', 'view'],
                'collections-management-issuer':['create', 'edit', 'view'],
            }
        },
        'approver': {
            permissions:{
                'collections-management-acquirer':['create', 'edit', 'view'],
                'collections-management-issuer':['create', 'edit', 'view'],
            }
        },
        'empty': {permissions:{
            'collections-management-acquirer':[],
            'collections-management-issuer':[],
        }}
    },
    'audit-logs-roles': {
        'maker': {
            permissions:{
                'audit-logs-all':['delete', 'extract', 'view'],
            }
        },
        'approver': {
            permissions:{
                'audit-logs-all':['view', 'extract'],
            }
        },
        'empty': {permissions:{
            'audit-logs-all':[],
        }}
    },
    'settings-roles': {
        'maker': {
            permissions:{
                'settings-all':['create', 'edit', 'view'],
            }
        },
        'approver': {
            permissions:{
                'settings-all':['create', 'edit', 'view'],
            }
        },
        'empty': {permissions:{
            'settings-all':[],
        }}
    }
}

export default function ApprovalMatrix({user, setPermission, active, permissions, isEmployee, isApprove}){

    const [roles, setRoles] = useState(permissions[`${active}-roles`]);
    const {auth} = useContext(AuthContext);

    if(isEmployee){

        initial['access-management-roles'] = {
            'maker': {
                permissions:{
                    'access-all':['create', 'edit', 'view'],
                }
            },
            'approver': {
                permissions: {
                    'access-all':[],//'preselected'
                }
            },
            'both': {
                permissions: {
                    'access-all':['create', 'edit', 'view'],
                }
            },
            'empty': {
                permissions: {
                    'access-all':[],
                }
            }
        }
        initial['biller-management-roles'] = {
            'maker': {
                permissions:{
                    'biller-all':['create', 'edit', 'view'],
                    'biller-for-approval':[],
                }
            },
            'approver': {
                permissions:{
                    'biller-for-approval':['create', 'edit', 'view'],
                    'biller-all':[],
                }
            },
            'both': {
                permissions:{
                    'biller-all':['create', 'edit', 'view'],
                    'biller-for-approval':['create', 'edit', 'view'],
                }
            },
            'empty': {
                permissions:{
                    'biller-all':[],
                    'biller-for-approval':[],
                }
            },
        }
        initial['mandate-management-roles'] = {
            'maker': {
                permissions:{
                    'mandate-all':['create', 'edit', 'view'],
                    'mandate-for-approval':[],//'preselected'
                }
            },
            'approver': {
                permissions:{
                    'mandate-all':[],//'preselected'
                    'mandate-for-approval':['create', 'edit', 'view']
                }
            },
            'both': {
                permissions:{
                    'mandate-all':['create', 'edit', 'view'],
                    'mandate-for-approval':['create', 'edit', 'view']
                }
            },
            'empty': {
                permissions:{
                    'mandate-all':[],
                    'mandate-for-approval':[]
                }
            },
        }
    }

    useEffect(()=>{
        if(roles?.manual){
            setPermission((prev)=>{
                let newPermission = {...prev};
                if(roles.maker && roles.approver && initial[`${active}-roles`]['both']){
                    newPermission = {...newPermission, ...initial[`${active}-roles`]['both'].permissions};
                }else if(roles.maker){
                    newPermission = {...newPermission, ...initial[`${active}-roles`]['maker'].permissions};
                }else if(roles.approver){
                    newPermission = {...newPermission, ...initial[`${active}-roles`]['approver'].permissions};
                }else if(!roles.maker && !roles.approver){
                    newPermission = {...newPermission, ...initial[`${active}-roles`]['empty'].permissions};
                }
                return {...newPermission, [`${active}-roles`]:{...roles, manual: null}}
            })
            setRoles(prev=>({...prev, manual: null}))
        }
    }, [roles])

    return <div className={`px-3 mt-3 w-full mb-4 ${isApprove && 'pointer-events-none'}`}>
        <span className="font-bold">Select Role for {user.firstName}</span>
        <div className="flex">
            <div className="flex w-1/6 pt-3 gap-2">
                <div className="flex gap-3">
                    <img onClick={()=>setRoles(prev=>({...prev, maker: !prev?.maker, manual: true}))} src={roles?.maker ? IconChecked: IconUnchecked} alt="Checked" className="cursor-pointer" />
                    <span>Maker</span>
                </div>
                <div className="flex gap-3">
                <img onClick={()=>setRoles(prev=>({...prev, approver: !prev?.approver, manual: true}))} src={roles?.approver ? IconChecked: IconUnchecked} alt="Checked" className="cursor-pointer" />
                    <span>Approver</span>
                </div>
            </div>
        </div>
    </div>
}