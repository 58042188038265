import {useState, useContext, useEffect} from "react";
import Layout from "../layouts/Dashboard";
import { Link, useNavigate, useLocation } from "react-router-dom";
import IconSuccess from "../assets/icon-success.png";
import Modal from "./Modal";
import { AuthContext } from "../AuthContext";
import { axios, hasAccess, objectDiff } from "../utils";
import IconActive from "../assets/icon-active.png";
import IconActiveOff from "../assets/icon-active-off.png";
import IconDelete from "../assets/icon-delete.png";
import IconDeleteConfirm from "../assets/icon-delete-confirm.png";
import IconClock from "../assets/icon-clock.png";
import IconCancel from "../assets/icon-cancel.png";
import IconCancelGreen from "../assets/icon-cancel-green.png";
import IconSuspend from "../assets/icon-suspend.png";

export default function AddBiller(){
    
    const {auth} = useContext(AuthContext);
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const [form, setForm] = useState({
        'biller_name': '',
        'biller_tin_number': '',
        'branch_code': '',
    });
    const [error, setError] = useState(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const [errors, setErrors] = useState({});
    const [enroll, setEnroll] = useState(false);
    const [members, setMembers] = useState([]);
    const [member, setMember] = useState({});
    const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [lastBiller, setLastBiller] = useState({});
    const [isEnrolled, setIsEnrolled] = useState(false);
    const [initial, setInitial] = useState({});
    const [cancelConfirmModal, setCancelConfirmModal] = useState(false);
    const [cancelSuccess, setCancelSuccess] = useState(false);
    const [suspendConfirmModal, setSuspendConfirmModal] = useState(false);
    const [suspendSuccess, setSuspendSuccess] = useState(false);

    const onEnroll = async()=>{
        axios.post(`/billers/${id}/enroll`, {member_id: auth.member_id}).then(()=>setSuccessMessage('Enrolled successfully')).catch(e=>{
            setError(e.response?.data?.message || e.message);
        })
    }

    const onCancel = async()=>{
        setCancelConfirmModal(false);
        setError('')
        await axios.patch(`/billers/${id}`, {status: 'Pending Approval for Cancellation'}).then(()=>setCancelSuccess(true)).catch(e=>{
            setError(e.response?.data?.message || e.message);
        });
    }

    const onDelete = async()=>{
        setDeleteConfirmModal(false);
        setError('')
        await axios.patch(`/billers/${id}`, {status: 'Pending Approval for Deletion'}).then(()=>setDeleteSuccess(true)).catch(e=>{
            setError(e.response?.data?.message || e.message);
        });
    }

    const onSuspend = async()=>{
        setSuspendConfirmModal(false);
        setError('')
        await axios.patch(`/billers/${user.id}`, {status: 'Suspended'}).then(()=>setSuspendSuccess(true)).catch(e=>{
            setError(e.response?.data?.message || e.message);
        });
    }

    const onChange = (e)=>{
        let {name, value} = e.target;
        if(name === 'biller_tin_number'){
            setForm({...form, [name]: value.replace(/\D/g, '')});
            return;
        }
        else if(name === 'branch_code'){
            setForm({...form, [name]: value.slice(0, 3)});
            return;
        }
        else if(name === 'member_id'){
            setMember(members.find(m=>m.id === value));
            setForm({...form, [name]: value});
            return;
        }
        setForm({...form, [name]: value});
        setErrors({...errors, [name]: ''})
    }
    useEffect(()=>{
        if(auth.id){
            if(id){
                axios.get(`/billers/${id}`).then(resp=>{
                    setForm(resp.data);
                    setInitial(resp.data);
                    if(resp.data.member_id === auth.member_id){
                        setForm(prev=>({...prev, member_id: auth.member_id}))  
                    }
                }).catch(e=>{
                    setError(e.response?.data?.message || e.message);
                })
                axios.get('/member?limit=100&filters=approved|active')
                    .then((resp)=>{
                        setMembers([resp.data.data.find(m=>m.id === auth.member_id)])
    
                        setMember(resp.data.data.find(m=>m.id === auth.member_id));
                    })
                if(auth.member_id){
                    axios.get(`/member/${auth.member_id}/billers`)
                    .then(resp=>{
                        const e = resp.data.find(b=>b.biller_id === id);
                        if(e){
                            setIsEnrolled(e);
                            setEnroll(e);
                            const m = members.find(m=>m.id === e.member_id);
                            setMember(m);
                        }
                    })
                }
            }else{
                axios.get('/billers/util/last-id').then(resp=>{
                    setLastBiller(resp.data);
                });
            }
        }
    }, [auth])

    const onSubmit = async ()=>{
        setError('');
        setErrors({});

        if(!(/^\d{2,3}$/).test(form.branch_code)){
            setErrors({...errors, branch_code: 'Branch code must be 2-3 digits'});
            return;
        }

        try{
            const status = auth.member_id ? 'Pending for Approval' : 'approved';
            if(id) await axios.patch(`/billers/${id}`, {...objectDiff(initial, form), status: 'Pending for Approval'});
            else {
                let biller_id = lastBiller.biller_id || '000001';
                let num = parseInt(biller_id, 10);
                num += 1;
                biller_id = num.toString().padStart(biller_id.length, '0');
                const resp = await axios.post('/billers', {
                    ...form,
                    status,
                    biller_id
                });
                await axios.post(`/billers/${resp.data.id}/enroll`, {member_id: auth.member_id})
            }
            setSuccess(true);
        }catch(e){
            setError(e.response?.data?.errors ? Object.values(e.response.data.errors)[0] : e.message);
        }
    }

    return <Layout>
        <div className="flex justify-between items-center">
            <p className="text-2xl font-bold">{id ? 'Edit' : 'Add'} Biller</p>
            <div className="flex items-center space-x-4">
                {id && <Link to={`/biller-history?id=${id}&name=${form.biller_name}`} className="primary text-white px-4 py-2 rounded-md flex justify-center gap-2"><img src={IconClock} width={22} alt="View History Icon"/> <span className="pt-[1px]">View History</span></Link>}
                <Link to="/biller-management" className="px-4 py-2 secondary text-white rounded-md">Back</Link>
            </div>
        </div>
        {
            suspendSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconDeleteConfirm} alt="Success" className="mb-4"/>
                        <p className="font-semibold text-2xl mb-4">Suspend Success</p>
                        <span className="mb-6">You successfully suspended a user.</span>
                        <button onClick={()=>navigate('/biller-management')} className="primary text-white px-4 py-2 rounded-md w-1/2 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            suspendConfirmModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconDeleteConfirm} alt="Success" className="mb-4"/>
                        <p className="font-semibold text-2xl mb-4">Suspend</p>
                        <span className="mb-6">Are you sure you want to suspend?</span>
                        <div className="flex gap-3">
                            <button onClick={()=>setSuspendConfirmModal(false)} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Cancel</button>
                            <button onClick={onSuspend} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Suspend</button>
                        </div>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            cancelSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconDeleteConfirm} alt="Success" className="mb-4"/>
                        <p className="font-semibold text-2xl mb-4">Cancel Success</p>
                        <span className="mb-6">You successfully cancelled a biller.</span>
                        <button onClick={()=>navigate('/biller-management')} className="primary text-white px-4 py-2 rounded-md w-1/2 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            cancelConfirmModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconCancelGreen} alt="Success" className="mb-4"/>
                        <p className="font-semibold text-2xl mb-4">Cancel</p>
                        <span className="mb-6">Are you sure you want to cancel?</span>
                        <div className="flex gap-3">
                            <button onClick={()=>setCancelConfirmModal(false)} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Cancel</button>
                            <button onClick={onCancel} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            success && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4">Biller {id ? 'Updated':'Added'}!</p>
                        <span className="mb-6">You successfully {id ? 'updated':'added a'} biller.</span>
                        <button onClick={()=>navigate('/biller-management')} className="primary text-white px-4 py-2 rounded-md w-[97px] h-[54px] block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            deleteSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconDeleteConfirm} alt="Success" className="mb-4"/>
                        <p className="font-semibold text-2xl mb-4">Delete Success</p>
                        <span className="mb-6">You successfully deleted a biller.</span>
                        <button onClick={()=>navigate('/biller-management')} className="primary text-white px-4 py-2 rounded-md w-1/2 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            deleteConfirmModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconDeleteConfirm} alt="Success" className="mb-4"/>
                        <p className="font-semibold text-2xl mb-4">Delete</p>
                        <span className="mb-6">Are you sure you want to delete?</span>
                        <div className="flex gap-3">
                            <button onClick={()=>setDeleteConfirmModal(false)} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Cancel</button>
                            <button onClick={onDelete} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Delete</button>
                        </div>
                    </div>
                </div>
            </div>
            </Modal>
        }
        <div className="w-full bg-white border rounded p-3 mt-5 pb-6">
            {successMessage && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{successMessage}</span>}
            {error && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{error}</span>}
            <div className="flex flex-col items-center p-3">
                {
                    form.status==='Rejected' && <>
                        <div className="flex items-start flex-col mb-4 w-3/6">
                            <div className="font-bold">Status: <span className="text-red-500">Rejected</span></div>
                            <div className="font-bold">Specify: <span className="text-red-500">{form.reject_reason}</span></div>
                        </div>
                    </>
                }
                <div className="w-3/6">
                    <div class="relative input-container">
                        <input 
                            type="text" 
                            id="biller_name" 
                            name="biller_name" 
                            value={form.biller_name}
                            readOnly={auth.member_id !== form.member_id && id}
                            onChange={onChange}
                            className={`pt-4 w-full pl-[10px] py-2 border border-gray-300 rounded-md ${form.biller_name?'border-black': ''}`}
                        />
                        <label 
                            for="biller_name" 
                            className={`pl-1 ${form.biller_name?'active':''}`}
                        >
                            Biller Name
                        </label>
                    </div>
                </div>
                <div className="w-3/6">
                    <div class="relative input-container">
                        <input 
                            type="text" 
                            id="biller_tin_number" 
                            name="biller_tin_number" 
                            value={form.biller_tin_number}
                            readOnly={auth.member_id !== form.member_id && id}
                            onChange={onChange}
                            className={`pt-4 w-full pl-[10px] py-2 border border-gray-300 rounded-md ${form.biller_tin_number?'border-black': ''}`}
                        />
                        <label 
                            for="biller_tin" 
                            className={`pl-0 ${form.biller_tin_number?'active':''}`}
                        >
                            Biller TIN Number
                        </label>
                    </div>
                </div>
                <div className="mb-4 w-3/6">
                    <div class="relative input-container !mb-0">
                        <input 
                            type="number"
                            maxLength={3} 
                            id="branch_code" 
                            name="branch_code" 
                            value={form.branch_code}
                            readOnly={auth.member_id !== form.member_id && id}
                            onChange={onChange}
                            className={`pt-4 w-full py-2 border pl-[10px] border-gray-300 rounded-md ${form.branch_code?'border-black': ''}`}
                        />
                        <label 
                            for="branch_code" 
                            className={`pl-1 ${form.branch_code?'active':''}`}
                        >
                            Branch Code
                        </label>
                    </div>
                    {errors.branch_code && <span className="block text-red-500 font-semibold text-xs italic">{errors.branch_code}</span>}
                </div>
                {
                    (form.id && form.member_id!==auth.member_id && (form.status!=='Pending for Approval' && form.status!=='Rejected') && auth.role==='employee') && <div className={`w-3/6 mb-4 flex gap-2 ${isEnrolled && 'pointer-events-none disabled'}`}>
                        {!enroll && <img onClick={()=>setEnroll(prev=>!prev)} src={IconActiveOff} alt="Enroll On" className="cursor-pointer" />}
                        {enroll && <img onClick={()=>setEnroll(prev=>!prev)} src={IconActive} alt="Enroll On" className="cursor-pointer" />}
                        <span>Enroll as Acquiring Bank</span>
                    </div>
                }
                {
                    (enroll && form.member_id!==auth.member_id) && <>
                        <div className={`w-3/6 mb-4 ${(form.created_by && auth.id === form.created_by) && 'pointer-events-none disabled'}`}>
                            <select
                                    id="dropdown"
                                    name="member_id"
                                    placeholder="Bank Name"
                                    value={member?.id}
                                    onChange={onChange}
                                    disabled
                                    className="w-full px-3 py-3 border font-medium text-base rounded-lg text-gray-500"
                                >
                                {members.map(m=><option value={m.id}>{m.bank_name}</option>)}
                            </select>
                            {errors.member_id && <span className="block text-red-500 font-semibold text-xs italic">{errors.member_id}</span>}
                        </div>
                        <div className={`w-3/6 ${(form.created_by && auth.id === form.created_by) && 'pointer-events-none disabled'}`}>
                            <div class="relative input-container">
                                <input 
                                    type="text" 
                                    id="bic" 
                                    name="bic" 
                                    value={member?.bic}
                                    onChange={onChange}
                                    className={`pt-4 w-full pl-[10px] py-2 border border-gray-300 rounded-md ${member?.bic?'border-black': ''}`}
                                />
                                <label 
                                    for="bic" 
                                    className={` ${member?.bic?'active left-[0px] absolute':'pl-1'}`}
                                >
                                    BIC / Swift / Pesonet Identifiers
                                </label>
                            </div>
                        </div>
                    </>
                }
                {
                    (id && form.member_id!==auth.member_id && auth.id !== form.created_by) && <div className="mb-4 w-3/6 text-center">
                        <button disabled={!form.member_id || !enroll || isEnrolled} onClick={onEnroll} className={`primary text-white px-4 py-2 rounded-md w-full block ${isEnrolled && 'disabled'}`}>Enroll</button>
                    </div>
                }
                {
                    ((id && form.member_id===auth.member_id && hasAccess('biller-all', 'edit', auth.permissions)) || ((!id && hasAccess('biller-all', 'create', auth.permissions)))) &&
                    <div className="mb-4 w-3/6 text-center">
                        <button disabled={(`${form.branch_code}`.length<2) || Object.keys(form).filter(k=>['biller_tin_number', 'biller_name', 'branch_code'].includes(k)).some(k=>!form[k]) || (form.status && !['approved', 'Rejected'].includes(form.status))} onClick={onSubmit} className={`primary text-white px-4 py-2 rounded-md w-full block`}>{id ? 'Save': 'Add Biller'}</button>
                    </div>
                }
                <div className=" w-5/12 flex justify-between">
                    {
                        (form.status!=='Cancelled' && hasAccess('biller-all', 'cancel', auth.permissions) && id && auth.member_id && auth.member_id === form.member_id) && <div onClick={()=>setCancelConfirmModal(true)} className="justify-center flex text-red-500 gap-2 cursor-pointer">
                            <img src={IconCancel} alt="Cancel" className="cursor-pointer" />
                            Cancel
                        </div>
                    }
                    {
                        (false && hasAccess('biller-all', 'delete', auth.permissions) && id && auth.member_id === form.member_id) && <div onClick={()=>setSuspendConfirmModal(true)} className={`justify-center flex text-red-500 gap-2 cursor-pointer`}>
                            <img src={IconSuspend} alt="Suspend" className="cursor-pointer" />
                            Suspend
                        </div>
                    }
                    {
                        (hasAccess('biller-all', 'delete', auth.permissions) && id && !auth.member_id) && <div onClick={()=>setDeleteConfirmModal(true)} className={`justify-center flex text-red-500 gap-2 cursor-pointer`}>
                            <img src={IconDelete} alt="Suspend" className="cursor-pointer" />
                            Delete
                        </div>
                    }
                </div>
            </div>
        </div>
    </Layout>
}