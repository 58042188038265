import instance from 'axios';
import Cookies from 'js-cookie';

export function appendTimeToDate(timeString) {
  let currentDate = new Date();
  
  let timeComponents = timeString.split(' ');
  let timeParts = timeComponents[0].split(':');
  let hours = parseInt(timeParts[0]);
  let minutes = parseInt(timeParts[1]);
  let period = timeComponents[1];
  
  if (period === 'PM' && hours < 12) {
      hours += 12;
  } else if (period === 'AM' && hours === 12) {
      hours = 0;
  }
  
  currentDate.setHours(hours, minutes, 0, 0);
  
  return currentDate;
}

export const permissionDifference = (obj1, obj2) => {
  const diff = {};
  Object.keys(obj1).forEach(key => {
    if (!obj2[key]) {
      diff[key] = obj1[key];
    } else {
      const diffArray = obj1[key].filter(permission => !obj2[key].includes(permission));
      if (diffArray.length > 0) {
        diff[key] = diffArray;
      }
    }
  });
  return diff;
};

export const isArrayEqual = (arr1, arr2)=>{
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
}

export const objectDiff = (initial, updated) => {
  const diff = {};
  Object.keys(initial).forEach(key => {
    if (Array.isArray(initial[key]) && Array.isArray(updated[key])) {

  if (!isArrayEqual(initial[key], updated[key])) {
        diff[key] = updated[key];
      }
    } else if (initial[key] !== updated[key]) {
      diff[key] = updated[key];
    }
  });
  console.log(diff)
  return diff;
};

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const numberWithCommas = (x) => {
  return parseFloat(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const holidays = [
  /* { date: "01-01", event: "New Year's Day" },
  { date: "02-25", event: "EDSA People Power Revolution Anniversary" },
  { date: "04-09", event: "Araw ng Kagitingan" },
  { date: "05-01", event: "Labor Day" },
  { date: "06-12", event: "Independence Day" },
  { date: "08-21", event: "Ninoy Aquino Day" },
  { date: "08-30", event: "National Heroes Day" }, // this date should be checked as it's variable
  { date: "11-01", event: "All Saints' Day" },
  { date: "11-30", event: "Bonifacio Day" },
  { date: "12-25", event: "Christmas Day" },
  { date: "12-30", event: "Rizal Day" } */
];


export const isHoliday = dateObj => {
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getDate()).padStart(2, '0');
  const dateStr = `${month}-${day}`;

  return holidays.filter(holiday => holiday.date === dateStr);
}

export const generateRandomString = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  return Array.from({length}, () => characters.charAt(Math.floor(Math.random() * characters.length))).join('');
}

export const truncateString = (str)=>{
  if (str?.length >= 20) {
    return str.substring(0, 17) + '...';
  } else {
    return str;
  }
}

export const formatNumber = (num)=>{
  let formattedNum = parseFloat(num).toFixed(2);
  let parts = formattedNum.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export const isWeekend = (date) => {
  const dayOfWeek = new Date(date).getDay();
  return dayOfWeek === 0 || dayOfWeek === 6;
}

export const generateOtp = () => Math.floor(Math.random() * 9000) + 1000;

export const escapeRegExp = (string)=>{
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export function getNumberOfDays(month, year) {
    month = month - 1;
    var date = new Date(year, month, 1);
    date.setMonth(date.getMonth() + 1);
    date.setDate(date.getDate() - 1);
    return date.getDate();
}
export function hasAccess(key, action, permissions){
  if(!permissions) return false;
  if(Array.isArray(permissions[key])){
    return permissions[key].includes(action);
  }
  return (permissions[key] && permissions[key][action]);
}
export function formatStatus(status, param){
  switch(status){
    case 'Pending for Approval':
      return <><span className="text-[#50D0EC] text-4xl relative">&#183;</span>Pending for Approval</>;
    case 'active':
      return <><span className="text-[#4A9952] text-4xl relative">&#183;</span>Active</>;
    case 'approved':
      return <><span className="text-[#4A9952] text-4xl relative">&#183;</span>{param?.isMember ? 'Approved' : 'Active'}</>;
    case 'expired':
      return <><span className="text-[#FF7171] text-4xl relative">&#183;</span>Expired</>;
    case 'Rejected':
      return <><span className="text-[#FF7171] text-4xl relative">&#183;</span>Rejected</>;
    case 'cancelled':
    case 'Cancelled':
      return <><span className="text-[#FF7171] text-4xl relative">&#183;</span>Cancelled</>;
    case 'DELETED':
      return <><span className="text-[#FF7171] text-4xl relative">&#183;</span>DELETED</>;
    case 'Pending Approval for Deletion':
      return <><span className="text-[#50D0EC] text-4xl relative">&#183;</span>Pending Approval for Deletion</>;
    case 'Pending Approval for Cancellation':
      return <><span className="text-[#50D0EC] text-4xl relative">&#183;</span>Pending Approval for Cancellation</>;
    case 'Pending for Confirmation':
      return <><span className="text-[#50D0EC] text-4xl relative">&#183;</span>Pending for Confirmation</>;
    case 'Pending for Acceptance':
      return <><span className="text-[#50D0EC] text-4xl relative">&#183;</span>Pending for Acceptance</>;
    case 'Active and Accepted':
      return <><span className="text-[#4A9952] text-4xl relative">&#183;</span>Active and Accepted</>;
    default:
      return <><span className="text-[#FF7171] text-4xl relative">&#183;</span>{status}</>;
  }
}
export function getMonthName(monthIndex) {
    var date = new Date(2000, monthIndex, 1);
    var monthName = date.toLocaleString('default', { month: 'long' });
    return monthName;
}

export const axios = instance.create({
  baseURL: process.env.REACT_APP_API_URL,
});
axios.interceptors.request.use(
  (config) => {
    // always check if headers contain X-Custom-Header for eaauser
    var req = new XMLHttpRequest();
    req.open("GET", document.location, false);
    req.send(null);
    var headers = req.getAllResponseHeaders().toLowerCase();
    headers = headers.split(/\n|\r|\r\n/g).reduce(function(a, b) {
    if (b.length) {
        var [key, value] = b.split(": ");
        a[key] = value;
    }
    return a;
    }, {});
    if(headers['x-custom-header']){
      config.headers['eaauser'] = headers['x-custom-header']
    }
    const token = Cookies.get('auth_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 401 && window.document.location.href.indexOf('/login') === -1){
      window.document.location.href = '/login'
    }
    throw error;
  }
);

export const isValidContactNumber = (phoneNumber) => {
  const regex = /^(\+63\d{10}|09\d{9}|\(\d{3}\) \d{3} \d{3}|\d{3}-\d{3}-\d{4}|\d{10})$/;
    return regex.test(phoneNumber);
}

export function formatDateToMMDDYYYY(date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${month}-${day}-${year}`;
}

export function formatDateToYYYYMMDD(date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export const formatDateWithTime = (dateString) => {
  const date = new Date(dateString);
  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedHours = (hours % 12 === 0 ? 12 : hours % 12).toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const amPm = hours >= 12 ? 'PM' : 'AM';
  const formattedDate = `${month} ${day}, ${year} ${formattedHours}:${formattedMinutes} ${amPm}`;
  return formattedDate;
};

export const getTimeFromDate = (date) => {
  const hours = date.getHours() % 12;
  const minutes = date.getMinutes();
  const amPm = date.getHours() >= 12 ? 'PM' : 'AM';
  const formattedHours = hours ? hours.toString().padStart(2, '0') : '12';
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedTime = `${formattedHours}:${formattedMinutes} ${amPm}`;
  return formattedTime;
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear().toString().slice(-2);

  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  const formattedDay = day < 10 ? `0${day}` : `${day}`;

  return `${formattedMonth}/${formattedDay}/${year}`;
};
export const getFormattedDate = (date) => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const day = ("0" + date.getDate()).slice(-2);
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  return `${month} ${day}, ${year}`;
}