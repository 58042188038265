import { Link, useLocation, useNavigate, Red } from 'react-router-dom';
import Cookies from 'js-cookie';
import { AuthContext } from "../AuthContext";
import Logo from "../assets/logo-header.png";
import IconSettings from "../assets/icon-settings.png";
import IconNotif from "../assets/icon-notif.png";
import IconLogout from "../assets/icon-logout.png";
import IconMembers from "../assets/icon-members.png";
import IconMembersActive from "../assets/icon-members-active.png";
import IconAccess from "../assets/icon-access.png";
import IconAccessActive from "../assets/icon-access-active.png";
import IconBiller from "../assets/icon-biller.png";
import IconBillerActive from "../assets/icon-biller-active.png";
import IconMandate from "../assets/icon-mandate.png";
import IconMandateActive from "../assets/icon-mandate-active.png";
import IconCollection from "../assets/icon-collection.png";
import IconCollectionActive from "../assets/icon-collection-active.png";
import IconReports from "../assets/icon-reports.png";
import IconReportsActive from "../assets/icon-reports-active.png";
import IconCalendar from "../assets/icon-calendar.png";
import IconCalendarActive from "../assets/icon-calendar-active.png";
import IconLogs from "../assets/icon-logs.png";
import IconLogsActive from "../assets/icon-logs-active.png";
import { useEffect, useContext, useState } from 'react';
import { axios, hasAccess } from '../utils';
import IconSidebarDropdown from '../assets/icon-sidebar-dropdown.png';
import IconSettingsActive from '../assets/icon-settings-active.png';
import IconRight from '../assets/icon-right.png';

export default function Dashboard({children}) {
    
    const {auth, updateAuth} = useContext(AuthContext);
    const location = useLocation();
    const currentPath = location.pathname;
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('type');
    const [accessDropdown, setAccessDropdown] = useState(currentPath.match(/access|admin|user/)!==null);
    const [collectionDropdown, setCollectionDropdown] = useState(currentPath.match(/issuing|acquiring/)!==null);
    const [idleTimout, setIdleTimeout] = useState(null);

    const onLogout = ()=>{
        Cookies.remove('auth_token');
        updateAuth({});
        window.document.location = '/login'
    }

    //useIdleTimer(onLogout);

    useEffect(() => {
        axios.get('/auth/me').then(async resp=>{
            let data = resp.data;
            let permissions = {};
            try{
                permissions = JSON.parse(data.permissions);
                data = {...data, permissions}
                updateAuth(data);
            }catch(err){
                console.log(err);
            }
            if(data.member_id){
                axios.get(`/member/${data.member_id||''}`).then(resp=>{
                    let {type, bic, bank_name} = resp.data;
                    if(type){
                        try{
                            type = JSON.parse(type)
                        }catch(err){
                            console.log(err);
                        }
                    }
                    updateAuth({type, bic, bank_name});
                }).catch(e=>navigate('/login'));
            }
        }).catch(e=>navigate('/login'));
    }, []);


    return <div>
        <div className="p-4 flex justify-between items-center">
                <div className="flex items-center">
                    {!auth.bankName && <img src={Logo} alt="Logo" className="h-8 mr-2" />}
                    {auth.bankName && <div className='text-2xl capitalize'>{auth.bankName}</div>}
                </div>
                <div className="flex items-center space-x-4">
                    <span className="flex items-center">
                        <Link to="/settings" className="focus:outline-none">
                            <img src={IconSettings} width="24" height="24" alt="Profile" />
                        </Link>
                        <span className="ml-1">{auth.firstName} {auth.lastName}<span className='label'></span></span>
                    </span>
                    <button className="focus:outline-none">
                    <img src={IconNotif} width="24" height="24" alt="Notification" />
                    </button>
                    <span className="flex items-center">
                        <button className="focus:outline-none">
                            <img src={IconLogout} height="15" width="15" alt="Logout" />
                        </button>
                        <button onClick={onLogout} className="ml-2">Logout</button>
                    </span>
            </div>
        </div>
        <div className="flex h-screen">
            <div className="w-72 pl-3 sidebar">
                <ul>
                    {!auth.member_id && <li className={`my-3 p-1 flex items-center justify-start ${(currentPath.indexOf('member')>-1) ? 'current-path text-primary': ''}`}>
                        <img src={ currentPath.indexOf('/member-management')>-1 ? IconMembersActive : IconMembers} alt="Members Management"/>
                        <Link to="/member-management" className="block px-2 p-2 rounded w-full">{}Member Management</Link>
                    </li>}
                    {auth.role==='admin' && <li onClick={()=>navigate('/access-management?type=internal')} className={`mt-3 p-1 flex items-center justify-start cursor-pointer ${currentPath.match(/access|admin|user|employee/)!==null || accessDropdown ? 'current-path text-primary': ''}`}>
                        <img src={ currentPath.match(/access|admin|user|employee/)!==null ? IconAccessActive : IconAccess} alt="Access Management"/>
                        <Link to={'/access-management?type=internal'} className="block px-2 p-2 rounded">Access Management</Link>
                        {!auth.member_id && <img src={currentPath.match(/access|admin|user|employee/)!==null ? IconSidebarDropdown : IconRight} alt="Dropdown" className="ml-auto mr-1" />}
                    </li>}
                    {
                        (!auth.member_id && accessDropdown) && <>
                            <li className={`mt-0 pb-1 pl-12 cursor-pointer flex bg-[#D7F4DB] ${type==='internal' || (currentPath.indexOf('user')>-1 && type!=='bank') ? 'text-primary' : ''}`}>
                                <Link to="/access-management?type=internal" className="block w-full px-2 p-2 rounded">Internal</Link>
                            </li>
                            <li className={`mt-0 pb-1 pl-12 cursor-pointer flex bg-[#D7F4DB] ${type==='bank' || currentPath.indexOf('admin')>-1 ? 'text-primary' : ''}`}>
                                <Link to="/access-management?type=bank" className="block w-full px-2 p-2 rounded">Bank</Link>
                            </li>
                        </>
                    }
                    <li className={`my-3 p-1 flex items-center justify-start ${(currentPath.indexOf('biller')>-1) ? 'current-path text-primary': ''}`}>
                        <img src={ currentPath.indexOf('/biller-management')>-1 ? IconBillerActive : IconBiller} alt="Biller Management"/>
                        <Link to="/biller-management" className="block px-2 p-2 rounded w-full">Biller Management</Link>
                    </li>
                    <li className={`my-3 p-1 flex items-center justify-start ${(currentPath.indexOf('/mandate')>-1) ? 'current-path text-primary': ''}`}>
                        <img src={ currentPath.indexOf('/mandate-management')>-1 ? IconMandateActive : IconMandate} alt="Biller Management"/>
                        <Link to="/mandate-management" className="block px-2 p-2 rounded w-full">Mandate Management</Link>
                    </li>
                    {!auth.member_id && <>
                        <li className={`my-3 p-1 flex items-center justify-start ${(currentPath.indexOf('/collections')>-1) ? 'current-path text-primary': ''}`}>
                            <img src={ currentPath.indexOf('/collections')>-1 ? IconCollectionActive : IconCollection} alt="Collection Management"/>
                            <Link to="/collections" className="block px-2 p-2 rounded w-full">Presentment and Collection Management</Link>
                        </li>
                        <li className={`my-3 p-1 flex items-center justify-start ${(currentPath.indexOf('/logs')>-1) ? 'current-path text-primary': ''}`}>
                            <img src={ currentPath.indexOf('/logs')>-1 ? IconLogsActive : IconLogs} alt="Logs"/>
                            <Link to="/logs" className="block px-2 p-2 rounded w-full">Audit Log Reports</Link>
                        </li>
                        {hasAccess('calendar-all', 'view', auth.permissions) && <li className={`my-3 p-1 flex items-center justify-start ${(currentPath.match(/calendar/)!==null) ? 'current-path text-primary': ''}`}>
                            <img src={ currentPath.match(/calendar/)!==null ? IconCalendarActive : IconCalendar} alt="Biller Management"/>
                            <Link to="/calendar-management" className="block px-2 p-2 rounded w-full">Calendar Management</Link>
                        </li>}
                        <li className={`my-3 p-1 flex items-center justify-start ${(currentPath.indexOf('/dd-portal-settings')>-1) ? 'current-path text-primary': ''}`}>
                            <img src={ currentPath.indexOf('/dd-portal-settings')>-1 ? IconSettingsActive : IconSettings} alt="DD Portal Settings"/>
                            <Link to="/dd-portal-settings" className="block px-2 p-2 rounded w-full">DD Portal Settings</Link>
                        </li>
                    </>
                    }
                    {
                        auth.member_id && <>
                            <li onClick={()=>navigate('/collection-management?type=acquiring')} className={`mt-3 p-1 flex items-center justify-start cursor-pointer ${currentPath.match(/collection/)!==null || collectionDropdown ? 'current-path text-primary bg-[#E9F8EB]': ''}`}>
                                <img src={ currentPath.indexOf('/collection-management')>-1 ? IconCollectionActive : IconCollection} alt="Collection Management"/>
                                <Link to="/collection-management?type=acquiring" className="px-2 p-2 rounded flex items-center w-full">
                                    Collection Management
                                    {<img src={currentPath.indexOf('/collection-management')>-1 ? IconSidebarDropdown : IconRight} alt="Dropdown" className="ml-auto mr-1" />}
                                </Link>
                            </li>
                            {
                                currentPath.match(/collection/)!==null && <>
                                    <li className={`mt-0 pb-1 pl-12 cursor-pointer flex bg-[#D7F4DB] ${type==='acquiring'? 'text-primary ' : ''}`}>
                                        <Link to="/collection-management?type=acquiring" className="block px-2 p-2 rounded w-full">Acquiring Bank</Link>
                                    </li>
                                    <li className={`mt-0 pb-1 pl-12 cursor-pointer flex bg-[#D7F4DB] ${type==='issuing'? 'text-primary ' : ''}`}>
                                        <Link to="/collection-management?type=issuing" className="block px-2 p-2 rounded w-full">Issuing Bank</Link>
                                    </li>
                                </>
                            }
                            <li className={`my-3 p-1 flex items-center justify-start ${(currentPath.indexOf('/reports')>-1) ? 'current-path text-primary': ''}`}>
                                <img src={ currentPath.indexOf('/reports')>-1 ? IconReportsActive : IconReports} alt="Logs"/>
                                <Link to="/reports" className="block px-2 p-2 rounded w-full">Reports</Link>
                            </li>
                            <li className={`my-3 p-1 flex items-center justify-start ${(currentPath.indexOf('/logs')>-1) ? 'current-path text-primary': ''}`}>
                                <img src={ currentPath.indexOf('/logs')>-1 ? IconLogsActive : IconLogs} alt="Logs"/>
                                <Link to="/logs" className="block px-2 p-2 rounded w-full">Audit Log Reports</Link>
                            </li>
                        </>
                    }
                </ul>
            </div>
        
            <div className="flex-1 main-content overflow-auto">
                {children}
            </div>
        </div>
    </div>
}