import { useState, useEffect, useCallback, useRef } from "react";

function useIdleTimer(logoutCallback, idleTimeout = 60000) {
  const timerRef = useRef(null);  

  const resetTimer = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);  
    }
    
    timerRef.current = setTimeout(() => {
      logoutCallback();  
    }, idleTimeout);
  }, [logoutCallback, idleTimeout]);

  useEffect(() => {
    const handleUserActivity = () => resetTimer();  

    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);
    window.addEventListener("click", handleUserActivity);

    resetTimer();

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);  
      }
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      window.removeEventListener("click", handleUserActivity);
    };
  }, [resetTimer]);  

  return null;
}

export default useIdleTimer;
