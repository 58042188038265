import React, { useState } from 'react';

const Modal = ({children, onClose}) => {
  const [isOpen, setIsOpen] = useState(true);

  const closeModal = () => {
    onClose && onClose();
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="relative w-[508px] mx-auto my-6">
            <div className="relative flex flex-col w-full bg-white  rounded-lg shadow-lg outline-none focus:outline-none">
                {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
