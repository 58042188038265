import {useState, useEffect, useRef, useContext} from "react";
import Layout from "../layouts/Dashboard";
import { Link, useNavigate, useLocation } from "react-router-dom";
import IconCalendar from "../assets/icon-calendar.png";
import Modal from "./Modal";
import IconSuccess from "../assets/icon-success.png";
import { axios, isWeekend, generateRandomString, numberWithCommas, hasAccess, formatDateToYYYYMMDD } from "../utils";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AuthContext } from "../AuthContext";
import IconClock from "../assets/icon-clock.png";
import IconDelete from "../assets/icon-delete.png";
import IconDeleteConfirm from "../assets/icon-delete-confirm.png";
import IconCancel from "../assets/icon-cancel.png";
import IconCancelGreen from "../assets/icon-cancel-green.png";
import IconSuspend from "../assets/icon-suspend.png";

export default function CreateMandate(){

    const {auth} = useContext(AuthContext);
    const refStartDate = useRef(null);
    const refEndDate = useRef(null);
    const [success, setSuccess] = useState(false);
    const [form, setForm] = useState({
        mandate_id: '',
        biller_id: '',
        customer_name: '',
        bank_id: '',
        customer_bank_account_number: '',
        amount: '',
        start_date: null,
        end_date: null
    });
    const [billers, setBillers] = useState([]);
    const [members, setMembers] = useState([]);
    const [error, setError] = useState(null);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const [biller, setBiller] = useState({});
    const [oldMandateId, setOldMandateId] = useState(null);
    const [initialForm, setInitialForm] = useState({});
    const [isDirty, setIsDirty] = useState({});
    const [randomString] = useState(generateRandomString(7));
    const [member, setMember] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [cancelConfirmModal, setCancelConfirmModal] = useState(false);
    const [cancelSuccess, setCancelSuccess] = useState(false);
    const [suspendConfirmModal, setSuspendConfirmModal] = useState(false);
    const [suspendSuccess, setSuspendSuccess] = useState(false);
    
    const onLoad = async ()=>{
        try{
            let resp;
            if(id && !auth.member_id){
                resp = await axios.get(`billers?limit=1000`);
                setBillers(resp.data.data);
            }else{
                resp = await axios.get(`member/${auth.member_id}/billers`);
                setBillers(resp.data || []);
                if(resp.data.length){
                    const _billers = [...new Map(resp.data.map(item => [item['biller_id'], item])).values()];
                    const promises = _billers.map(async (biller)=>{
                        const resp = await axios.get(`/billers/${biller.biller_id}`);
                        return {...biller, ...resp.data}
                    })
                    const enrolledBillers = await Promise.all(promises);
                    const approvedBillers = enrolledBillers.filter((biller)=>biller.status==='approved');
                    setBillers(approvedBillers);
                }
            }
            const _members_resp = await axios.get(`/member/util/has_admins`);
            setMembers(_members_resp.data);
            
            let isRejected = false;
            if(id){
                resp = await axios.get(`/mandates/${id}`);
                const _data = {...resp.data, amount: numberWithCommas(resp.data.amount)};
                setInitialForm(_data);
                if(
                    ['Rejected', 'Pending for Approval', 'Pending for Acceptance', 'Active and Accepted'].includes(_data.status)
                    && hasAccess('mandate-all', 'edit', auth.permissions)
                ){
                    _data.old_mandate_id = _data.mandate_id;
                    isRejected = true;
                    setOldMandateId(_data.old_mandate_id);
                    setIsEdit(true)
                }
                setForm(_data);
                setMember(_members_resp.data.filter(m=>m.id === _data.member_id)[0]);
                onChange({target: {name: 'biller_id', value: _data.biller_id, skip: true, isRejected, data: _data}});
            }
        }catch(e){
            setError(e.response?.data?.message || e.message);
        }
    }

    useEffect(()=>{
        if(auth.id){
            onLoad();
        }
    }, [auth]);

    const canEdit = (data)=>{
        return  (data.member_id===auth.member_id && data.status !== 'expired' && data.status!=='Cancelled' && hasAccess('mandate-all', 'edit', auth.permissions));
    }

    const onChange = (e)=>{
        let {name, value, skip, isRejected, data} = e.target;

        if(name === 'customer_bank_account_number' || name === 'amount'){
            value = value.replace(/\D/g, '');
        }else if(name==='biller_id'){
            if(value){
                axios.get(`/billers/${value}`).then(resp=>{
                    setBiller(resp.data);
                    if(!id || (isRejected && canEdit(data))){
                        const mandate_id = `${auth.bic}${resp.data.biller_id}${randomString}`
                        onChange({target: {name: 'mandate_id', value: mandate_id}})
                    }
                })
            }else{
                onChange({target: {name: 'mandate_id', value: ''}})
            }
        }
        else if(name==='customer_name' || name ==='liason_name' || name==='liason2_name'){
            value = value.replace(/[^a-zA-Z\s]/g, '');
        }else if(name==='amount'){
            value = numberWithCommas(value);
        }

        setForm(prev=>{
            if(!skip && initialForm[name]){
                setIsDirty(prev=>({...prev, [name]: initialForm[name] != value}));
            }
            return {...prev, [name]: value}
        });
    }

    const onSubmit = ()=>{
        setError('')
        setErrors({});

        const hasEmpty = Object.keys(form).filter(key=>!['bankByName'].includes(key)).filter(key=>!form[key]);
        if(hasEmpty.length){
            setErrors(hasEmpty.reduce((acc, key)=>({...acc, [key]: `${key.replaceAll('_', ' ').replace(/\b\w/g, char => char.toUpperCase())} is required.`}), {}));
            return;
        }

        if(new Date(form.start_date) < stripTime(new Date())){
            setErrors({...errors, start_date: 'Start Date must be current or future date'});
            return;
        }
        if(new Date(form.end_date) <= new Date(form.start_date)){
            setErrors({...errors, end_date: 'End Date must be a day after Start Date'});
            return;
        }

        axios.get(`/users?member_id=${form.bank_id}`).then(async(resp)=>{
            if(!resp.data.data.length){
                setErrors({...errors, bank_id: 'Invalid Bank ID'});
                return;
            }else{
                if(id && !['Rejected', 'Pending for Approval', 'Pending for Acceptance', 'Active and Accepted'].includes(form.status)) {
                    await axios.patch(`/mandates/${id}`, {
                        ...form, amount: parseFloat(form.amount),
                        status: 'Pending Confirmation',
                    })
                }
                else {
                    await axios.post('/mandates', {
                        ...form, 
                        amount: parseFloat(`${form.amount}`?.replace(',', '')), 
                        status: 'Pending for Approval',
                        start_date: formatDateToYYYYMMDD(new Date(form.start_date)),
                        end_date: formatDateToYYYYMMDD(new Date(form.end_date)),
                        ...(['Rejected', 'Pending for Approval', 'Pending for Acceptance', 'Active and Accepted', 'Cancelled'].includes(form.status) && {old_mandate_id: oldMandateId})
                    });
                    if(id) await axios.patch(`/mandates/${id}`, {status: 'Cancelled'})
                }
                setSuccess(true);
            }
        })
        .catch(e=>{
            setError(e.response?.data?.errors ? Object.values(e.response.data.errors)[0] : e.message);
        })
    }
  
    const handleDateChange = (k, date) => {
        setErrors(prev=>({...prev, [k]: ''}));
        if(k==='start_date' && stripTime(new Date(date)) < stripTime(new Date())){
            setErrors(prev=>({...prev, start_date: 'Start Date must be current or future date'}));
            return;
        }else if(k==='end_date' && stripTime(new Date(date)) <= stripTime(new Date(form.start_date ||  new Date()))){
            setErrors(prev=>({...prev, end_date: 'End Date must be at least a day after Start Date'}));
            return;
        }else if(isWeekend(date)){
            setErrors({...errors, [k]: `${k==='start_date' ? 'Start Date' : 'End Date'} must be weekday`});
        }
      setForm({...form, [k]: stripTime(date)});
      onChange({target: {name: k, value: date}});
    };

    const stripTime = date => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

    const onClickDateIcon = ()=>{
        setTimeout(()=>{
            const datepickerPopper = document.querySelector('.react-datepicker-popper');
            datepickerPopper?.classList.add('calendar-box-right');
        }, 1);
    }

    const onDelete = async()=>{
        setDeleteConfirmModal(false);
        setError('')
        await axios.patch(`/mandates/${id}`, {status: 'Pending Approval for Deletion'}).then(()=>setDeleteSuccess(true)).catch(e=>{
            setError(e.response?.data?.message || e.message);
        });
    }

    const onCancel = async()=>{
        setCancelConfirmModal(false);
        setError('')
        await axios.patch(`/mandates/${id}`, {status: 'Pending Approval for Cancellation'}).then(()=>setCancelSuccess(true)).catch(e=>{
            setError(e.response?.data?.message || e.message);
        });
    }

    const onSuspend = async()=>{
        setSuspendConfirmModal(false);
        setError('')
        await axios.patch(`/mandates/${user.id}`, {status: 'Suspended'}).then(()=>setSuspendSuccess(true)).catch(e=>{
            setError(e.response?.data?.message || e.message);
        });
    }

    return <Layout>
        <div className="flex justify-between items-center">
            <p className="text-2xl font-bold">{id ? (isEdit ? 'Edit':'View') :'Create'} Mandate</p>
            <div className="flex items-center gap-2">
                {id && <Link to={`/mandate-history?id=${id}`} className="primary text-white px-4 py-2 rounded-md flex justify-center gap-2"><img src={IconClock} width={22} alt="View History Icon"/> <span className="pt-[1px]">View History</span></Link>}
                <Link to="/mandate-management" className="px-4 py-2 secondary w-[88px] text-center text-white rounded-md">Back</Link>
            </div>
        </div>
            {
                suspendSuccess && <Modal>
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                        <div className="mb-4 items-center flex flex-col">
                            <img src={IconDeleteConfirm} alt="Success" className="mb-4"/>
                            <p className="font-semibold text-2xl mb-4">Suspend Success</p>
                            <span className="mb-6">You successfully suspended a user.</span>
                            <button onClick={()=>navigate('/mandate-management')} className="primary text-white px-4 py-2 rounded-md w-1/2 block">OK</button>
                        </div>
                    </div>
                </div>
                </Modal>
            }
            {
                suspendConfirmModal && <Modal>
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                        <div className="mb-4 items-center flex flex-col">
                            <img src={IconDeleteConfirm} alt="Success" className="mb-4"/>
                            <p className="font-semibold text-2xl mb-4">Suspend</p>
                            <span className="mb-6">Are you sure you want to suspend?</span>
                            <div className="flex gap-3">
                                <button onClick={()=>setSuspendConfirmModal(false)} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Cancel</button>
                                <button onClick={onSuspend} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Suspend</button>
                            </div>
                        </div>
                    </div>
                </div>
                </Modal>
            }
            {
                cancelSuccess && <Modal>
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                        <div className="mb-4 items-center flex flex-col">
                            <img src={IconDeleteConfirm} alt="Success" className="mb-4"/>
                            <p className="font-semibold text-2xl mb-4">Cancel Success</p>
                            <span className="mb-6">You successfully cancelled a mandate.</span>
                            <button onClick={()=>navigate('/mandate-management')} className="primary text-white px-4 py-2 rounded-md w-1/2 block">OK</button>
                        </div>
                    </div>
                </div>
                </Modal>
            }
            {
                cancelConfirmModal && <Modal>
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                        <div className="mb-4 items-center flex flex-col">
                            <img src={IconCancelGreen} alt="Success" className="mb-4"/>
                            <p className="font-semibold text-2xl mb-4">Cancel</p>
                            <span className="mb-6">Are you sure you want to cancel?</span>
                            <div className="flex gap-3">
                                <button onClick={()=>setCancelConfirmModal(false)} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Cancel</button>
                                <button onClick={onCancel} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Yes</button>
                            </div>
                        </div>
                    </div>
                </div>
                </Modal>
            }
            {
                deleteSuccess && <Modal>
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                        <div className="mb-4 items-center flex flex-col">
                            <img src={IconDeleteConfirm} alt="Success" className="mb-4"/>
                            <p className="font-semibold text-2xl mb-4">Delete Success</p>
                            <span className="mb-6">You successfully deleted a mandate.</span>
                            <button onClick={()=>navigate('/mandate-management')} className="primary text-white px-4 py-2 rounded-md w-1/2 block">OK</button>
                        </div>
                    </div>
                </div>
                </Modal>
            }
            {
                deleteConfirmModal && <Modal>
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                        <div className="mb-4 items-center flex flex-col">
                            <img src={IconDeleteConfirm} alt="Success" className="mb-4"/>
                            <p className="font-semibold text-2xl mb-4">Delete</p>
                            <span className="mb-6">Are you sure you want to delete?</span>
                            <div className="flex gap-3">
                                <button onClick={()=>setDeleteConfirmModal(false)} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Cancel</button>
                                <button onClick={onDelete} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
                </Modal>
            }
        {
            success && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4">Mandate {id ? 'Updated':'Created'}</p>
                        <span className="mb-6">You successfully {id ? 'updated a':'created a'} mandate.</span>
                        <button onClick={()=>navigate('/mandate-management')} className="primary text-white px-4 py-2 rounded-md w-1/2 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        <div className={`w-full bg-white border rounded p-6 mt-6 pb-6 ${(id && !canEdit(form)) && 'pointer-events-none'}`}>
            <div className="flex flex-col items-center ">
                {error && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{error}</span>}
                {
                    form.status==='Rejected' && 
                    <div className="flex items-start flex-col mb-4 w-3/6">
                        <div className="font-bold">Status: <span className="text-red-500">Rejected</span></div>
                        <div className="font-bold">Reason for rejection: <span className="font-normal">{form.reject_reason}</span></div>
                    </div>
                }
                {oldMandateId && <div className="mb-0 w-[535px] pt-2">
                    <div class="relative input-container">
                        <input 
                            type="text" 
                            id="mandate_id" 
                            name="mandate_id" 
                            readOnly
                            value={oldMandateId}
                            className={`pt-4 w-full pl-[10px] font-medium text-base py-2 border rounded-md ${oldMandateId?'border-black': ''}`}
                        />
                        <label 
                            for="mandate_id" 
                            className={`pl-1 ${oldMandateId?'active':''}`}
                        >
                            Old Mandate ID
                        </label>
                    </div>
                </div>}
                <div className="w-[535px]">
                    <div class={`relative input-container px-2 border rounded-lg ${form.biller_id ? 'border-black' : 'py-2'}`}>
                        {
                            form.biller_id && 
                            <label 
                                for="biller_id" 
                                className={`relative text-xs ${form.biller_id?'active':'ml-1'}`}
                            >
                                Biller Name
                            </label>
                        }
                        <select
                            id="dropdown"
                            name="biller_id"
                            value={form.biller_id}
                            onChange={onChange}
                            className={`outline-none w-full pr-2 ml-[-3px] z-[-1] pb-1 font-medium text-gray-500 ${form.biller_id ? 'xappearance-none': '' }`}
                        >
                        {!id && <option value="">Biller Name</option>}
                        {billers.map(b=><option value={b.id}>{b.biller_name}</option>)}
                        </select>
                    </div>
                    {errors.biller_id && <span className="block text-red-500 font-semibold text-xs italic">{errors.biller_id}</span>}
                </div>
                <div className="mb-0 w-[535px]">
                    <div class="relative input-container">
                        <input 
                            type="text" 
                            value={biller.biller_id}
                            readOnly
                            className={`pt-4 w-full pl-[10px] font-medium text-base py-2 border rounded-md ${biller.biller_id?'border-black': ''}`}
                        />
                        <label 
                            for="tin_no" 
                            className={`pl-0 ${biller.biller_id?'active pl-2':''}`}
                        >
                            Biller Code
                        </label>
                    </div>
                </div>
                <div className="mb-0 w-[535px]">
                    <div class="relative input-container">
                        <input 
                            type="text" 
                            id="tin_no" 
                            name="tin_no" 
                            value={biller.biller_tin_number}
                            onChange={onChange}
                            readOnly
                            className={`pt-4 w-full pl-[10px] font-medium text-base py-2 border rounded-md ${biller.biller_tin_number?'border-black': ''}`}
                        />
                        <label 
                            for="tin_no" 
                            className={`pl-0 ${biller.biller_tin_number?'active pl-2':''}`}
                        >
                            TIN No.
                        </label>
                    </div>
                    {errors.customer_name && <span className="block text-red-500 font-semibold text-xs italic">{errors.customer_name}</span>}
                </div>
                <div className="mb-0 w-[535px]">
                    <div class="relative input-container">
                        <input 
                            type="text" 
                            id="branch_code" 
                            name="branch_code" 
                            value={biller.branch_code}
                            readOnly
                            onChange={onChange}
                            className={`pt-4 w-full pl-[10px] font-medium text-base py-2 border rounded-md ${biller.branch_code?'border-black': ''}`}
                        />
                        <label 
                            for="branch_code" 
                            className={`pl-0 ${biller.branch_code?'active':''}`}
                        >
                            Branch Code
                        </label>
                    </div>
                    {errors.branch_code && <span className="block text-red-500 font-semibold text-xs italic">{errors.branch_code}</span>}
                </div>
                <div className="mb-0 w-[535px] pt-2">
                    <div class="relative input-container">
                        <input 
                            type="text" 
                            id="mandate_id" 
                            name="mandate_id" 
                            readOnly
                            value={form.mandate_id}
                            className={`pt-4 w-full pl-[10px] font-medium text-base py-2 border rounded-md ${form.mandate_id?'border-black': ''}`}
                        />
                        <label 
                            for="mandate_id" 
                            className={`pl-1 ${form.mandate_id?'active':''}`}
                        >
                            Mandate ID
                        </label>
                    </div>
                    {errors.mandate_id && <span className="block text-red-500 font-semibold text-xs italic">{errors.mandate_id}</span>}
                </div>
                <div className="mb-0 w-[535px]">
                    <div class="relative input-container">
                        <input 
                            type="text" 
                            id="bank_name" 
                            name="bank_name" 
                            value={id ? member?.bank_name : auth.bank_name}
                            onChange={onChange}
                            readOnly
                            className={`pt-4 w-full pl-[10px] font-medium text-base py-2 border rounded-md ${member?.bank_name || auth.bank_name?'border-black': ''}`}
                        />
                        <label 
                            for="bank_name" 
                            className={`pl-0 ${member?.bank_name || auth.bank_name?'active':''}`}
                        >
                            Acquiring Bank Name
                        </label>
                    </div>
                </div>
                <div className="mb-0 w-[535px]">
                    <div class="relative input-container">
                        <input 
                            type="text" 
                            id="bic" 
                            name="bic" 
                            value={id ? member?.bic : auth.bic}
                            onChange={onChange}
                            readOnly
                            className={`pt-4 w-full pl-[10px] font-medium text-base py-2 border rounded-md ${member?.bic || auth.bic?'border-black': ''}`}
                        />
                        <label 
                            for="bic" 
                            className={`pl-0 ${member?.bic || auth.bic?'active':''}`}
                        >
                            Acquiring Bank BIC
                        </label>
                    </div>
                </div>
                <div className="mb-0 w-[535px]">
                    <div class="relative input-container">
                        <input 
                            type="text" 
                            id="customer_name" 
                            name="customer_name" 
                            value={form.customer_name}
                            onChange={onChange}
                            className={`pt-4 w-full pl-[10px] font-medium text-base py-2 border rounded-md ${form.customer_name?'border-black': ''}`}
                        />
                        <label 
                            for="customer_name" 
                            className={`pl-0 ${form.customer_name?'active':'pl-1'}`}
                        >
                            Customer Name
                        </label>
                    </div>
                    {errors.customer_name && <span className="block text-red-500 font-semibold text-xs italic">{errors.customer_name}</span>}
                </div>
                <div className="mb-0 w-[535px]">
                    <div class="relative input-container">
                        <input 
                            type="text" 
                            id="customer_reference_id" 
                            name="customer_reference_id" 
                            value={form.customer_reference_id}
                            onChange={onChange}
                            className={`pt-4 w-full pl-[10px] font-medium text-base py-2 border rounded-md ${form.customer_reference_id?'border-black': ''}`}
                        />
                        <label 
                            for="customer_reference_id" 
                            className={`pl-0 ${form.customer_reference_id?'active':'pl-1'}`}
                        >
                            Customer Reference ID
                        </label>
                    </div>
                    {errors.customer_reference_id && <span className="block text-red-500 font-semibold text-xs italic">{errors.customer_reference_id}</span>}
                </div>
                <div className="mb-0 w-[535px]">
                    <div class={`relative input-container border rounded-lg pt-1 pr-2 ${form.bank_id ? 'border-black': 'py-2'}`}>
                        {
                            form.bank_id && 
                            <label 
                                for="customer_bank_account_number" 
                                className={`relative text-xs ${form.bank_id?'ml-3 active':'ml-1 pl-1'}`}
                            >
                                Customer Bank
                            </label>
                        }
                        <select
                        id="dropdown"
                        name="bank_id"
                        value={form.bank_id}
                        onChange={onChange}
                        className={`w-full outline-none px-1 ml-1 pb-1 font-medium text-gray-500 ${form.bank_id?'xappearance-none border-black mt-[-20px] pl-2': 'py-1 pl-1'}`}
                        >
                        <option value="">Customer Bank</option>
                        {members.map(member=><option value={member.id}>{member.bank_name}</option>)}
                        </select>
                    </div>
                    {errors.bank_id && <span className="block text-red-500 font-semibold text-xs italic">{errors.bank_id}</span>}
                </div>
                <div className="mb-0 w-[535px]">
                    <div class="relative input-container">
                        <input 
                            type="text" 
                            id="customer_bank_account_number" 
                            name="customer_bank_account_number" 
                            value={form.customer_bank_account_number}
                            onChange={onChange}
                            className={`pt-4 w-full font-medium text-base py-2 border rounded-md ${form.customer_bank_account_number?'border-black pl-3': ''}`}
                        />
                        <label 
                            for="customer_bank_account_number" 
                            className={`${form.customer_bank_account_number?'ml-[-18px] active pl-3':'ml-0 pl-1'}`}
                        >
                            Customer Bank Account Number
                        </label>
                    </div>
                    {errors.customer_bank_account_number && <span className="block text-red-500 font-semibold text-xs italic">{errors.customer_bank_account_number}</span>}
                </div>
                <div className="mb-0 w-[535px]">
                    <div class="relative input-container">
                        <input 
                            type="text" 
                            id="amount" 
                            name="amount" 
                            value={form.amount}
                            onChange={onChange}
                            className={`pt-4 w-full pl-[10px] font-medium text-base py-2 border rounded-md ${form.amount?'border-black': ''}`}
                        />
                        <label 
                            for="amount" 
                            className={`pl-2 ${form.amount?'active ml-[-21px]':''}`}
                        >
                            Approve Amount/Approve Amount Range
                        </label>
                    </div>
                    {errors.amount && <span className="block text-red-500 font-semibold text-xs italic">{errors.amount}</span>}
                </div>
                <div className="mb-4 w-[535px]">
                    <div className="relative w-full">
                        <div class="relative">
                            <DatePicker
                                selected={form.start_date}
                                onChange={(v)=>handleDateChange('start_date', v)}
                                dateFormat="MM/dd/yyyy"
                                ref={refStartDate}
                                minDate={new Date()}
                                className={`pt-4 w-full px-3 font-medium text-base py-2 border rounded-md ${form.start_date?'border-black': ''}`}
                            />
                            <label 
                                for="start_date" 
                                className="absolute top-1 text-xs left-3 transition-all duration-200 ease-in-out text-gray-500"
                            >
                                Start Date
                            </label>
                        </div>
                        <div className="absolute inset-y-0 right-0 flex items-center px-3 rounded-r-lg">
                            <img src={IconCalendar} alt="Calendar" className="cursor-pointer" onClick={()=>{refStartDate.current.setFocus();onClickDateIcon()}}/>
                        </div>
                    </div>
                    {errors.start_date && <span className="block text-red-500 font-semibold text-xs italic">{errors.start_date}</span>}
                </div>
                <div className="mb-4 w-[535px]">
                    <div className="relative w-full">
                        <div class="relative">
                            <DatePicker
                                selected={form.end_date}
                                ref={refEndDate}
                                onChange={(v)=>handleDateChange('end_date', v)}
                                dateFormat="MM/dd/yyyy"
                                minDate={new Date(new Date(form.start_date).setDate(new Date().getDate() + 1))}
                                className={`pt-4 w-full px-3 font-medium text-base py-2 border rounded-md ${form.end_date?'border-black': ''}`}
                            />
                            <label 
                                for="end_date" 
                                className="absolute top-1 text-xs left-3 transition-all duration-200 ease-in-out text-gray-500"
                            >
                                End Date
                            </label>
                        </div>
                        <div className="absolute inset-y-0 right-0 flex items-center px-3 rounded-r-lg">
                            <img src={IconCalendar} onClick={()=>{refEndDate.current.setFocus();onClickDateIcon()}} className="cursor-pointer" alt="Calendar"/>
                        </div>
                    </div>
                    {errors.end_date && <span className="block text-red-500 font-semibold text-xs italic">{errors.end_date}</span>}
                </div>
                <div className="mb-4 w-[535px] text-center">
                    <button disabled={(id && !canEdit(form)) || (id && !Object.keys(isDirty).some(key=>isDirty[key])) || errors.start_date || errors.end_date || Object.keys(form).filter(f=>['mandate_id','biller_id','customer_name','bank_id','customer_bank_account_number','amount','start_date','end_date'].includes(f)).some(key=>!form[key])} onClick={onSubmit} className="primary text-white px-4 py-2 h-[54px] rounded-md w-full block">{id ? (form.status==='Rejected' ? 'Re-Submit' : 'Save') :'Create Mandate'} </button>
                </div>
                <div className={`w-[535px] flex justify-between ${form.status==='expired' ? 'pointer-events-none opacity-50' : 'pointer-events-auto'}`}>
                    {
                        (form.status!=='Cancelled' && hasAccess('mandate-all', 'cancel', auth.permissions) && id && auth.member_id === form.member_id) && <div onClick={()=>setCancelConfirmModal(true)} className="justify-center flex text-red-500 gap-2 cursor-pointer">
                            <img src={IconCancel} alt="Cancel" className="cursor-pointer" />
                            Cancel
                        </div>
                    }
                    {
                        (false && hasAccess('mandate-all', 'delete', auth.permissions) && id) && <div onClick={()=>setSuspendConfirmModal(true)} className={`justify-center flex text-red-500 gap-2 cursor-pointer`}>
                            <img src={IconSuspend} alt="Suspend" className="cursor-pointer" />
                            Suspend
                        </div>
                    }
                </div>
            </div>
        </div>
    </Layout>
}