import {useState, useEffect, useRef, forwardRef, useContext} from "react";
import Layout from "../layouts/Dashboard";
import IconPrev from "../assets/icon-prev.png";
import IconNext from "../assets/icon-next.png";
import {getNumberOfDays, getMonthName, axios, formatDateToMMDDYYYY, isHoliday, hasAccess, objectDiff} from "../utils";
import Modal from "./Modal";
import IconCalendar from "../assets/icon-calendar.png";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import IconActive from "../assets/icon-active.png";
import IconActiveOff from "../assets/icon-active-off.png";
import IconSearch from "../assets/icon-search.png";
import IconDelete from "../assets/icon-delete-green.png";
import IconSuccess from "../assets/icon-success.png";
import Icon3Dot from "../assets/3dot.png";
import IconCheckRed from "../assets/icon-checked-red.jpeg";
import { AuthContext } from "../AuthContext";
import { Link } from "react-router-dom";
import IconRight from "../assets/icon-right.png";
import IconDown from "../assets/icon-down.png";

const CustomInput = forwardRef(({value, onClick, onChange, ...props}, ref)=> {
    const handleKeyPress = (event) => {
      const charCode = event.charCode;
      // Allow numeric characters and the '/'
      if (
        (charCode < 48 || charCode > 57) && // Not a number
        charCode !== 47 // Not a '/'
      ) {
        event.preventDefault();
      }
    };
  
    return (
        <div className="flex border border-gray-300 rounded-md">
            <input
                {...props}
                value={value}
                onClick={onClick}
                onChange={onChange}
                onKeyPress={handleKeyPress}
            />
            <img className="cursor-pointer w-[24px] h-[24px] mt-2 mr-3" onClick={onClick} src={IconCalendar} alt="Calendar"/>
        </div>
    );
})

export default function CalendarManagement(){

    const {auth} = useContext(AuthContext);
    const currentYear = new Date().getFullYear();
    const [month, setMonth] = useState(new Date().getMonth());
    const [year, setYear] = useState(new Date().getFullYear());
    const [days, setDays] = useState(getNumberOfDays(month+1, year));
    const [startDay, setStartDay] = useState();
    const [createModal, setCreateModal] = useState();
    const [form, setForm] = useState({status: 'active'});
    const [enableExclude, setEnableExclude] = useState(false);
    const [search, setSearch] = useState('');
    const refDate = useRef(null);
    const [members, setMembers] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [excludes, setExcludes] = useState([]);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState();
    const [calendars, setCalendars] = useState([]);
    const [showApproved, setShowApproved] = useState(false);
    const [showPending, setShowPending] = useState(false);
    const [initialForm, setInitialForm] = useState({});

    const onChange = e=>{
        let {name, value} = e.target;
        setForm({...form, [name]: value});
        if(name==='search'){
            if (value) {
              setFilteredOptions(members.filter(m=>!excludes.some(f=>f.id === m.id)).filter(m=>m.bank_name.toLowerCase().includes(value.toLowerCase())));
            } else {
              setFilteredOptions([]);
            }
        }
    }
    const incrementMonth = () => {
        if (month === 11) {
            if (year < currentYear + 2) {
                setMonth(0);
                setYear(year + 1);
            }
        } else {
            setMonth(month + 1);
        }
    };

    const decrementMonth = () => {
        if (month === 0) {
            if (year > currentYear) {
                setMonth(11);
                setYear(year - 1);
            }
        } else {
            setMonth(month - 1);
        }
    };

    const onCreate = async ()=>{
        const params = {
            ...form, 
            status: 'non-banking', 
            date: formatDateToMMDDYYYY(new Date(form.date)),
            excluded_banks: excludes.map(e=>e.id)
        }
        try{
            if(form.id){
                await axios.patch(`/calendar/${form.id}`, {
                    ...params,
                    approved_status: 'Pending for Approval'
                });
            }
            else{
                await axios.post('/calendar', params);
            }
            setCreateModal(false);
            setSuccess(true);
            getCalendars();
            setForm({status: 'active'});
        }catch(err){
            setError(err.response.data.errors.message)
        }
    }

    const getCalendars = ()=>{
        setCalendars([])
        let _calendars = [];
        axios.get(`/calendar/non-banking?limit=1000&year=${year}`).then(async resp=> {
            const uniqueCalendars = resp.data.reduce((acc, calendar) => {
                const existingCalendar = acc.find(c => c.name === calendar.name);
                if (!existingCalendar) {
                    return [...acc, calendar];
                }
                return acc;
            }, []);
            _calendars = uniqueCalendars;

            resp  = await axios.get(`/calendar/for-approval?limit=1000&year=${year}`)
            _calendars = [..._calendars, ...resp.data.filter(c=>new Date(c.date).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0))]
            _calendars = _calendars.filter(c=>new Date(c.date).getFullYear()===year && new Date(c.date).getMonth()===month)
            setCalendars(_calendars);
        });
    }

    const isWeekday = (date) => {
        const day = date.getDay();
        return day !== 0 && day !== 6; // 0 = Sunday, 6 = Saturday
    };

    useEffect(()=>{
        setForm(prev=>({...prev, excludes}))
    }, [excludes])

    useEffect(() => {
        setDays(getNumberOfDays(month+1, year));
        setStartDay(new Date(year, month, 1).getDay())
        axios.get('/member?filters=active&limit=1000').then(resp=>setMembers(resp.data.data));
        getCalendars()
    }, [month, year]);

    const onClickCreate = ()=>{
        setForm(prev=>({...prev, date: '', name: ''}));
        setError(null);
        setEnableExclude(false);
        setExcludes([])
        setCreateModal(true)
    }

    const Edit = ({date})=>{

        const [showEdit, setShowEdit] = useState(false);
        const menuRef = useRef(null);

        const handleClickOutside = (event) => {
          if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowEdit(false);
          }
        };

        const onEdit = ()=>{
            setError(null);
            if(false && date.excluded_banks?.length>0){
                setEnableExclude(true);
                setExcludes(members.filter(f=>date.excluded_banks && date.excluded_banks?.includes(f.id)));
            }
            setForm(prev=>{
                const _data = {
                    ...prev, date: new Date(date.date), name: date.name, id: date.id, excludes: members.filter(f=>date.excluded_banks && date.excluded_banks?.includes(f.id))
                };
                setInitialForm(_data)
                return _data;
            });
            setCreateModal(true)
            setShowEdit(false);
        }
      
        useEffect(() => {
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, []);

        return <div className="relative" ref={menuRef}>
            {new Date().setHours(0, 0, 0, 0) <= new Date(date.date).setHours(0, 0, 0, 0) && <img onClick={()=>setShowEdit(prev=>!prev)} src={Icon3Dot} className="h-[15px] cursor-pointer" alt="View"/>}
            {showEdit && <div onClick={onEdit} className="absolute left-0 bg-white top-0 shadow py-1 cursor-pointer px-3">Edit</div> }
        </div>
    }

    return <Layout>
        <div className="flex justify-between items-center">
            <div className="flex gap-3">
                <p className="text-2xl font-semibold">Calendar Management</p>
                {hasAccess('calendar-for-approval', 'approve', auth.permissions) &&  <Link to="/pending-calendar" className="p-1 px-2 text-base secondary font-bold text-white rounded-[10px] leading-[18.75px]">View Event for Approval</Link>}
            </div>
            <div className="flex items-center space-x-4 text-2xl">
                <span>{getMonthName(month)} {year}</span>
                <img src={IconPrev} onClick={decrementMonth} alt="Previous" className="cursor-pointer" />
                <img src={IconNext} onClick={incrementMonth} alt="Next" className="cursor-pointer" />
            </div>
        </div>
        {
            success && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <span className="mb-6">Event {form.id?'updated':'created'}{auth.attribute!=='super' && ` and waiting for approval`}.</span>
                        <button onClick={()=>setSuccess(false)} className="primary text-white px-4 py-2 rounded-md w-1/2 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            createModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-3 rounded-lg shadow-md max-w-md w-3/12">
                    <div className="mb-4 items-center flex flex-col">
                        <div className="flex justify-around gap-20">
                            <p className="font-bold text-xl2 mb-4 text-primary">Event</p>
                            <p className="font-bold text-xl2 mb-4">Notes</p>
                        </div>
                        <button
                            class="relative top-[-40px] right-[-160px]" onClick={()=>setCreateModal(false)}
                        >x
                        </button>
                        {error && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{error}</span>}
                        <div className="mb-4 w-full">
                            <div class="relative">
                                <input 
                                    type="text" 
                                    id="name" 
                                    name="name" 
                                    value={form.name}
                                    onChange={onChange}
                                    className="pt-6 w-full px-3 pl-4 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                                />
                                <label 
                                    for="name" 
                                    className="absolute top-3 text-xs left-4 transition-all duration-200 ease-in-out text-gray-500"
                                >
                                    Event Title
                                </label>
                            </div>
                        </div>
                        <div className="relative mb-4 w-full">
                            <div class="relative">
                                <DatePicker
                                    selected={form.date}
                                    onChange={(v)=>onChange({target: {name: 'date', value: v}})}
                                    dateFormat="MM/dd/yyyy"
                                    ref={refDate}
                                    filterDate={isWeekday}
                                    openToDate={new Date(year, month, 1)}
                                    className="pt-4 w-full px-3 py-2 outline-none focus:outline-none"
                                    customInput={<CustomInput />}
                                    minDate={new Date()}
                                    maxDate={new Date(new Date().getFullYear() + 2, 11, 31)}
                                />
                                <label 
                                    for="start_date" 
                                    className="absolute top-1 text-xs left-3 transition-all duration-200 ease-in-out text-gray-500"
                                >
                                    Date
                                </label>
                            </div>
                        </div>
                        <div className="hidden flex mb-4 justify-start w-full">
                            <img onClick={()=>setEnableExclude(prev=>!prev)} src={enableExclude ? IconActive : IconActiveOff} alt="Active" className="cursor-pointer" />
                            <span className="pl-2">Exclude Bank</span>
                        </div>
                        {
                            enableExclude && <>
                                <div className="relative w-full mb-4">
                                    <div className="w-full">
                                        <div class="relative">
                                            <input value={form.search} name="search" onChange={onChange} type="text" class="text-sm w-full border border-gray-300 rounded-md py-2 pl-10 pr-4" placeholder="Search Bank Here"/>
                                            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                <img src={IconSearch} alt="Search" className="cursor-pointer w-[80%]" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {filteredOptions.length > 0 && (
                                    <ul className=" w-full mt-[-16px] bg-white border border-gray-300 rounded-md shadow-lg">
                                    {filteredOptions.map((o, index) => (
                                        <li
                                        key={index}
                                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                        onClick={() => {
                                            setExcludes(prev=>([...prev, o]));
                                            setFilteredOptions([]);
                                            setForm(prev=>({...prev, search: ''}));
                                        }}
                                        >
                                        {o.bank_name}
                                        </li>
                                    ))}
                                    </ul>
                                )}
                                {
                                    excludes.map((o, index) => (
                                        <div className="flex justify-between relative w-full mb-4 capitalize">
                                            {o.bank_name}
                                            <span className="text-[#4A9952] flex gap-1 w-1/5">
                                                <img src={IconDelete} alt="Delete" className="w-[17px] h-[21px]"/>
                                                <span onClick={()=>setExcludes(prev=>prev.filter((p, i)=>i!==index))} className="cursor-pointer">Delete</span>
                                            </span>
                                        </div>
                                    ))
                                }
                            </>
                        }
                        <button disabled={(form.id && Object.keys(objectDiff(initialForm, form)).length === 0) || !form.name || !form.date || (enableExclude && excludes.length === 0)} onClick={onCreate} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Save{form.id && ' Changes'}</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        <div className="w-full mt-5 bg-white rounded p-3">
            <div className="flex justify-around">
                <div className="w-1/4 mr-4">
                    {hasAccess('calendar-all', 'create', auth.permissions) && <button onClick={onClickCreate} className="primary text-white px-4 py-2 rounded-md w-full block mb-5">Create</button>}
                    <div className="flex justify-between items-center mb-4">
                        <div>
                            <img onClick={decrementMonth} src={IconPrev} alt="Previous" className="cursor-pointer" />
                        </div>
                        <div className="font-bold">{getMonthName(month)}</div>
                        <div>
                            <img onClick={incrementMonth} src={IconNext} alt="Next" className="cursor-pointer" />
                        </div>
                    </div>
                    <div class="flex justify-between mb-2 text-[10px] leading-[12px]">
                        <div class="calendar-box text-center font-normal">SUN</div>
                        <div class="calendar-box text-center font-normal">MON</div>
                        <div class="calendar-box text-center font-normal">TUE</div>
                        <div class="calendar-box text-center font-normal">WED</div>
                        <div class="calendar-box text-center font-normal">THU</div>
                        <div class="calendar-box text-center font-normal">FRI</div>
                        <div class="calendar-box text-center font-normal">SAT</div>
                    </div>
                    <div className="flex flex-wrap justify-start calendar-mini">
                        {
                            new Array(startDay).fill(undefined).map(i=> <div className="calendar-box"></div>)
                        }
                        {
                            new Array(days).fill(undefined).map((i, index) => <div key={index} className="calendar-box text-center h-10">
                                <span className={`${new Date().getFullYear()===year && new Date().getMonth()===month && new Date().getDate()===index+1 ? 'bg-black rounded-full p-2 px-3 text-white' : ''}`}>{index+1}</span>
                            </div>)
                        }
                    </div>
                    <div>
                        {calendars.filter(c=>c.approved_status==='approved').length > 0 && <div onClick={()=>setShowApproved(prev=>!prev)} className="cursor-pointer font-bold px-1 mb-2 flex justify-between">
                            <span>Approved Events</span>
                            {showApproved && <img src={IconDown} alt="Right" className="w-[10px] h-[9px] mt-1"/>}
                            {!showApproved && <img src={IconRight} alt="Right" className="w-[10px] h-[9px] mt-1"/>}
                        </div>
                        }
                        {showApproved && calendars.filter(c=>c.approved_status==='approved').sort((a, b) => new Date(a.date) - new Date(b.date)).map((c, index) => (
                            <div className="flex justify-between mb-2 px-1">
                                <div className="flex gap-2 capitalize">
                                    <img src={IconCheckRed} className="w-[20px] h-[21px]" alt="Event"/>                                    
                                    {c.name}
                                </div>
                                <Edit date={c}/>
                            </div>
                        ))}
                    </div>
                    <div className="mt-3">
                        {calendars.filter(c=>c.approved_status!=='approved').length > 0 && <div onClick={()=>setShowPending(prev=>!prev)} className="cursor-pointer font-bold px-1 mb-2 flex justify-between">
                            <span>Pending Event</span>
                            {showPending && <img src={IconDown} alt="Right" className="w-[10px] h-[9px] mt-1"/>}
                            {!showPending && <img src={IconRight} alt="Right" className="w-[10px] h-[9px] mt-1"/>}
                        </div>
                        }
                        {showPending && calendars.filter(c=>c.approved_status!=='approved').sort((a, b) => new Date(a.date) - new Date(b.date)).map((c, index) => (
                            <div className="flex justify-between mb-2 px-1">
                                <div className="flex gap-2 capitalize">
                                    <img src={IconCheckRed} className="w-[20px] h-[21px]" alt="Event"/>                                    
                                    {c.name}
                                </div>
                                <Edit date={c}/>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex-1 mb-10">
                    <ul className="flex justify-between w-full mt-2 leading-[15.23px] font-normal text-[13px] mb-3">
                        <li className="flex-grow text-center">SUN</li>
                        <li className="flex-grow text-center">MON</li>
                        <li className="flex-grow text-center">TUE</li>
                        <li className="flex-grow text-center">WED</li>
                        <li className="flex-grow text-center">THU</li>
                        <li className="flex-grow text-center">FRI</li>
                        <li className="flex-grow text-center">SAT</li>
                    </ul>
                    <div className="flex flex-wrap justify-start calendar-big">
                        {
                            new Array(startDay).fill(undefined).map(i=> <div className="calendar-box border"></div>)
                        }
                        {
                            new Array(days).fill(undefined).map((i, index) => <div key={index} className="border calendar-box pl-2 pt-3 overflow-y-auto h-[115px] text-center">
                                <span className={`${new Date().getFullYear()===year && new Date().getMonth()===month && new Date().getDate()===index+1 ? 'bg-black rounded-full p-2 px-3 text-white ml-3' : ''}`}>{index+1}</span>
                                {
                                    isHoliday(new Date(year, month, index+1)).map(h => (
                                        <div className="flex justify-between text-white bg-[#4A9952] mb-1 rounded w-[95%] overflow-auto px-1">
                                            {h.event}
                                        </div>
                                    ))
                                }
                                {
                                    calendars.filter(c=>new Date(c.date).getFullYear()===year && new Date(c.date).getMonth()===month && new Date(c.date).getDate()===index+1).map((c, index) => (
                                        <>
                                            <div className={` text-white  mb-1 rounded w-[95%] overflow-auto px-1 capitalize ${c.approved_status!=='approved'?'bg-[#B4B4B4]':'bg-[#ff604a]'}`}>
                                                {c.name}
                                            </div>
                                            {c.approved_status!=='approved' && <div className=" text-[#FF604B] font-normal text-xs leading-[14.06px]">Waiting for Approval</div>}
                                        </>
                                    ))
                                }
                            </div>)
                        }
                    </div>
                </div>
            </div>
        </div>
    </Layout>
}