import './Landing.css';
import { useEffect, useState } from 'react';
import { axios } from '../utils';
import { useNavigate } from 'react-router-dom';

export default function Landing({children}) {

  const navigate = useNavigate();

  useEffect(()=>{
      axios.get('/auth/me').catch(err=>navigate('/login'));
  }, [])

  return <div className="landing">{children}</div>
}