import { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import Layout from "../layouts/Dashboard";
import Modal from "./Modal";
import IconSuccess from "../assets/icon-success.png";
import { axios, formatStatus } from "../utils";
import { AuthContext } from "../AuthContext";

export default function PendingMembers() {

    const {auth} = useContext(AuthContext);
    const [exportSuccess, setExportSuccess] = useState(false);
    const [rows, setRows] = useState([]);
    const [uploadModal, setUploadModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadedFileName, setUploadedFileName] = useState('');
    const [errorUpload, setErrorUpload] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [error, setError] = useState(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get('tab');
    const [selected, setSelected] = useState(tab || "internal");

    const onUpload = ()=>{
        setErrorUpload('');
        setSelectedFile('')
        setUploadedFileName('')
        setUploadModal(true);
    }

    const onClickUpload = () => {
        const formData = new FormData();
        formData.append('file', selectedFile);
        axios.post('/mandates/util/import', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
        }).then(()=>{
            setUploadSuccess(true);
            setUploadModal(false);
        }).catch((e)=>{
            setErrorUpload(e.response?.data?.message || e.message)
        })
    }
  
    const handleFileChange = (event) => {
        setErrorUpload(null);
        const selectedFile = event.target.files[0];
        const fileType = selectedFile.name.split('.').pop().toLowerCase();
        if (!['xls', 'xlsx'].includes(fileType)) {
            setErrorUpload('Invalid file format.');
            return;
        }
        setSelectedFile(selectedFile);
        setUploadedFileName(selectedFile.name);
    };

    const onLoad = ()=>{
        const sort = JSON.stringify([{orderBy: "createdAt", order: 'desc'}]);
        axios.get(`/mandates?limit=1000&status=Pending Confirmation|Pending for Approval|Pending for Acceptance&sort=${encodeURIComponent(sort)}`).then(resp=>{
            const _rows = resp.data.data.filter(r=>{
                if(selected === 'internal'){
                    return (r.status==='Pending for Approval' && r.created_by !== auth.id)
                }else if(selected === 'external'){
                    return (r.status==='Pending for Acceptance' && r.bank_id===auth.member_id && r.updated_by !== auth.id && r.created_by !== auth.id)
                }
            })
            setRows(_rows)
        })
    }

    useEffect(()=>{
        if(auth.member_id){
            onLoad();
        }
    }, [auth, selected])

    const onClickExport = ()=>{
        setError('')
        axios.post(`/mandates/util/export`, {status: 'Pending for Approval|Pending for Acceptance'})
            .then(()=>{
                setExportSuccess(true);
            }).catch((e)=>{
                setError(e.response?.data?.errors ? Object.values(e.response.data.errors)[0] : e.message);
            });
    }

  return <Layout>
        <div className="flex justify-between items-center">
            <p className="text-2xl font-bold">List of Mandate for Approval</p>
            <div className="flex items-center space-x-4">
                <Link to="/mandate-management" className="px-4 py-2 secondary text-white rounded-md">Back</Link>
                <button disabled={rows.length===0} onClick={onClickExport} className="px-4 py-2 primary text-white rounded-md font-bold h-[43px]">Export</button>
                <button disabled={rows.length===0} onClick={onUpload} className="px-4 py-2 primary text-white rounded-md font-bold h-[43px]">Upload</button>
            </div>
        </div>
        {
            exportSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4 text-center">Your requested file has been<br/>sent to your email</p>
                        <button onClick={()=>setExportSuccess(false)} className="primary text-white px-4 py-2 rounded-md w-3/12 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            uploadSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4 text-center">File successfully uploaded.</p>
                        <button onClick={()=>{setUploadSuccess(false);onLoad()}} className="primary text-white px-4 py-2 rounded-md w-3/12 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            uploadModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white rounded-lg shadow-md max-w-md w-3/12 p-5">
                    
                    <div className="mb-4 flex items-center gap-4 flex-col">
                        <div className="flex">
                            <p className="font-bold text-xl2">Upload File</p>
                            <div onClick={()=>setUploadModal(false)} className="relative right-[-120px] top-[-15px] cursor-pointer">x</div>
                        </div>
                        <div className=" border border-dotted flex flex-col w-full items-center gap-2 p-5">
                            {!uploadedFileName && <>
                                <span className="text-gray-500 text-xs">Drop file here or</span>
                                <label htmlFor="fileInput" className="w-1/2 secondary border border-gray-300 text-center py-2 rounded-md cursor-pointer">
                                Excel File
                                </label>
                                <input
                                id="fileInput"
                                type="file"
                                className="hidden"
                                onChange={handleFileChange}
                                accept=".xls, .xlsx"
                                />
                            </>}
                            {errorUpload && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{errorUpload}</span>}
                            {!errorUpload && selectedFile && (
                            <span className="text-gray-500">{uploadedFileName}</span>
                            )}

                        </div>
                        <button onClick={onClickUpload} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Upload</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {error && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{error}</span>}
        <div className="flex flex-col">
            <div className="flex px-3 pl-0 gap-1">
                <div onClick={()=>setSelected('internal')} className={`pb-3 cursor-pointer font-bold p-3 rounded-t ${selected==='internal' ? 'bg-white text-primary' : 'bg-[#f0f0f1]'}`}>Acquiring Bank</div>
                <div onClick={()=>setSelected('external')} className={`pb-3 cursor-pointer font-bold p-3 rounded-t ${selected==='external' ? 'bg-white text-primary' : 'bg-[#f0f0f1]'}`}>Issuing Bank</div>
            </div>
        </div>
        {
            rows.length === 0 && <div className="text-center bg-white p-5 block text-sm font-semibold">No Pending Mandate for Approval</div>
        }
        {rows.length > 0 && <div className="w-full bg-white rounded p-3">
            <div className="mb-10">
                <table className="table-auto  w-full">
                    <thead>
                    <tr className="border-b">
                        <th className="py-2 text-left" width="20%">Mandate ID</th>
                        <th className="py-2 text-left" width="25%">Biller Name</th>
                        <th className="py-2 text-left" width="20%">Created By</th>
                        <th className="py-2 text-left" width="20%">Status</th>
                        <th className="py-2 text-left" width="15%">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        rows.map((r)=><tr className="border-b border-gray-200 py-10">
                            <td className="text-base font-normal leading-[18.75px]">{r.mandate_id}</td>
                            <td className="text-base font-normal leading-[18.75px]">{r.billerByName}</td>
                            <td className="text-base font-normal leading-[18.75px]">{r.createdByName}</td>
                            <td className="py-2 flex gap-1 justify-start text-base items-center leading-[18.75px] font-normal">{formatStatus(r.status)}</td>
                            <td className="text-left text-baseleading-[18.75px]"><Link to={`/approve-mandate?id=${r.id}&tab=${selected}`}  className="px-4 py-2 primary text-white rounded-md">View and Approve</Link></td>
                        </tr>)
                    }
                    </tbody>
                </table>
            </div>
        </div>}
  </Layout>;
}