import Layout from "../layouts/Dashboard";
import { UserProvider } from './UserContext';
import PermissionForm from "./PermissionForm";
import { Link, useLocation } from "react-router-dom";

export default function ApproveUser() {

    const setUser = ()=>null;
    const setPage = ()=>null;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const back = queryParams.get('back');

  return <Layout>
    <div className="flex justify-between items-center">
        <p className="text-2xl font-semibold">View and Approve</p>
        <div className="flex items-center space-x-4">
            <Link to={back? `/${back}` : '/access-management'} className="px-4 py-2 secondary text-white rounded-md">Back</Link>
        </div>
    </div>
    <div className="w-full bg-white rounded p-3 mt-5 pb-6">
        <UserProvider>
            <PermissionForm isApprove isEmployee {...{setUser, setPage, id}} />
        </UserProvider>
    </div>
  </Layout>;
}