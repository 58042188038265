import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Layout from "../layouts/Dashboard";
import Modal from "./Modal";
import IconSuccess from "../assets/icon-success.png";
import { axios, formatDate } from "../utils";
import { AuthContext } from "../AuthContext";

export default function PendingMembers() {

    const {auth} = useContext(AuthContext);
    const [exportSuccess, setExportSuccess] = useState(false);
    const [rows, setRows] = useState([]);
    const [error, setError] = useState(null);
    const [uploadModal, setUploadModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadedFileName, setUploadedFileName] = useState('');
    const [errorUpload, setErrorUpload] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);

    const onUpload = ()=>{
        setErrorUpload('');
        setSelectedFile('')
        setUploadedFileName('')
        setUploadModal(true);
    }
    const onClickUpload = () => {
        const formData = new FormData();
        formData.append('file', selectedFile);
        axios.post('/users/util/import', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
        }).then(()=>{
            setUploadSuccess(true);
            setUploadModal(false);
        }).catch((e)=>{
            setErrorUpload(e.response?.data?.message || e.message)
        })
    }
  
    const handleFileChange = (event) => {
      setSelectedFile(event.target.files[0]);
      setUploadedFileName(event.target.files[0].name);
    };

    const onLoad = async ()=>{
        if(!auth.id) return;
        setError('')
        try{
            const resp = await axios.get(`/users?role=employee&limit=1000&status=Pending for Approval`)
            setRows(resp.data.data.filter(u=>u.created_by !== auth.id))
        }catch(e){
            setError(e.response?.data?.message || e.message)
        }
    }
    const onExport = ()=>{
        setError('')
        axios.post("/users/util/export", {filters: 'Pending for Approval', limit: 1000, role: 'employee'})
            .then(()=>{
                setExportSuccess(true);
            }).catch((e)=>{
                setError(e.response?.data?.errors ? Object.values(e.response.data.errors)[0] : e.message);
            });
    }

    useEffect(()=>{
        onLoad();
    }, [auth]);

  return <Layout>
        <div className="flex justify-between items-center">
            <p className="text-2xl font-semibold leading-[28.13px]">List of Employee for Approval</p>
            <div className="flex items-center space-x-4 leading-[18.75px text-base font-bold">
                <Link to="/access-management" className="text-base font-bold leading-[18.75px] px-4 py-2 secondary text-white rounded-md">Back</Link>
                <button disabled={rows.length===0} onClick={onExport} className="text-base font-bold leading-[18.75px] px-4 py-2 primary text-white rounded-md">Export</button>
                <button disabled={rows.length===0} onClick={onUpload} className="text-base font-bold leading-[18.75px] px-4 py-2 primary text-white rounded-md">Upload</button>
            </div>
        </div>
        {
            uploadSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4 text-center">File successfully uploaded.</p>
                        <button onClick={async()=>{setUploadSuccess(false);await onLoad();}} className="primary text-white px-4 py-2 rounded-md w-3/12 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            uploadModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white rounded-lg shadow-md max-w-md w-3/12 p-5">
                    
                    <div className="mb-4 flex items-center gap-4 flex-col">
                        <div className="flex">
                            <p className="font-bold text-xl2">Upload File</p>
                            <div onClick={()=>setUploadModal(false)} className="relative right-[-120px] top-[-15px] cursor-pointer">x</div>
                        </div>
                        <div className=" border border-dotted flex flex-col w-full items-center gap-2 p-5">
                            {!uploadedFileName && <>
                                <span className="text-gray-500 text-xs">Drop file here or</span>
                                <label htmlFor="fileInput" className="w-1/2 secondary border border-gray-300 text-center py-2 rounded-md cursor-pointer">
                                Excel File
                                </label>
                                <input
                                id="fileInput"
                                type="file"
                                className="hidden"
                                onChange={handleFileChange}
                                />
                            </>}
                            {errorUpload && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{errorUpload}</span>}
                            {!errorUpload && selectedFile && (
                            <span className="text-gray-500">{uploadedFileName}</span>
                            )}

                        </div>
                        <button onClick={onClickUpload} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Upload</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {error && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{error}</span>}
        {
            exportSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4 text-center">Your requested file has been<br/>sent to your email</p>
                        <button onClick={()=>setExportSuccess(false)} className="primary text-white px-4 py-2 rounded-md w-3/12 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            rows.length === 0 && <div className="text-center bg-white p-5 mt-5 block text-sm font-semibold">No Pending Employee for Approval</div>
        }
        {rows.length > 0 && <div className="w-full mt-5 border rounded p-3">
            <div className="overflow-x-auto mb-10">
                <table className="table-auto border-collapse w-full">
                    <thead>
                    <tr className="border-b text-lg">
                        <th className="py-2 font-medium leading-[21.09px] text-left">Email</th>
                        <th className="py-2 font-medium leading-[21.09px] text-left">Name</th>
                        <th className="py-2 font-medium leading-[21.09px] text-left">Role</th>
                        <th className="py-2 font-medium leading-[21.09px] text-left">Date Created</th>
                        <th className="py-2 font-medium leading-[21.09px] text-left">Created By</th>
                        <th className="py-2 font-medium leading-[21.09px] text-left">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        rows.map((r)=><tr className="border-b border-gray-200 leading-[18.75px] font-normal text-base">
                            <td className="py-4 text-left">{r.email}</td>
                            <td className="py-4 text-left">{r.firstName} {r.lastName}</td>
                            <td className="py-4 text-left">{r.role}</td>
                            <td className="py-4 text-left">{formatDate(r.createdAt)}</td>
                            <td className="py-4 text-left">{r.createdByName}</td>
                            <td className="py-4 text-left" width="150"><Link to={`/approve-employee?id=${r.id}&back=pending-employee`}  className="py-2 primary text-white rounded-md block text-center">View and Approve</Link></td>
                        </tr>)
                    }
                    </tbody>
                </table>
            </div>
        </div>}
  </Layout>;
}