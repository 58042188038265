import Layout from "../layouts/Dashboard";
import {Link, useNavigate, useLocation} from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../AuthContext";
import { axios, hasAccess } from "../utils";
import Modal from "./Modal";
import IconSuccess from "../assets/icon-success.png";

export default function ApproveBiller() {

    const {auth} = useContext(AuthContext);
    const navigate = useNavigate();
    const [form, setForm] = useState({});
    const [approvedModal, setApprovedModal] = useState();
    const [rejectModal, setRejectModal] = useState();
    const [rejectReason, setRejectReason] = useState('');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const back = queryParams.get('back');
    const [rejectSuccess, setRejectSuccess] = useState();

    const onApprove = async(approved)=>{
        if(approved){
            await axios.patch(`/billers/${id}`, {status: 'approved'})
            await axios.post(`/billers/${id}/enroll`, {member_id: auth.member_id})
            setApprovedModal(true)
        }else{
            setRejectModal(true);
        }
    }

    const onReject = async()=>{
        await axios.patch(`/billers/${id}`, {status: 'Rejected', reject_reason: rejectReason})
        setRejectSuccess(true);
    }
    
    useEffect(()=>{
        if(!hasAccess('biller-management-roles', 'approver', auth.permissions)){
            navigate('/biller-management');
        }
        axios.get(`/billers/${id}`).then(resp=>{
            setForm(resp.data);
        });
    }, []);

  return <Layout>
        <div className="flex justify-between items-center">
            <p className="text-2xl font-semibold">View and Approve</p>
            <div className="flex items-center space-x-4">
                <Link to={`${back ? `/${back}` : '/biller-management'}`} className="px-4 py-2 secondary text-white rounded-md">Back</Link>
            </div>
        </div>
        {
            rejectModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    <div className="bg-white px-8 py-4 rounded-lg shadow-md">
                        <div onClick={()=>setRejectModal(false)} className='text-end w-full right-0 cursor-pointer text-black'>x</div>
                        <div className="mb-4 items-center flex flex-col">
                            <div className="font-semibold text-2xl">Reject</div>
                            <div className="mt-[15px] mb-[20px] font-normal"><span className="capitalize">{form.biller_name}</span> has been rejected due to</div>
                            <input className="rounded mb-[17px] w-[451px] h-[54px] border" type="text" value={rejectReason} onChange={(e)=>setRejectReason(e.target.value)} placeholder="Please add reason for rejection" />
                            <button onClick={onReject} disabled={!rejectReason} className="disabled:opacity-[0.5] primary text-white px-4 py-2 rounded-md w-[136px] h-[54px] block">Reject</button>
                        </div>
                    </div>
                </div>
            </Modal>
        }
        {
            rejectSuccess && <Modal onClose={()=>setRejectSuccess(false)}>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <div className="font-semibold text-2xl leading-[28.13px] mb-[20px]">Rejection Successful!</div>
                        <div className="text-xl2 mb-6 text-center">
                            You have successfully rejected a biller.
                        </div>
                        <button onClick={()=>navigate('/pending-billers')} className="primary text-white px-4 py-2 rounded-md w-[97px] h-[54px] block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            approvedModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4 text-center">Approval Successful!</p>
                        <p className="text-xl2 mb-4 text-center">You successfully approved a biller</p>
                        <button onClick={()=>navigate('/pending-billers')} className="primary text-white px-4 py-2 rounded-md w-3/12 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
    <div className="bg-white w-full  mt-5 rounded p-10 flex flex-col items-center">
        <div className="w-1/2 flex justify-between mb-4">
            <div>
                <div className="label text-xs">Biller Name</div>
                <span className="font-bold">{form.biller_name}</span>
            </div>
            <div>
                <div className="label text-xs">Biller TIN Number</div>
                <span className="font-bold">{form.biller_tin_number}</span>
            </div>
            <div>
                <div className="label text-xs">Branch Code</div>
                <span className="font-bold">{form.branch_code}</span>
            </div>
        </div>
        <div className="w-1/2 flex justify-between mb-4 flex-col gap-3">
            <button onClick={()=>onApprove(true)} className="px-4 py-2 w-full primary text-white rounded-md">Approve</button>
            <button onClick={()=>onApprove(false)} className="block mt-3 w-full text-center px-4 py-2 secondary text-white rounded-md">Reject</button>
        </div>
    </div>
  </Layout>;
}