import { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import Layout from "../layouts/Dashboard";
import Modal from "./Modal";
import IconSuccess from "../assets/icon-success.png";
import { axios, formatDate, formatStatus } from "../utils";
import { AuthContext } from "../AuthContext";

export default function PendingMembers() {

    const {auth} = useContext(AuthContext);
    const [exportSuccess, setExportSuccess] = useState(false);
    const [rows, setRows] = useState([]);
    const [error, setError] = useState(null);
    const [uploadModal, setUploadModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadedFileName, setUploadedFileName] = useState('');
    const [errorUpload, setErrorUpload] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('type');

    const onClickExport = ()=>{
        setError('');
        const param = auth.member_id ? {member_id: auth.member_id} :{};
        const role = auth.member_id ? 'employee' : '';
        axios.post('/users/util/export', {status:'Pending for Approval', role, type, ...param})
            .then(()=>{
                setExportSuccess(true)
            }).catch((e)=>{
                setError(e.response?.data?.message || e.message);
            });
    }

    const onUpload = ()=>{
        setErrorUpload('');
        setSelectedFile('')
        setUploadedFileName('')
        setUploadModal(true);
    }

    const onClickUpload = () => {
        const formData = new FormData();
        formData.append('file', selectedFile);
        axios.post('/users/util/import', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
        }).then(()=>{
            setUploadSuccess(true);
            setUploadModal(false);
        }).catch((e)=>{
            setErrorUpload(e.response?.data?.message || e.message)
        })
    }
  
    const handleFileChange = (event) => {
        setErrorUpload(null);
        const selectedFile = event.target.files[0];
        const fileType = selectedFile.name.split('.').pop().toLowerCase();
        if (!['xls', 'xlsx'].includes(fileType)) {
            setErrorUpload('Invalid file format.');
            return;
        }
        setSelectedFile(selectedFile);
        setUploadedFileName(selectedFile.name);
    };

    const onLoad = async ()=>{
        setError('')
        setRows([])
        try{
            const sort = JSON.stringify([{orderBy: "updatedAt", order: 'desc'}]);
            const param = auth.member_id ? `&member_id=${auth.member_id}` : '';
            const role = auth.member_id ? 'employee' : '';
            const resp = await axios.get(`/users?type=${type}&role=${role}&limit=1000${param}&status=Pending for Approval|Pending Approval for Deletion|Pending Approval for Cancellation&sort=${encodeURIComponent(sort)}`)
            setRows(resp.data.data.filter(row=>(row.created_by !== auth.id && row.updated_by!==auth.id) || (row.updated_by && row.created_by === auth.id && row.updated_by!==auth.id) ))
        }catch(e){
            setError(e.response?.data?.message || e.message)
        }
    }

    useEffect(()=>{
        if(auth.id){
            onLoad();
        }
    }, [auth]);

  return <Layout>
        <div className="flex justify-between items-center">
            <p className="text-2xl font-semibold leading-[28.13px]">List of {type==='bank'?'Bank Admin':'User'} for Approval</p>
            <div className="flex items-center space-x-4">
                <Link to={`/access-management?type=${type}`} className="px-4 py-2 secondary text-white rounded-md text-base font-bold leading-[18.75px]">Back</Link>
                <button disabled={rows.length === 0} onClick={onClickExport} className="px-4 py-2 primary text-white rounded-md text-base font-bold leading-[18.75px]">Export</button>
                <button disabled={rows.length === 0} onClick={onUpload} className="px-4 py-2 primary text-white rounded-md text-base font-bold leading-[18.75px]">Upload</button>
            </div>
        </div>
        {
            uploadSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4 text-center">File successfully uploaded.</p>
                        <button onClick={async()=>{setUploadSuccess(false); await onLoad();}} className="primary text-white px-4 py-2 rounded-md w-3/12 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            uploadModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white rounded-lg shadow-md max-w-md w-3/12 p-5">
                    
                    <div className="mb-4 flex items-center gap-4 flex-col">
                        <div className="flex">
                            <p className="font-bold text-xl2">Upload File</p>
                            <div onClick={()=>setUploadModal(false)} className="relative right-[-120px] top-[-15px] cursor-pointer">x</div>
                        </div>
                        <div className=" border border-dotted flex flex-col w-full items-center gap-2 p-5">
                            {!uploadedFileName && <>
                                <span className="text-gray-500 text-xs">Drop file here or</span>
                                <label htmlFor="fileInput" className="w-1/2 secondary border border-gray-300 text-center py-2 rounded-md cursor-pointer">
                                Excel File
                                </label>
                                <input
                                id="fileInput"
                                type="file"
                                className="hidden"
                                onChange={handleFileChange}
                                accept=".xlsx, .xls"
                                />
                            </>}
                            {errorUpload && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{errorUpload}</span>}
                            {!errorUpload && selectedFile && (
                            <span className="text-gray-500">{uploadedFileName}</span>
                            )}

                        </div>
                        <button disabled={!selectedFile} onClick={onClickUpload} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Upload</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            exportSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4 text-center">Your requested file has been<br/>sent to your email</p>
                        <button onClick={()=>setExportSuccess(false)} className="primary text-white px-4 py-2 rounded-md w-3/12 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            rows.length === 0 && <div className="text-center bg-white p-5 mt-5 block text-sm font-semibold">No Pending User for Approval</div>
        }
        { rows.length > 0 && <div className="w-full mt-5 bg-white rounded p-3">
            <div className="overflow-x-auto mb-10">
                {error && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{error}</span>}
                <table className="table-auto border-collapse w-full">
                    <thead>
                    <tr className="border-b">
                        <th className="py-2 text-left text-lg font-medium leading[21.09px]" width="16%">Email</th>
                        <th className="py-2 text-left text-lg font-medium leading[21.09px]" width="16%">Name</th>
                        {type==='bank' && <th className="py-2 text-left text-lg font-medium leading[21.09px]" width="16%">Bank Name</th>}
                        <th className="py-2 text-left text-lg font-medium leading[21.09px]" width="16%">Date Created</th>
                        <th className="py-2 text-left text-lg font-medium leading[21.09px]" width="16%">Created By</th>
                        <th className="py-2 text-left text-lg font-medium leading[21.09px]" width="16%">Status</th>
                        <th className="py-2 text-left text-lg font-medium leading[21.09px]" width="16%">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                        {
                            rows.map((row, i)=>(
                                <tr key={i} className="border-b">
                                    <td className="py-3 text-left font-normal text-base leading-[18.75px]" width="16%">{row.email}</td>
                                    <td className="py-3 text-left font-normal text-base leading-[18.75px]" width="16%">{row.firstName} {row.lastName}</td>
                                    {type==='bank' && <td className="py-3 text-left font-normal text-base leading-[18.75px]" width="16%">{row.bankName}</td>}
                                    <td className="py-3 text-left font-normal text-base leading-[18.75px]" width="16%">{formatDate(row.createdAt)}</td>
                                    <td className="py-3 text-left font-normal text-base leading-[18.75px]" width="16%">{row.createdByName}</td>
                                    <td className="py-3 text-left font-normal text-base leading-[18.75px] flex gap-1 justify-left items-center w-full" width="16%">{formatStatus(row.status)}</td>
                                    <td className="text-left py-3" width="16%">
                                        <Link to={type === 'bank' ? `/approve-admin?id=${row.id}&back=pending-users`:`/approve-user?id=${row.id}&type=${type}&back=pending-users`}  className="px-4 py-2 primary text-white rounded-md block text-center">
                                            View {auth.id!==row.created_by && 'and Approve'}
                                        </Link>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>}
  </Layout>;
}