import {useState, useContext} from "react";
import Layout from "../layouts/Dashboard";
import { UserProvider } from './UserContext';
import UserForm from "./UserForm";
import { axios, hasAccess } from "../utils";
import IconSuccess from "../assets/icon-success.png";
import Modal from "./Modal";
import PermissionForm from "./PermissionForm";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { AuthContext } from "../AuthContext";
import IconClock from "../assets/icon-clock.png";

export default function AddUser(){

    const [user, setUser] = useState({});
    const [error, setError] = useState();
    const [page, setPage] = useState('user-form');
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const {auth} = useContext(AuthContext);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('type') || 'internal';
    const id = queryParams.get('id');

    const onAddUser = async ()=>{
        setError('');
        try{
            const name = user.name.split(" ");
            const params = {
                ...user, 
                firstName: name[0],
                lastName: name[1],
                permissions: JSON.stringify(user.permissions),
                role: user.role || auth.role,
                status: (id && auth.attribute === 'super') ? 'approved' : 'Pending for Approval',
            };
            if(user.id) await axios.patch(`/users/${user.id}`, {
                ...params, 
                new_permissions: user.permissions,
                previous_status: user.status
            });
            else await axios.post("/users", params);
            setSuccess(true);
        }catch(e){
            setError(e.response?.data?.errors ? Object.values(e.response.data.errors)[0] : e.message);
        }
    }
    const onSuccessOK = ()=>{
        setSuccess(false);
        setPage('user-form')
        navigate(`/access-management?type=${type}`)
    }

    return <Layout>
        <div className="flex justify-between items-center">
            <p className="text-2xl font-bold">
                {page==='user-form' ? `${id ? 'Edit': 'Add'} User` : 'Roles & Permissions'}
            </p>
            <div className="flex items-center space-x-4">
                {page==='user-form' && <Link to="/access-management?type=internal" className="px-4 py-2 secondary text-white rounded-md">Back</Link>}
                {page==='permission-form' && <>
                    {!user.id && <button onClick={()=>setPage('user-form')} className="px-4 py-2 secondary text-white rounded-md">Back</button>}
                    {user.id && <Link reloadDocument={user.id} to={`/user-form?id=${user.id}`} className="px-4 py-2 secondary text-white rounded-md">Back</Link>}
                    {user.id && <Link to={`/access-history?id=${user.id}&type=internal`} className="primary text-white px-4 py-2 rounded-md flex justify-center gap-2"><img src={IconClock} width={22} alt="View History Icon"/> <span className="pt-[1px]">View History</span></Link>}
                    <button disabled={user.status==='Cancelled' || user.status==='Pending Approval for Cancellation' || (!user.id && !hasAccess('access-all', 'create', auth.permissions)) || (user.id && user.status==='Pending for Approval') || (user.id && !hasAccess('access-all', 'edit', auth.permissions))} onClick={onAddUser} className="primary text-white px-4 py-2 rounded-md block disabled:opacity-50">{user.id ? 'Save' : 'Add User'}</button>
                </>
                }
            </div>
        </div>
        {
            success && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4">{user.id ? 'User Updated' : 'New User Added'}</p>
                        <span className="mb-6">You successfully {user.id ? 'updated a':'added a new'} user.</span>
                        <button onClick={onSuccessOK} className="primary text-white px-4 py-2 rounded-md w-1/2 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        <UserProvider>
            <div className="w-full bg-white rounded p-3 mt-5 pb-6">
                {error && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{error}</span>}
                <div className="flex flex-col items-center ">
                        {page==='user-form' && <UserForm {...{setPage}} />}
                </div>
                {page==='permission-form' && <PermissionForm {...{setPage, setUser}} />}
            </div>
            
        </UserProvider>
    </Layout>
}