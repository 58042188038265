import {useContext, useEffect, useState} from "react";
import { UserContext } from './UserContext';
import {useLocation, useNavigate} from 'react-router-dom';
import { axios, hasAccess } from "../utils";
import { AuthContext } from "../AuthContext";

export default function Form({setPage}){

    const { user, updateUser } = useContext(UserContext);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const page = queryParams.get('page');
    const {auth} = useContext(AuthContext);

    useEffect(()=>{
        if(id){
            axios.get(`/users/${id}`).then(resp=>{
                const data = resp.data;
                let permissions = {};
                try{
                    permissions = JSON.parse(data.permissions);
                    permissions = Object.keys(permissions).reduce((acc, key)=>(isNaN(key))?{...acc, [key]: permissions[key]}: acc, {});
                }catch(e){
                }
                const name = data.firstName + ' ' + data.lastName;
                updateUser({...data, permissions, name})
                if(page) setPage(page);
            })
        }
    }, [])

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleChange = (e) => {
        let {name, value} = e.target;
        if(name==='name'){
            value = value.trimStart().replace(/[^a-zA-Z ]/g, '');
            const [firstName, ...lastName] = value.split(' ');
            updateUser(prev=>({...prev, [name]: value, firstName, lastName: lastName.join(' ')}));
        }else{
            value = value.trim();
            updateUser(prev=>({...prev, [name]: value}));
        }
    }
    
    return <>{
                user.status==='Rejected' && <>
                <div className="flex items-start flex-col mb-4 w-3/6">
                    <div className="font-bold">Status: <span className="text-red-500">Rejected</span></div>
                    <div className="font-bold">Reason for rejection: <span className="font-normal">{user.reject_reason}</span></div>
                </div>
            </>
            }
            <div className={`mb-4 w-3/6 ${user.id && 'disabled pointer-events-none'}`}>
                <div class="relative input-container">
                    <input 
                        onChange={handleChange}
                        type="text" 
                        id="email" 
                        name="email" 
                        value={user.email}
                        className={`pt-4 w-full pl-[10px] py-2 border border-gray-300 rounded-md ${user.email?'border-black': ''}`}
                    />
                    <label 
                        for="email" 
                        className={`pl-2 ${user.email?'active':''}`}
                    >
                        Email
                    </label>
                </div>
            </div>
            <div className={`mb-4 w-3/6 ${(user.id && user.created_by!==auth.id) || (user.id && (!hasAccess('access-all', 'edit', auth.permissions) || user.status==='Cancelled' || user.status==='Pending Approval for Cancellation')) ? 'pointer-events-none' : ''}`}>
                <div class="relative">
                    <input 
                        onChange={handleChange}
                        type="text" 
                        value={user.name}
                        id="name" 
                        name="name" 
                        className={`pt-4 w-full pl-[10px] py-2 border border-gray-300 rounded-md ${user.name?'border-black': ''}`}
                    />
                    <label 
                        for="name" 
                        className={`pl-2 ${user.name?'active':''}`}
                    >
                        Name
                    </label>
                </div>
            </div>
            <div className="mb-4 w-3/6 text-center">
                <button disabled={!emailRegex.test(user.email) || !user.name} onClick={()=>setPage('permission-form')} className="primary text-white px-4 py-2 rounded-md w-full block disabled:opacity-[0.5]">Next</button>
            </div>
    </>
}