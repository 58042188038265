import {useContext, useEffect, useState} from "react";
import { UserContext } from './UserContext';
import {useLocation, useNavigate} from 'react-router-dom';
import { axios, hasAccess } from "../utils";
import { AuthContext } from "../AuthContext";

export default function Form({setPage}){

    const {auth} = useContext(AuthContext);
    const { user, updateUser } = useContext(UserContext);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    useEffect(()=>{
        if(id){
            axios.get(`/users/${id}`).then(resp=>updateUser({
                ...resp.data,
                name: resp.data.firstName + ' ' + resp.data.lastName,
                permissions: resp.data.permissions ? JSON.parse(resp.data.permissions): ""
            }))
        }
    }, [])

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleChange = (e) => {
        let {name, value} = e.target;
        if(name==='name'){
            value = value.trimStart().replace(/[^a-zA-Z ]/g, '');
        }else{
            value = value.trim();
        }
        updateUser({...user, [name]: value});
    }
    
    return <>
            {
                user.status==='Rejected'&& <div className="mb-4 w-3/6">
                    <div className="font-bold">Status: <span className="text-red-500">Rejected</span></div>
                    <div className="font-bold">Reason for rejection: <span className="font-normal">{user.reject_reason}</span></div>
                </div>
            }
            <div className={`mb-4 w-3/6 ${user.id && 'disabled pointer-events-none'}`}>
                <div class="relative input-container">
                    <input 
                        onChange={handleChange}
                        type="text" 
                        id="email" 
                        name="email" 
                        value={user.email}
                        className={`pt-4 w-full pl-[10px] py-2 border border-gray-300 rounded-md ${user.email?'border-black': ''}`}
                    />
                    <label 
                        for="email" 
                        className={`pl-2 ${user.email?'active':''}`}
                    >
                        Email
                    </label>
                </div>
            </div>
            <div className={`mb-4 w-3/6 ${user.id && user.created_by!==auth.id && 'pointer-events-none'}`}>
                <div class="relative input-container">
                    <input 
                        onChange={handleChange}
                        type="text" 
                        value={user.name}
                        id="name" 
                        name="name" 
                        className={`pt-4 w-full pl-[10px] py-2 border border-gray-300 rounded-md ${user.name?'border-black': ''}`}
                    />
                    <label 
                        for="name" 
                        className={`pl-2 ${user.name?'active':''}`}
                    >
                        Name
                    </label>
                </div>
            </div>
            <div className="mb-4 w-3/6 text-center">
                <button disabled={!emailRegex.test(user.email) || !user.name} onClick={()=>setPage('permission-form')} className="primary text-white px-4 py-2 rounded-md w-full block disabled:opacity-[0.5]">Next</button>
            </div>
    </>
}