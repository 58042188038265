import Layout from "../layouts/Dashboard";
import {useState, useEffect, useContext} from "react";
import { AuthContext } from "../AuthContext";
import Modal from "./Modal";
import { axios, getFormattedDate } from "../utils";
import IconSuccess from "../assets/icon-success.png";

export default function BankReports() {

    const {auth} = useContext(AuthContext);
    const [exportSuccess, setExportSuccess] = useState(false);
    const [report, setReport] = useState({banks: [], total_loss: 0, total_win: 0});

    useEffect(()=>{
        axios.get(`/reports/util/net-settlement`).then(resp=>{
            setReport(resp.data)
        });
    }, [])

    const onClickExport = ()=>{
        setExportSuccess(true)
    }

    return <Layout>
        <div className="flex justify-between items-center">
            <p className="text-2xl font-bold capitalize">Reports {auth.bankName}</p>
            <div className="flex items-center space-x-6">
                <button disabled={Object.keys(report.banks).length===0} onClick={onClickExport} className="px-4 py-2 primary font-bold text-base leading-[18.75px] text-white rounded-md">Export</button>
            </div>
        </div>
        <div className="flex mt-2 justify-between">
            <div>Following are the results of the Philippine EFT Systems and Operations Network (PESONet) on {getFormattedDate(new Date())} for cycle 1 for your information.</div>
            <div>{getFormattedDate(new Date())}</div>
        </div>
        {
            exportSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4 text-center">Your requested file has been<br/>sent to your email</p>
                        <button onClick={()=>setExportSuccess(false)} className="primary text-white px-4 py-2 rounded-md w-3/12 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        <div className="w-full mt-3 bg-white rounded p-3">
            <div className="overflow-x-auto mb-3">
                <table className="table-auto border-collapse w-full">
                    <thead>
                        <tr>
                            <th className="py-2 text-left" width="20%">Bank Name</th>
                            <th className="py-2 text-right" width="20%">Outward Count</th>
                            <th className="py-2 text-right" width="20%">Loss (Debit)</th>
                            <th className="py-2 text-right" width="20%">Inward Count</th>
                            <th className="py-2 text-right" width="20%">Win (Credit)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Object.keys(report.banks).map((bank)=>{
                                return <tr>
                                    <td className="py-2 text-left">{bank}</td>
                                    <td className="py-2 text-right">{report.banks[bank].outbound_count}</td>
                                    <td className="py-2 text-right">{report.banks[bank].loss} {report.banks[bank].loss!=='' && '-'}</td>
                                    <td className="py-2 text-right">{report.banks[bank].inbound_count}</td>
                                    <td className="py-2 text-right">{report.banks[bank].win} {report.banks[bank].win!=='' && '+'}</td>
                                </tr>
                            })
                        }
                        <tr>
                            <td colSpan={2} className="py-2 pl-10">TOTAL</td>
                            <td className="py-2 text-right">{report.total_outbound_count}</td>
                            <td className="py-2 text-right">{report.total_loss}</td>
                            <td className="py-2 text-right">{report.total_inbound_count}</td>
                            <td className="py-2 text-right">{report.total_win}</td>
                        </tr>
                        <tr>
                            <td colSpan={2} className="py-2 bg-black pl-10 text-white">NET SETTLEMENT</td>
                            <td className="py-2 bg-black text-white text-right"></td>
                            <td className="py-2 bg-black text-white text-right">{report.total_loss}-</td>
                            <td className="py-2 bg-black text-white text-right"></td>
                            <td className="py-2 bg-black text-white text-right pr-2">{report.total_win}+</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="flex justify-end">
                <div>PHILIPPINE CLEARING HOUSE CORPORATION</div>
            </div>
        </div>
    </Layout>;
}