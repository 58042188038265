import React, { createContext, useState } from 'react';

const UserContext = createContext();

const UserProvider = ({ children, data }) => {
  const [user, setUser] = useState(data ?? {
    password: process.env.REACT_APP_DEFAULT_USER_PASSWORD,
    lastName: "test"
  });
  const updateUser = (data) => {
    setUser(data);
  };

  return <UserContext.Provider value={{user, updateUser}}>{children}</UserContext.Provider>;
};

export { UserContext, UserProvider };