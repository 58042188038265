import { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../layouts/Dashboard";
import { axios, formatStatus, hasAccess, formatDate } from "../utils";
import { AuthContext } from "../AuthContext";
import Modal from "./Modal";
import IconSuccess from "../assets/icon-success.png";

export default function PendingCalendar() {

    const {auth} = useContext(AuthContext);
    const [rows, setRows] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [approvedModal, setApprovedModal] = useState(false);
    const [rejectModal, setRejectModal] = useState();
    const [rejectReason, setRejectReason] = useState('');
    const [rejectSuccess, setRejectSuccess] = useState();
    const [form, setForm] = useState({});

    const onRejected = ()=>{
    axios.patch(`/calendar/${form.id}`, {approved_status:'rejected', reject_reason: rejectReason})
        .then((resp)=>{
            setForm({});
            setRejectModal(false)
            setRejectSuccess(true);
        })
    }

    const onReject = (row)=>{
        setForm(row);
        setRejectModal(true)
    }

    const onApprove = (row)=>{
        setForm(row);
        axios.patch(`/calendar/${row.id}`, {approved_status:'approved'})
            .then((resp)=>{
                setApprovedModal(true)
            })
    }
    
    const onLoad = ()=>{
        axios.get('/calendar/for-approval').then(resp=>{
            let data = resp.data.filter(c=>(c.updated_by ? c.updated_by!==auth.id : c.created_by!==auth.id) && (!c.updated_by || c.updated_by!==auth.id)).sort((a, b) => new Date(a.date) - new Date(b.date));
            data = data.filter(c=>new Date(c.date).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0))
            setRows(data)
        })
    }

    useEffect(()=>{
        if(auth.id){
            if(!hasAccess('calendar-all', 'approve', auth.permissions)){
                navigate('/calendar-management');
            }
            onLoad();
        }
    }, [auth]);

  return <Layout>
        <div className="flex justify-between items-center">
            <p className="text-2xl font-semibold leading-[28.13px]">List of Event for Approval</p>
            <div className="flex items-center space-x-4">
                <Link to="/calendar-management" className="px-4 py-2 secondary text-white rounded-md text-base font-bold leading-[18.75px]">Back</Link>
            </div>
        </div>
        {
            rejectModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    <div className="bg-white px-8 py-4 rounded-lg shadow-md">
                        <div onClick={()=>setRejectModal(false)} className='text-end w-full right-0 cursor-pointer text-black'>x</div>
                        <div className="mb-4 items-center flex flex-col">
                            <div className="font-semibold text-2xl">Reject</div>
                            <div className="mt-[15px] mb-[20px] font-normal"><span className="capitalize">{form.name}</span> has been rejected due to</div>
                            <input className="rounded mb-[17px] w-[451px] h-[54px] border" type="text" value={rejectReason} onChange={(e)=>setRejectReason(e.target.value)} placeholder="Please add reason for rejection" />
                            <button onClick={onRejected} disabled={!rejectReason} className="disabled:opacity-[0.5] primary text-white px-4 py-2 rounded-md w-[136px] h-[54px] block">Reject</button>
                        </div>
                    </div>
                </div>
            </Modal>
        }
        {
            rejectSuccess && <Modal onClose={()=>setRejectSuccess(false)}>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <div className="font-semibold text-2xl leading-[28.13px] mb-[20px]">Rejection Successful!</div>
                        <div className="text-xl2 mb-6 text-center">
                            You have successfully rejected an event.
                        </div>
                        <button onClick={()=>navigate('/calendar-management')} className="primary text-white px-4 py-2 rounded-md w-[97px] h-[54px] block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            approvedModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4 text-center">Approval Successful!</p>
                        <p className="text-xl2 mb-4 text-center">You successfully approved {form.name}</p>
                        <button onClick={()=>{setApprovedModal(false);onLoad();}} className="primary text-center text-white px-4 py-2 rounded-md w-3/12 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {error && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{error}</span>}
        {
            rows.length === 0 && <div className="text-center bg-white p-5 mt-5 block text-sm font-semibold">No Pending Event for Approval</div>
        }
        {rows.length > 0 && <div className="w-full mt-5 bg-white rounded p-3">
            <div className="overflow-x-auto mb-10">
                <table className="table-auto border-collapse w-full">
                    <thead>
                    <tr className="border-b">
                        <th className="py-2 text-left text-lg font-medium leading-[21.09px]">Event Name</th>
                        <th className="py-2 text-left text-lg font-medium leading-[21.09px]">Date Created</th>
                        <th className="py-2 text-left text-lg font-medium leading-[21.09px]">Created By</th>
                        <th className="py-2 text-left text-lg font-medium leading-[21.09px]">Event Date</th>
                        <th className="py-2 text-left text-lg font-medium leading-[21.09px]">Status</th>
                        <th className="py-2 text-left text-lg font-medium leading-[21.09px]" width="15%">Action</th>
                    </tr>
                    </thead>
                    <tbody> 
                        {
                            rows.map((r, i)=><tr key={i} className="border-b">
                                <td className="py-2 text-left text-base font-normal leading-[18.75px]">{r.name}</td>
                                <td className="py-2 text-left text-base font-normal leading-[18.75px]">{formatDate(r.createdAt)}</td>
                                <td className="py-2 text-left text-base font-normal leading-[18.75px]">{r.createdByName}</td>
                                <td className="py-2 text-left text-base font-normal leading-[18.75px]">{formatDate(r.date)}</td>
                                <td className="py-2 gap-1 flex justify-start items-center text-base font-normal leading-[18.75px]">{formatStatus(r.approved_status)}</td>
                                <td className="py-3 text-left">
                                    <div className="flex gap-2">
                                        <button onClick={()=>onReject(r)} className="px-4 py-2 primary text-white rounded-md w-[91px]">Reject</button>
                                        <button onClick={()=>onApprove(r)} className="px-4 py-2 primary text-white rounded-md w-[91px]">Approve</button>
                                    </div>
                                </td>
                            </tr>)
                        }
                    </tbody>
                </table>
            </div>
        </div>
        }
  </Layout>;
}