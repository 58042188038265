
import Layout from "../layouts/Dashboard";
import { Link, useLocation } from "react-router-dom";
import { axios, formatDateWithTime } from "../utils";
import { useState, useEffect } from "react";

export default function BillerHistory (){

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const name = queryParams.get('name');
    const [rows, setRows] = useState([]);

    useEffect(()=>{
        const sort = JSON.stringify([{orderBy: "createdAt", order: 'desc'}])
        axios.get(`/billers/${id}/history?sort=${encodeURIComponent(sort)}`).then(resp=>setRows(resp.data))
    }, [])

    return <Layout>
        <div className="flex justify-between items-center">
            <p className="text-2xl font-semibold leading-[28.13px]">{name} History</p>
            <Link to={`/biller-form?id=${id}&page=permission-form`} className="px-4 py-2 secondary text-white rounded-md">Back</Link>
        </div>
        <div className="w-full mt-5 border rounded p-3">
            <div className="overflow-x-auto mb-3">
                <table className="table-auto border-collapse w-full">
                    <thead>
                    <tr className="border-b">
                        <th className="py-2 text-left" width="33%">User</th>
                        <th className="py-2 text-left" width="33%">Action</th>
                        <th className="py-2 text-left" width="33%">Date and Time</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        rows.map(r=><tr className="border-b border-gray-200">
                            <td className="py-2">{r.username}</td>
                            <td className="py-2">{r.action}</td>
                            <td className="py-2">{formatDateWithTime(r.dateStr)}</td>
                        </tr>)
                    }
                    </tbody>
                </table>
            </div>
        </div>
    </Layout>
}