import Layout from "../layouts/Dashboard";
import { useEffect, useState, useContext } from "react";
import IconSearch from "../assets/icon-search.png";
import Modal from "./Modal";
import IconSuccess from "../assets/icon-success.png";
import { axios, formatDate, hasAccess, isWeekend, formatNumber, escapeRegExp } from "../utils";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import IconExpanded from "../assets/icon-expanded2.png";
import IconExpandGreen from "../assets/icon-expand-green.png";

export default function CollectionsBank(){

    const {auth} = useContext(AuthContext);
    const [selected, setSelected] = useState("uploaded");
    const [search, setSearch] = useState("");
    const [result, setResult] = useState([]);
    const [exportSuccess, setExportSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [uploadModal, setUploadModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadedFileName, setUploadedFileName] = useState('');
    const [errorUpload, setErrorUpload] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('type');
    const [message, setMessage] = useState(null);
    const [totalAmount, setTotalAmount] = useState({rejected: 0, uploaded: 0, accepted: 0, total: 0, collection: 0, 'for-acceptance': 0, history: 0});
    const [totalEntries, setTotalEntries] = useState({rejected: 0, uploaded: 0, accepted: 0, total: 0, collection: 0, 'for-acceptance': 0, history: 0});
    const [accepted, setAccepted] = useState([]);
    const [collection, setCollection] = useState([]);
    const [rejected, setRejected] = useState([]);
    const [forAcceptance, setForAcceptance] = useState([]);
    const [history, setHistory] = useState([]);
    const [hideUploaded, setHideUploaded] = useState(false);
    const [summary, setSummary] = useState({total_entries: 0, cleared: {}, rejected: {}});

    const onUpload = ()=>{
        setErrorUpload('');
        setSelectedFile('')
        setUploadedFileName('')
        setUploadModal(true);
    }

    const fetchUploaded = ()=>{
        setHideUploaded(false)
        const _search = escapeRegExp(search);
        if(type==='issuing'){
            setSelected('for-acceptance')
            axios.get(`/collection/util/acceptance/upload/history?filters=${_search}`)
                .then(resp=>{
                    const _data = resp.data;
                    const _amount = _data.reduce((acc, cur)=> acc+parseFloat(cur.total_amount), 0);
                    const _entires = _data.reduce((acc, cur)=> acc+parseFloat(cur.total_entries), 0);
                    setTotalAmount(prev=>({...prev, history: _amount}))
                    setTotalEntries(prev=>({...prev, history: _entires}))
                    setHistory(_data)
                })
        }else{
            setSelected('uploaded');
            axios.get(`collection/util/collection/upload/today`)
                .then(resp=>{
                    setResult(resp.data);
                })
            axios.get(`/collection/util/collection/upload/history?filters=${_search}`)
                .then(resp=>{
                    const _data = resp.data;
                    const _amount = _data.reduce((acc, cur)=> acc+parseFloat(cur.total_amount), 0);
                    const _entires = _data.reduce((acc, cur)=> acc+parseFloat(cur.total_entries), 0);
                    setTotalAmount(prev=>({...prev, history: _amount}))
                    setTotalEntries(prev=>({...prev, history: _entires}))
                    setHistory(_data)
                })
        }
    }

    useEffect(()=>{
        fetchUploaded();
    }, [type])

    useEffect(()=>{
        const _search = escapeRegExp(search.trim());
        //if(_search.length<3) return;
        switch(selected){
            case 'for-acceptance':
                axios.get(`/collection/util/acceptance/issuing?filters=${_search}`).then(resp=>{
                    setForAcceptance(resp.data)
                    setTotalAmount(prev=>({...prev, 'for-acceptance': 0}))
                    setTotalEntries(prev=>({...prev, 'for-acceptance': 0}))
                    resp.data.map((r)=>{
                        setTotalAmount(prev=>({...prev, 'for-acceptance': parseFloat(prev['for-acceptance'])+ parseFloat(r.amount)}))
                        setTotalEntries(prev=>({...prev, 'for-acceptance': parseFloat(prev['for-acceptance'])+ parseFloat(r.entries)}))
                    })
                });
                break;
            case 'accepted':
                axios.get(`/collection/util/accepted/${type}?filters=${_search}`).then(resp=>{
                    setAccepted(resp.data)
                    setTotalAmount(prev=>({...prev, accepted: 0}))
                    setTotalEntries(prev=>({...prev, accepted: 0}))
                    resp.data.map((r)=>{
                        setTotalAmount(prev=>({...prev, accepted: parseFloat(prev.accepted)+ parseFloat(r.amount)}))
                        setTotalEntries(prev=>({...prev, accepted: parseFloat(prev.accepted)+ parseFloat(r.entries)}))
                    })
                });
                break;
            case 'rejected':
                axios.get(`/collection/util/rejected/${type}?filters=${_search}`).then(resp=>{
                    setRejected(resp.data)
                    setTotalAmount(prev=>({...prev, rejected: 0}))
                    setTotalEntries(prev=>({...prev, rejected: 0}))
                    resp.data.map((r)=>{
                        setTotalAmount(prev=>({...prev, rejected: parseFloat(prev.rejected)+ parseFloat(r.amount)}))
                        setTotalEntries(prev=>({...prev, rejected: parseFloat(prev.rejected)+ parseFloat(r.entries)}))
                    })
                });
                break;
            case 'collection':
                axios.get(`/collection/util/collection/${type}?filters=${_search}`).then(resp=>{
                    setCollection(resp.data)
                    setTotalAmount(prev=>({...prev, collection: 0}))
                    setTotalEntries(prev=>({...prev, collection: 0}))
                    resp.data.map((r)=>{
                        setTotalAmount(prev=>({...prev, collection: parseFloat(prev.collection)+ parseFloat(r.amount)}))
                        setTotalEntries(prev=>({...prev, collection: parseFloat(prev.collection)+ parseFloat(r.entries)}))
                    })
                });
                break;
            default:
                break;
        }
    }, [selected, search]);

    const onClickUpload = () => {
        setSearch('')
        const formData = new FormData();
        formData.append('file', selectedFile);
        const endpoint = type==='acquiring' ? '/collection/util/upload' : '/acceptance/util/upload';
        axios.post(endpoint, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
        }).then(resp=>{
            if(type==='issuing'){
                setSelected('');
                setTimeout(()=>setSelected('for-acceptance'), 100)
            }
            setUploadModal(false);
            let {message, summary} = resp.data;
            setUploadSuccess(message);
            setSummary(summary);
            fetchUploaded();
        }).catch((e)=>{
            setErrorUpload(e.response?.data?.errors?.message || e.message)
        })
    }
  
    const handleFileChange = (event) => {
        setErrorUpload(null);
        const selectedFile = event.target.files[0];
        const fileType = selectedFile.name.split('.').pop().toLowerCase();
        if (!['xls', 'xlsx'].includes(fileType)) {
            setErrorUpload('Invalid file format.');
            return;
        }
        setSelectedFile(selectedFile);
        setUploadedFileName(selectedFile.name);
    };

    const onClickExport = ()=>{
        setError('')
        const endpoint = type==='issuing' ? '/collection/util/export' : '/acceptance/util/export';
        axios.post(endpoint)
            .then(()=>{
                setExportSuccess(true);
            }).catch((e)=>{
                setError(e.response?.data?.errors ? Object.values(e.response.data.errors)[0] : e.message);
            });
    }

    return <Layout>
        {
            exportSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4 text-center">Your requested file has been<br/>sent to your email</p>
                        <button onClick={()=>setExportSuccess(false)} className="primary text-white px-4 py-2 rounded-md w-3/12 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            uploadSuccess && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
                    <div className="mb-4 items-center flex flex-col">
                        <img src={IconSuccess} alt="Success" className="mb-4"/>
                        <p className="font-bold text-xl2 mb-4 text-center">{uploadSuccess}</p>
                        {!!summary.total_entries && <div className="flex flex-col font-medium text-base leading-[24px] mb-4">
                            <span>Total number of entries: {summary.total_entries}</span>
                            <span>Total number cleared: {Object.values(summary.cleared).reduce((sum, item) => sum + item.mandates.length, 0)}</span>
                            <span>Total number rejected: {Object.values(summary.rejected).reduce((sum, item) => sum + item.mandates.length, 0)}</span>
                        </div>}
                        <button onClick={()=>setUploadSuccess(false)} className="primary text-white px-4 py-2 rounded-md w-3/12 block">OK</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        {
            uploadModal && <Modal>
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white rounded-lg shadow-md max-w-md w-3/12 p-5">
                    
                    <div className="mb-4 flex items-center gap-4 flex-col">
                        <div className="flex">
                            <p className="font-bold text-xl2">Upload File</p>
                            <div onClick={()=>setUploadModal(false)} className="relative right-[-120px] top-[-15px] cursor-pointer">x</div>
                        </div>
                        <div className=" border border-dotted flex flex-col w-full items-center gap-2 p-5">
                            {!uploadedFileName && <>
                                <span className="text-gray-500 text-xs">Drop file here or</span>
                                <label htmlFor="fileInput" className="w-1/2 secondary border border-gray-300 text-center py-2 rounded-md cursor-pointer">
                                Excel File
                                </label>
                                <input
                                id="fileInput"
                                type="file"
                                className="hidden"
                                onChange={handleFileChange}
                                accept=".xls, .xlsx"
                                />
                            </>}
                            {errorUpload && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{errorUpload}</span>}
                            {!errorUpload && selectedFile && (
                            <span className="text-gray-500">{uploadedFileName}</span>
                            )}

                        </div>
                        <button disabled={!selectedFile} onClick={onClickUpload} className="primary text-white px-4 py-2 rounded-md w-1/2 block">Upload</button>
                    </div>
                </div>
            </div>
            </Modal>
        }
        <div className="flex justify-between items-center">
            <p className="text-2xl font-bold">Collection Management</p>
            <div className="flex gap-3">
                {hasAccess(`collections-management-${type==='issuing' ? 'issuer' : 'acquirer'}`, 'extract', auth.permissions) && <button disabled={[...forAcceptance, ...accepted, ...result, ...collection, ...rejected].length===0} onClick={onClickExport} className="px-4 py-2 primary text-white rounded-md">Export Collection {type==='acquiring' && 'File'}</button>}
                {((hasAccess('collections-management-acquirer', 'create', auth.permissions) || hasAccess('collections-management-issuer', 'create', auth.permissions)) && !isWeekend()) && <button onClick={onUpload} className="px-4 py-2 primary text-white rounded-md">Upload {type==='issuing' && 'Acceptance File'}</button>}
            </div>
        </div>
        <div className="flex flex-col">
            <div className="flex px-3 pl-0 gap-1">
                {
                    type==='acquiring' && <>
                        <div onClick={()=>setSelected('uploaded')} className={`pb-3 cursor-pointer font-bold p-3 rounded-t ${selected==='uploaded' ? 'bg-white text-primary' : 'bg-[#f0f0f1]'}`}>Uploaded File</div>
                        <div onClick={()=>setSelected('collection')} className={`pb-3 cursor-pointer font-bold p-3 rounded-t ${selected==='collection' ? 'bg-white text-primary' : 'bg-[#f0f0f1]'}`}>For Collection</div>
                    </>
                }
                {
                    type==='issuing' && 
                        <div onClick={()=>setSelected('for-acceptance')} className={`pb-3 cursor-pointer font-bold p-3 rounded-t ${selected==='for-acceptance' ? 'bg-white text-primary' : 'bg-[#f0f0f1]'}`}>For Acceptance</div>
                }
                <div onClick={()=>setSelected('rejected')} className={`pb-3 cursor-pointer font-bold p-3 rounded-t ${selected==='rejected' ? 'bg-white text-primary' : 'bg-[#f0f0f1]'}`}>Rejected</div>
                <div onClick={()=>setSelected('accepted')} className={`pb-3 cursor-pointer font-bold p-3 rounded-t ${selected==='accepted' ? 'bg-white text-primary' : 'bg-[#f0f0f1]'}`}>Accepted</div>
            </div>
        </div>
        <div className="w-full mt-0 bg-white p-1">
            <div className="flex w-full gap-3">
                <div className="w-full relative">
                    <input value={search} onChange={e=>setSearch(e.target.value)} type="text" class="text-base font-normal leading-[18.75px] w-full border border-gray-300 rounded-md py-2 pl-10 pr-4 focus:outline-none focus:ring focus:border-blue-300" placeholder="Search"/>
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <img src={IconSearch} alt="Search" className="cursor-pointer w-[80%]" />
                    </div>
                </div>
            </div>
        </div>
        {error && <span className="text-center p-3 block text-red-500 text-sm font-semibold mt-1">{error}</span>}
        {message && <span className="text-center p-3 block text-green-500 text-sm font-semibold mt-1">{message}</span>}
        <div className="w-full rounded p-3 bg-white mb-3">
    
            <div className="overflow-x-auto mb-10">
                <table className="table-auto border-collapse w-full">
                    <thead>
                    <tr className="border-b">
                        {
                            (type==='acquiring' && !['rejected','collection', 'accepted'].includes(selected)) && <>
                                <th className="py-2 text-left">Consolidated File</th>
                                <th className="py-2 text-left">Last Date Uploaded</th>
                            </>
                        }
                        {
                            (['accepted', 'rejected', 'collection', 'for-acceptance'].includes(selected)) && <>
                                <th className="py-2 text-left">Consolidated File</th>
                                <th className="py-2 text-left">Bank Name</th>
                                <th className="py-2 text-left">Last Date Uploaded</th>
                            </>
                        }
                        <th className="py-2 text-left">Last Uploaded By</th>
                        <th className="py-2 text-center">No. of Entries</th>
                        {
                            selected === 'rejected' && <th className="py-2 text-center">Reason for Rejection</th>
                        }
                        <th className="py-2 text-center">Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                        {
                            selected ==='for-acceptance' && forAcceptance.map(a=><tr>
                                <td className="py-2">{a.consolidated_file_name}</td>
                                <td>{a.billerBankByName}</td>
                                <td>{formatDate(a.createdAt)}</td>
                                <td>{a.uploadedByName}</td>
                                <td className="text-center">{a.entries}</td>
                                <td className="text-center">{formatNumber(a.amount)}</td> 
                            </tr>)
                        }
                        {
                            selected==='accepted' && accepted.map(a=><tr>
                                <td className="py-2">{a.consolidated_file_name}</td>
                                <td>{a.billerBankByName}</td>
                                <td>{formatDate(new Date())}</td>
                                <td>{a.uploadedByName}</td>
                                <td className="text-center">{a.entries}</td>
                                <td className="text-center">{formatNumber(a.amount)}</td>
                            </tr>)
                        }
                        {
                            selected ==='uploaded' && result.map(r=><tr>
                                <td className="py-2">{r.consolidated_file_name}</td>
                                <td>{formatDate(new Date())}</td>
                                <td>{r.uploadedByName}</td>
                                <td className="text-center">{r.total_entries}</td>
                                <td className="text-center">{formatNumber(r.total_amount)}</td>
                            </tr>)
                        }
                        {
                            selected ==='collection' && collection.map(c=>{
                                return <tr>
                                    <td className="py-2">{c.consolidated_file_name}</td>
                                    <td>{c.billerBankByName}</td>
                                    <td>{formatDate(c.createdAt)}</td>
                                    <td>{c.uploadedByName}</td>
                                    <td className="text-center">{c.entries}</td>
                                    <td className="text-center">{formatNumber(c.amount)}</td>
                                </tr>
                            })
                        }
                        {
                            selected ==='rejected' && rejected.map(r=>{
                                return <tr>
                                    <td className="py-2">{r.consolidated_file_name}</td>
                                    <td>{r.billerBankByName}</td>
                                    <td>{formatDate(r.createdAt)}</td>
                                    <td>{r.uploadedByName}</td>
                                    <td className="text-center">{r.entries}</td>
                                    <td className="text-center">{r.mandates[0].reject_reason}</td>
                                    <td className="text-center">{formatNumber(r.amount)}</td>
                                </tr>
                            })
                        }
                        {!['uploaded', 'for-acceptance'].includes(selected) && <tr>
                            <td colSpan={selected==='rejected' ? 5 : (['accepted', 'collection', 'for-acceptance'].includes(selected)? 4: 3)} className="bg-black"></td>
                            <td className="bg-black text-white text-center">TOTAL ENTRIES:<br/>{totalEntries[selected]}</td>
                            <td className="bg-black text-white text-center">TOTAL AMOUNT:<br/>{formatNumber(totalAmount[selected])}</td>
                        </tr>}
                    </tbody>
                </table>
            </div>
        </div>
        {
            (['uploaded', 'for-acceptance'].includes(selected)) && <>
                <div className="bg-white w-full rounded p-3">
                    <div className="overflow-x-auto mb-10">
                        <table className="table-auto border-collapse w-full">
                                <thead>
                                <tr className="border-b">
                                    <th className="py-2 text-left text-2xl font-bold" width="33%">Upload{selected==='for-acceptance' ? ' Acceptance': ''} History</th>
                                    <th className="py-2 text-center">Total Entries: {totalEntries.history}</th>
                                    <th className="py-2 text-center">Total Amount: {formatNumber(totalAmount.history)}</th>
                                    <td className="text-right py-2 flex justify-end pt-3 cursor-pointer"><img className="w-[30px] h-[30px]" src={hideUploaded ? IconExpandGreen : IconExpanded} onClick={()=>setHideUploaded(prev=>!prev)}/></td>
                                </tr>
                                {!hideUploaded && <tr className="border-b">
                                    <th className="py-2 text-left">Date</th>
                                    <th className="py-2 text-center">No. of Entries</th>
                                    <th className="py-2 text-center">Amount</th>
                                </tr>}
                                {
                                    !hideUploaded && history.map((h)=><tr>
                                        <th className="py-2 text-left">{formatDate(h.createdAt)}</th>
                                        <th className="py-2 text-center">{h.total_entries}</th>
                                        <th className="py-2 text-center">{formatNumber(h.total_amount)}</th>
                                    </tr>)
                                }
                            </thead>
                        </table>
                    </div>
                </div>
            </>
        }
    </Layout>
}